@charset "UTF-8";
/*=================================
    CSS Index Here
==================================*/
/*

01. Theme Base
    1.1. Mixin
    1.2. Function
    1.3. Variable
    1.4. Typography
    1.5. Extend
    1.7. Wordpress Default
02. Reset
    2.1. Container
    2.2. Grid
    2.3. Input
    2.4. Slick Slider
    2.5. Mobile Menu
03. Utilities
    3.1. Preloader
    3.2. Buttons
    3.3. Titles
    3.4. Common
    3.6. Font
    3.7. Background
    3.8. Text Color
    3.9. Overlay
    3.10. Animation
04. Template Style
    4.1. Widget
    4.2. Header
    4.3. Footer
    4.4. Breadcumb
    4.5. Pagination
    4.6. Blog
    4.7. Comments
    4.8. Hero Area
    4.9. Error    
    4.00. Popup Search
    4.00. Popup Side Menu
    4.00. Wocommerce
    4.00. Products
    4.00. Cart
    4.00. Checkout
    4.00. Wishlist
05. Spacing

*/
/*=================================
    CSS Index End
==================================*/
/*=================================
   01. Theme Base
==================================*/
/*------------------- 1.1. Mixin -------------------*/
/*------------------- 1.2. Function -------------------*/
/*------------------- 1.3. Variable-------------------*/
:root {
  --theme-color: #9c3519;
  --theme-color2: #b4320e;
  --title-color: #171b2a;
  --body-color: #74787c;
  --smoke-color: #f8f8f8;
  --smoke-two: #f3f2f8;
  --light-color: #979797;
  --black-color: #000000;
  --black-color2: #080e1c;
  --white-color: #ffffff;
  --yellow-color: #fec624;
  --success-color: #28a745;
  --error-color: #dc3545;
  --blue-color: #786acf;
  --border-color: rgb(239, 239, 239);
  --title-font: "Josefin Sans", Roboto, Helvetica, sans-serif;
  --title-font2: "Josefin Sans", Roboto, Helvetica, sans-serif;
  --body-font: "Josefin Sans", Roboto, Helvetica, sans-serif;
  --para-font: "Josefin Sans", Roboto, Helvetica, sans-serif;
  --icon-font: "Font Awesome 6 Pro";
  --main-container: 1220px;
  --container-gutters: 30px;
  --section-space: 80px;
  /* --section-space-mobile: 80px; */
  --section-space-mobile: 35px;
  --section-title-space: 70px;
  --ripple-ani-duration: 5s;
}
.teamall .slick-initialized .slick-slide{
  padding: 0.5rem;
}
.home2,
.home3,
.home4 {
  --theme-color: #2a07f9;
  --theme-color2: #c011f9;
}
.section-title {
  margin-bottom: 40px;
}
.section-title h2 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.3em !important;
  line-height: 30px;
  margin-bottom: 10px;
  color: #22356a;
}
.padd{
  padding-top: 50px;
  padding-bottom: 0px;
}
.sec-title {
    text-transform: capitalize !important;
    font-size: 45px !important;
    line-height: 30px !important;
}
.section-line {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    z-index: 9;
    list-style: none;
    padding: 0;
    margin: 0;
}
.section-line::before {
    position: absolute;
    left: 50%;
    top: 4px;
    width: 150px;
    height: 2px;
    background: #95b7c9;
    content: "";
    z-index: -9;
    transform: translateX(-50%);
}
.section-line li {
    height: 10px;
    width: 10px;
    background: #325d73;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    margin: 0px 1.5px;
    position: relative;
}
.section-line li.second {
    background: #f1992d;
}
/*------------------- 1.5. Typography -------------------*/
html {
  scroll-behavior: auto !important;
}

body {
  /* font-family: var(--body-font); */
  font-family: 'Mont-Bold', sans-serif!important;
  font-size: 16px;
  font-weight: 400;
  color: var(--body-color);
  line-height: 1.556;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

iframe {
  border: none;
  width: 100%;
}
.footer-logo{
  max-height: 140px;
}

/* .slick-slide:focus,
button:focus,
a:focus,
a:active,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
  outline: none;
} */
.menu-fixed {
  position: fixed;
}

.side-menu {
  background: #fff;
  width: 270px;
  min-height: 100vh;
  box-shadow: 0 0 10px rgb(43 43 43 / 9%);
  transition: .4s cubic-bezier(.25,.8,.25,1),background 0s;
  z-index: 998;
}
.side-menu .navbar-header {
  padding: 0.35rem 1rem 0.3rem;
  /* max-height: 4.45rem; */
  min-height: 3.5rem;
  background: #fff;
  justify-content: start;
}
.side-menu .main-menu-content {
  max-height: calc(100vh - 58px);
  position: relative;
  padding-top: 1rem;
}
.side-menu .scrollbar-container {
  overflow: auto;
}
.menu-collapsed .side-menu .navigation {
  margin: 0 10px;
}

.side-menu .navigation {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  padding-bottom: 1rem;
  margin: 0 15px;
}
.side-menu .navigation li {
  position: relative;
  width: 100%;
}
.menu-collapsed .side-menu .navigation li a {
  flex-direction: column;
  justify-content: center;
}

.side-menu .navigation li.active a {
  background: linear-gradient(118deg,#f0401b,#ff6508);
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
}
.side-menu .navigation li a {
  padding: 10px 15px;
  color: #625f6e;
  line-height: 1.3;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: padding .5s ease,background 0s,color 0s;
  position: relative;
}
.header-navbar {
  position: fixed !important;
  width: calc(100% - 2rem);
  z-index: 12;
  right: 0;
  margin: 0 1rem;
  padding: 0.9rem 1rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  top: 0;
}
@media (min-width: 992px){
 .header-navbar {
    width: calc(100% - 4rem - 270px) !important;
}
}
 .header-navbar {
    width: calc(100% - 2rem);
}
@media (min-width: 992px){
.header-navbar {
    width: calc(100% - 4rem - 270px);
    margin: 0 2rem;
}
.header-navbar .search-input .search-input-close {
  display: none;
}
}
.header-navbar .search-input .search-input-close {
  z-index: 1;
  display: none;
  position: absolute;
  top: 32%;
  right: 15px;
  cursor: pointer;
}
.header-navbar .search-input {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: block;
  max-width: 350px;
  border-right: 1px solid #c9c9c9;
}
.header-navbar .search-input {
  float: left;
}
.header-navbar .search-input form {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 1rem;
}
.header-navbar .search-input .search-input-close, .header-navbar .search-input .search-input-icon {
  display: block;
}

.header-navbar .search-input .search-input-icon {
  z-index: 2;
  cursor: pointer;
  padding: 0;
  background: unset;
  border: unset;
}
.header-navbar .search-input .autocomplete-container {
  position: relative;
  display: block;
  margin-left: 0.5rem;
  max-width: 285px;
  width: 100%;
}
.header-navbar .search-input input {
  background: rgba(27,90,143,.11);
  box-shadow: none;
  width: 100%;
  outline: none;
  height: auto;
  transition: all .3s ease-out;
  border-radius: unset;
}

.header-navbar .search-input input {
  border: none;
  /* background: none; */
  padding: 10px;
  line-height: 16px;
}
.header-navbar {
    position: fixed;
    width: calc(100% - 2rem);
    z-index: 12;
    right: 0;
    margin: 0 1rem;
    padding: 0.9rem 1rem;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    top: 0;
}
.header-navbar .dropdown .dropdown-toggle {
  background: unset;
}
.header-navbar .user-nav {
  line-height: 1.5;
  font-size: 14px;
  margin-right: 0.8rem;
  color: #6e6b7b;
}
.header-navbar .user-nav .user-name {
  width: 120px;
  text-align: right;
}
.header-navbar .user-nav .user-status {
  font-size: 11.6667px;
}
.header-navbar .avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(27,90,143,.11);
  color: #1b5a8f;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
.header-navbar li a.nav-link {
    color: #6e6b7b;
    position: relative;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
}
.header-navbar .dropdown .dropdown-item {
  font-size: 13px;
  padding: 0.3rem 1.28rem;
}
.header-navbar .dropdown .dropdown-item {
  font-size: 13px;
  padding: 0.3rem 1.28rem;
}

.header-navbar li a.nav-link {
  color: #6e6b7b;
  position: relative;
}

.LoginPage .brand-logo, .RegisterPage .brand-logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: auto;
  z-index: 2;
  background: unset;
}
.form-group label {
  color: #5e5873;
  font-size: .857rem;
  margin-bottom: 0.2857rem;
  font-weight: 400;
}
.LoginPage .form-control {
  border-color: #ced4da;
  color: #6e6b7b!important;
  font-size: 16px;
  padding: 0.4rem 0.8rem;
  height: 50px;
  font-weight: 500;
  line-height: normal;
}
.LoginPage  .btn-primary, .btn-primary:hover {
  border-color: var(--theme-color);
  background-color: var(--theme-color);
}
.content{
  background-color: #f6f6f6;
  min-height: 100vh;
}
.LoginPage .card{
  min-height: 100vh;
}
.events-card {
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.175);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.175); 
}
  .events-card .card-header {
    background: #fff;
    padding: 15px 20px; 
  }
  .events-card h3 {
    font-size: 16px;
    color: #18212c;
    font-weight: bold;
    margin-bottom: 0; 
  }
  .events-card p {
    font-size: 12px;
    color: #847577;
    margin: 0; 
  }
  .events-card .custom-dropdown {
    padding: 10px;
    padding-right: 0px;
    cursor: pointer; 
  }
    .events-card .custom-dropdown .dropdown-menu {
      min-width: 7rem; 
    }
    .events-card .custom-dropdown a {
      font-size: 12px; 
    }
  .events-card .event-card-img {
    position: relative; 
  }
    .events-card .event-card-img img {
      width: 100%;
      cursor: pointer; 
    }
    .events-card .event-card-img h4 {
      position: absolute;
      left: 20px;
      bottom: 20px;
      color: #fff;
      font-size: 18px;
      margin-bottom: 0px; 
    }
  .events-card .card-body h5 {
    font-size: 14px;
    color: #18212c;
    font-weight: 700; 
  }
  .events-card .card-body p {
    font-size: 12px;
    color: #847577; 
  }
  .events-card .card-sponsor {
    background: #f9f9f9;
    padding: 15px 20px; 
  }
    .events-card .card-sponsor h4 {
      font-size: 14px;
      display: inline-block;
      margin-right: 5px; 
    }
    .events-card .card-sponsor .card-sponsor-img {
      display: inline-block; 
    }
    .events-card .card-sponsor p {
      color: #e53632;
      font-weight: 500;
      font-size: 14px;
      margin-top: 6px; 
    }
  .events-card .card-footer {
    background: #FFF;
    border-top: 0px;
    padding: 20px;
   }
    .events-card .card-footer ul {
      display: inline-block;
      margin-bottom: 0px; 
    }
      .events-card .card-footer ul li {
        display: inline-block;
        margin-right: 36px;
       }
    .events-card .card-footer a {
      font-size: 14px;
      color: #18212c; 
    }
      .events-card .card-footer a i {
        color: #b9b9b9;
        margin-right: 5px; 
      }
      .ql-toolbar.ql-snow + .ql-container.ql-snow{
        min-height: 400px;
      }
input:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.app-body {
  margin-left: 270px;
  padding: 5rem 2rem 2rem;
}
img:not([draggable]),
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

ol li,
ul li {
  line-height: 1.556;
}

ol li a,
ul li a {
  /* font-family: inherit; */
  font-family: 'Mont-Bold', sans-serif!important;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid var(--border-color);
}

th {
  font-weight: 700;
  color: var(--title-color);
}

td,
th {
  border: 1px solid var(--border-color);
  padding: 9px 12px;
}

div,
section {
  line-height: 1;
}

a {
  color: var(--theme-color);
  text-decoration: none;
  outline: 0;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

a:hover {
  color: var(--title-color);
}

a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0;
}

button {
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

img {
  border: none;
  max-width: 100%;
}

ins {
  text-decoration: none;
}

pre {
  /* font-family: var(--para-font); */
  font-family: 'Mont-Bold', sans-serif!important;
  background: #f5f5f5;
  color: #666;
  font-size: 18px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

span.ajax-loader:empty,
p:empty {
  display: none;
}

p {
  /* font-family: var(--para-font); */
  font-family: 'Mont-Bold', sans-serif!important;
  margin: 0 0 18px 0;
  color: var(--body-color);
  line-height: 1.556;
  font-size: 16px;
}

span {
  line-height: 1.4;
  display: inline-block;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
span a {
  font-size: inherit;
  /* font-family: inherit; */
  font-family: 'Mont-Bold', sans-serif!important;
  font-weight: inherit;
  line-height: inherit;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  /* font-family: var(--title-font); */
  font-family: 'Mont-Bold', sans-serif!important;
  color: var(--title-color);
  text-transform: none;
  font-weight: bold;
  line-height: 1.417;
  margin: 0 0 15px 0;
}

.h1,
h1 {
  font-size: 48px;
}

.h2,
h2 {
  font-size: 36px;
}

.h3,
h3 {
  font-size: 30px;
}

.h4,
h4 {
  font-size: 24px;
}

.h5,
h5 {
  font-size: 20px;
}

.h6,
h6 {
  font-size: 16px;
}

/* Large devices */
@media (max-width: 1199px) {

  p,
  pre {
    font-size: 16px;
  }

  .h1,
  h1 {
    font-size: 40px;
  }

  .h2,
  h2 {
    font-size: 30px;
  }

  .h3,
  h3 {
    font-size: 24px;
  }

  .h4,
  h4 {
    font-size: 22px;
  }
}

/* Small devices */
@media (max-width: 767px) {

  .h1,
  h1 {
    font-size: 30px;
  }

  .h2,
  h2 {
    font-size: 26px;
  }

  .h3,
  h3 {
    font-size: 22px;
  }

  .h4,
  h4 {
    font-size: 22px;
  }

  .h5,
  h5 {
    font-size: 18px;
  }
}

/*------------------- 1.6. Extend -------------------*/
/*------------------- 1.7. Wordpress Default -------------------*/
p.has-drop-cap {
  margin-bottom: 20px;
}

.page--item p:last-child .alignright {
  clear: right;
}

.blog-title,
.pagi-title,
.breadcumb-title {
  word-break: break-word;
}

.blocks-gallery-caption,
.wp-block-embed figcaption,
.wp-block-image figcaption {
  color: var(--body-color);
}

.bypostauthor,
.gallery-caption {
  display: block;
}

.page-links,
.clearfix {
  clear: both;
}

.page--item {
  margin-bottom: 30px;
}

.page--item p {
  line-height: 1.8;
}

.content-none-search {
  margin-top: 30px;
}

.wp-block-button.aligncenter {
  text-align: center;
}

.alignleft {
  display: inline;
  float: left;
  margin-bottom: 10px;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-bottom: 10px;
  margin-left: 1.5em;
  margin-right: 1em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.gallery {
  margin-bottom: 1.5em;
  width: 100%;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
  padding: 0 5px;
}

.wp-block-columns {
  margin-bottom: 1em;
}

figure.gallery-item {
  margin-bottom: 10px;
  display: inline-block;
}

figure.wp-block-gallery {
  margin-bottom: 14px;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
  font-size: 12px;
  color: var(--body-color);
  line-height: 1.5;
  padding: 0.5em 0;
}

.wp-block-cover p:not(.has-text-color),
.wp-block-cover-image-text,
.wp-block-cover-text {
  color: var(--white-color);
}

.wp-block-cover {
  margin-bottom: 15px;
}

.wp-caption-text {
  text-align: center;
}

.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption .wp-caption-text {
  margin: 0.5em 0;
  font-size: 14px;
}

.wp-block-media-text,
.wp-block-media-text.alignwide,
figure.wp-block-gallery {
  margin-bottom: 30px;
}

.wp-block-media-text.alignwide {
  background-color: var(--smoke-color);
}

.editor-styles-wrapper .has-large-font-size,
.has-large-font-size {
  line-height: 1.4;
}

.wp-block-latest-comments a {
  color: inherit;
}

.wp-block-button {
  margin-bottom: 10px;
}

.wp-block-button:last-child {
  margin-bottom: 0;
}

.wp-block-button .wp-block-button__link {
  color: #fff;
}

.wp-block-button .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
}

.wp-block-button.is-style-outline .wp-block-button__link {
  background-color: transparent;
  border-color: var(--title-color);
  color: var(--title-color);
}

.wp-block-button.is-style-outline .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.wp-block-button.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

ol.wp-block-latest-comments li {
  margin: 15px 0;
}

ul.wp-block-latest-posts {
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}

ul.wp-block-latest-posts a {
  color: inherit;
}

ul.wp-block-latest-posts a:hover {
  color: var(--theme-color);
}

ul.wp-block-latest-posts li {
  margin: 15px 0;
}

.wp-block-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.wp-block-search .wp-block-search__input {
  width: 100%;
  max-width: calc(100% - 120px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  padding-left: 20px;
}

.wp-block-search .wp-block-search__button {
  margin: 0;
  width: 120px;
  border: none;
  height: 50px;
  color: #fff;
  background-color: var(--theme-color);
}

.wp-block-search .wp-block-search__button:hover {
  background-color: var(--title-color);
  opacity: 0.8;
}

ul.wp-block-rss a {
  color: inherit;
}

.wp-block-group.has-background {
  padding: 15px 15px 1px;
  margin-bottom: 30px;
}

.wp-block-table td,
.wp-block-table th {
  border-color: rgba(0, 0, 0, 0.1);
}

.wp-block-table.is-style-stripes {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.logged-in .will-sticky .sticky-active.active,
.logged-in .preloader .vs-btn {
  top: 32px;
}

@media (max-width: 782px) {

  .logged-in .will-sticky .sticky-active.active,
  .logged-in .preloader .vs-btn {
    top: 46px;
  }
}

@media (max-width: 600px) {

  .logged-in .will-sticky .sticky-active.active,
  .logged-in .preloader .vs-btn {
    top: 0;
  }
}

.post-password-form .theme-input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 20px;
}

.post-password-form .theme-input-group .theme-input-style {
  width: 100%;
  max-width: calc(100% - 120px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  padding-left: 20px;
}

.post-password-form .theme-input-group .submit-btn {
  margin: 0;
  width: 120px;
  border: none;
  height: 50px;
  color: #fff;
  background-color: var(--theme-color);
}

.post-password-form .theme-input-group .submit-btn:hover {
  background-color: var(--title-color);
  opacity: 0.8;
}

.page-links {
  clear: both;
  margin: 0 0 1.5em;
  padding-top: 1em;
}

.page-links>.page-links-title {
  margin-right: 10px;
}

.page-links>span:not(.page-links-title):not(.screen-reader-text),
.page-links>a {
  display: inline-block;
  padding: 5px 13px;
  background-color: var(--white-color);
  color: var(--title-color);
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-right: 10px;
}

.page-links>span:not(.page-links-title):not(.screen-reader-text):hover,
.page-links>a:hover {
  opacity: 0.8;
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: transparent;
}

.page-links>span:not(.page-links-title):not(.screen-reader-text).current,
.page-links>a.current {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: transparent;
}

.page-links span.screen-reader-text {
  display: none;
}

.blog-single .wp-block-archives-dropdown {
  margin-bottom: 30px;
}

.blog-single.format-quote,
.blog-single.format-link,
.blog-single.tag-sticky-2,
.blog-single.sticky {
  border-color: transparent;
  position: relative;
}

.blog-single.format-quote .blog-content,
.blog-single.format-link .blog-content,
.blog-single.tag-sticky-2 .blog-content,
.blog-single.sticky .blog-content {
  background-color: #f3f6f7;
  padding: 60px;
  border: none;
  border-radius: 15px;
}

.blog-single.format-quote .blog-content:before,
.blog-single.format-link .blog-content:before,
.blog-single.tag-sticky-2 .blog-content:before,
.blog-single.sticky .blog-content:before {
  display: none;
}

.blog-single.format-quote:before,
.blog-single.format-link:before,
.blog-single.tag-sticky-2:before,
.blog-single.sticky:before {
  content: "";
  position: absolute;
  /* font-family: "Font Awesome 5 Pro"; */
  font-family: 'Mont-Bold', sans-serif!important;
  font-size: 5rem;
  opacity: 0.3;
  right: 60px;
  line-height: 1;
  bottom: 45px;
  color: var(--theme-color);
  z-index: 1;
}

.blog-single.tag-sticky-2::before,
.blog-single.sticky::before {
  content: "Featured";
  right: 0;
  top: 0;
  font-size: 18px;
  color: var(--white-color);
  background-color: var(--theme-color);
  /* font-family: var(--title-font); */
  font-family: 'Mont-Bold', sans-serif!important;
  opacity: 1;
  text-transform: capitalize;
  padding: 10px 23px;
  font-weight: 400;
}

.blog-single.format-quote:before {
  content: "";
}

.blog-single .blog-content .wp-block-categories-dropdown.wp-block-categories,
.blog-single .blog-content .wp-block-archives-dropdown {
  display: block;
  margin-bottom: 30px;
}

.blog-details .blog-single:before {
  display: none;
}

.blog-details .blog-single .blog-content {
  background-color: transparent;
  padding: 0;
}

.blog-details .blog-single.format-chat .blog-meta {
  margin-bottom: 20px;
}

.blog-details .blog-single.format-chat .blog-content>p:nth-child(2n) {
  background: var(--smoke-color);
  padding: 5px 20px;
}

.blog-details .blog-single.tag-sticky-2,
.blog-details .blog-single.sticky,
.blog-details .blog-single.format-quote,
.blog-details .blog-single.format-link {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
}

.blog-details .blog-single.tag-sticky-2:before,
.blog-details .blog-single.sticky:before,
.blog-details .blog-single.format-quote:before,
.blog-details .blog-single.format-link:before {
  display: none;
}

.vs-search {
  background-color: #f3f3f3;
  margin-bottom: 30px;
  border: 1px solid #f3f3f3;
}

.vs-search .search-grid-content {
  padding: 30px;
  /* Small devices */
}

@media (max-width: 767px) {
  .vs-search .search-grid-content {
    padding: 20px;
  }
}

.vs-search .search-grid-title {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: -0.2em;
}

.vs-search .search-grid-title a {
  color: inherit;
}

.vs-search .search-grid-title a:hover {
  color: var(--theme-color);
}

.vs-search .search-grid-meta>* {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
}

.vs-search .search-grid-meta>*:last-child {
  margin-right: 0;
}

.vs-search .search-grid-meta a,
.vs-search .search-grid-meta span {
  color: var(--body-color);
}

/* Large devices */
@media (max-width: 1199px) {

  .blog-single.format-quote:before,
  .blog-single.format-link:before,
  .blog-single.tag-sticky-2:before,
  .blog-single.sticky:before {
    font-size: 40px;
    right: 40px;
    bottom: 25px;
  }

  .blog-single.format-quote .blog-content,
  .blog-single.format-link .blog-content,
  .blog-single.tag-sticky-2 .blog-content,
  .blog-single.sticky .blog-content {
    padding: 30px;
  }
}

/* Small devices */
@media (max-width: 767px) {

  .blog-single.format-quote:before,
  .blog-single.format-link:before,
  .blog-single.tag-sticky-2:before,
  .blog-single.sticky:before {
    font-size: 14px;
    padding: 8px 16px;
    bottom: unset;
    top: 5px;
    right: 10px;
  }

  .blog-single.format-quote .blog-content,
  .blog-single.format-link .blog-content,
  .blog-single.tag-sticky-2 .blog-content,
  .blog-single.sticky .blog-content {
    padding: 30px 15px;
  }
}

@media (max-width: 768px) {
  .wp-block-search .wp-block-search__input {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .wp-block-latest-comments {
    padding-left: 10px;
  }

  .page--content.clearfix+.vs-comment-form {
    margin-top: 24px;
  }
}

/*=================================
    02. Reset
==================================*/
/*------------------- 2.1. Container -------------------*/
@media only screen and (min-width: 1300px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: calc(var(--main-container) + var(--container-gutters));
    padding-left: calc(var(--container-gutters) / 2);
    padding-right: calc(var(--container-gutters) / 2);
  }
}

@media only screen and (max-width: 1600px) {
  .container-fluid.px-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .container-fluid.px-0 .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

/*------------------- 2.2. Grid -------------------*/
.vs-container {
  max-width: 1680px;
  padding-left: calc(var(--container-gutters) / 2);
  padding-right: calc(var(--container-gutters) / 2);
  margin-left: auto;
  margin-right: auto;
}

.vs-container.style2 {
  padding-left: 0;
  padding-right: 0;
}

/* .slick-track>[class*=col] {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)/ 2);
  padding-left: calc(var(--bs-gutter-x)/ 2);
  margin-top: var(--bs-gutter-y);
} */

/* Extra large devices */
@media (max-width: 1680px) {
  .vs-container.style2 {
    --bs-gutter-x: 1.5rem;
    max-width: 1250px;
    padding-left: calc(var(--bs-gutter-x) / 2);
    padding-right: calc(var(--bs-gutter-x) / 2);
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .vs-container.style2 {
    --bs-gutter-x: 1.5rem;
    max-width: 100%;
    padding-left: calc(var(--bs-gutter-x) / 2);
    padding-right: calc(var(--bs-gutter-x) / 2);
  }
}

@media (min-width: 1199px) {
  .row {
    --bs-gutter-x: 30px;
  }
}

.gy-30 {
  --bs-gutter-y: 30px;
}

.g-0 {
  --bs-gutter-x: 0px;
  --bs-gutter-y: 0px;
}

.gx-0 {
  --bs-gutter-x: 0px;
}

.gx-15 {
  --bs-gutter-x: 15px;
}

.gy-15 {
  --bs-gutter-y: 15px;
}

.gx-20,
.g-20 {
  --bs-gutter-x: 20px;
}

.gy-20,
.g-20 {
  --bs-gutter-y: 20px;
}

.gx-40 {
  --bs-gutter-x: 40px;
}

.gy-40 {
  --bs-gutter-y: 40px;
}

.gx-35 {
  --bs-gutter-x: 35px;
}

.gx-60 {
  --bs-gutter-x: 60px;
}

.gy-60 {
  --bs-gutter-y: 60px;
}

@media (min-width: 1399px) {
  .gx-30 {
    --bs-gutter-x: 30px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .gx-60 {
    --bs-gutter-x: 40px;
  }

  .gx-40 {
    --bs-gutter-x: 30px;
  }

  .gx-35 {
    --bs-gutter-x: 24px;
  }

  .gx-lg-15 {
    --bs-gutter-x: 15px;
  }
}

/*------------------- 2.3. Input -------------------*/
select {
  display: block;
  width: 100%;
  line-height: 1.5;
  vertical-align: middle;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select,
.form-select,
.form-control {
  height: 55px;
  padding: 0 25px;
  padding-right: 60px;
  border: 1px solid var(--border-color);
  color: var(--body-color);
  background-color: var(--white-color);
  border-radius: 0;
  font-weight: 400;
  font-size: 16px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 999px;
}

select:focus,
.form-select:focus,
.form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--white-color);
  border-color: var(--theme-color);
}

select::-moz-placeholder,
.form-select::-moz-placeholder,
.form-control::-moz-placeholder {
  color: var(--body-color);
}

select::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: var(--body-color);
}

select:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: var(--body-color);
}

select::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: var(--body-color);
}

select::-moz-placeholder,
.form-select::-moz-placeholder,
.form-control::-moz-placeholder {
  color: var(--body-color);
}

select:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: var(--body-color);
}

select::-ms-input-placeholder,
.form-select::-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  color: var(--body-color);
}

select::placeholder,
.form-select::placeholder,
.form-control::placeholder {
  color: var(--body-color);
}

select.style2,
.form-select.style2,
.form-control.style2 {
  height: 60px;
  border: 2px solid #f5f4fa;
  -webkit-box-shadow: 0px 9px 46px 0px rgba(188, 188, 188, 0.25);
  box-shadow: 0px 9px 46px 0px rgba(188, 188, 188, 0.25);
}

select.style3,
.form-select.style3,
.form-control.style3 {
  background-color: var(--smoke-color);
  border-color: var(--smoke-color);
}

.form-select {
  cursor: pointer;
  background-position: right 1.3rem center;
}

input.form-control::-webkit-outer-spin-button,
input.form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.form-control[type=number] {
  -moz-appearance: textfield;
}

textarea.form-control {
  min-height: 150px;
  padding-top: 17px;
  padding-bottom: 17px;
  border-radius: 15px;
}

/* input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
} */
input#excelUpload {}
.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
}
.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.modal .modal-content .modal-body .form-group label {
    color: #5e5873;
    font-size: .857rem;
    margin-bottom: 0.2857rem;
}
.form-group {
    position: relative;
}
.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
}
input[type=checkbox]~label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: block;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  visibility: hidden;
}
input[type=checkbox]~label:before {
  content: "";
  font-family: 'fontawesome';
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 3px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
}

input[type=checkbox]:checked~label:before {
  content: "";
  color: var(--theme-color);
  border-color: var(--theme-color);
}

input[type=radio] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}

input[type=radio]~label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 1;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
}

input[type=radio]~label::before {
  content: "";
  position: absolute;
  font-family: var(--icon-font);
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  font-size: 0.29rem;
  line-height: 14.8px;
  text-align: center;
  border: 1px solid var(--theme-color);
  border-radius: 100%;
  font-weight: 700;
  background: var(--white-color);
  color: transparent;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

input[type=radio]:checked~label::before {
  border-color: var(--theme-color);
  background-color: var(--theme-color);
  color: var(--white-color);
}

label {
  margin-bottom: 0.8em;
  margin-top: -0.3em;
  display: block;
  color: var(--title-color);
  line-height: 1.556;
}

.form-group {
  margin-bottom: var(--bs-gutter-x);
  position: relative;
}

.form-group>i {
  position: absolute;
  right: 36px;
  top: 13px;
  font-size: 18px;
  color: var(--body-color);
}

.form-group:last-child:not([class*=mb-]) {
  margin-bottom: 0;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid var(--error-color) !important;
  background-position: right calc(.375em + 0.8875rem) center;
  background-image: none;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

textarea.form-control.is-invalid {
  background-position: top calc(.375em + 0.5875rem) right calc(.375em + .8875rem);
}

.row.no-gutters>.form-group {
  margin-bottom: 0;
}

.form-messages {
  display: none;
}

.form-messages.mb-0 * {
  margin-bottom: 0;
}

.form-messages.success {
  color: var(--success-color);
  display: block;
}

.form-messages.error {
  color: var(--error-color);
  display: block;
}

/* Extra small devices */
@media (max-width: 575px) {

  .form-select,
  .form-control {
    padding-right: 30px;
    padding-left: 15px;
  }
}

/*------------------- 2.4. Slick Slider -------------------*/
/* .row>.slick-list {
  padding-left: 0;
  padding-right: 0;
}

.slick-track {
  min-width: 100%;
}

.slick-slide img {
  display: inline-block;
}

.slick-dots {
  list-style-type: none;
  padding: 2px 0;
  margin: 30px 0 30px 0;
  line-height: 0;
  text-align: center;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.slick-dots li {
  display: inline-block;
  margin-right: 10px;
}

.slick-dots li:last-child {
  margin-right: 0;
}

.slick-dots button {
  font-size: 0;
  padding: 0;
  background-color: transparent;
  width: 12px;
  height: 12px;
  line-height: 0;
  border-radius: 9999px;
  border: 3px solid #7a7a7a;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.slick-dots button:hover {
  border-color: var(--theme-color);
}

.slick-dots .slick-active button {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  border-color: var(--theme-color);
}
  */

.slick-arrow {
  display: inline-block;
  padding: 0;
  background-color: var(--white-color);
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.04);
  box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.04);
  color: var(--theme-color);
  position: absolute;
  top: 50%;
  margin-right: 50px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: var(--pos-x, -170px);
  width: var(--icon-size, 60px);
  height: var(--icon-size, 60px);
  z-index: 2;
  font-size: 18px;
  border-radius: 50%;
}

.slick-arrow i {
  width: var(--icon-size, 60px);
  height: var(--icon-size, 60px);
  line-height: var(--icon-size, 58px);
}

.slick-arrow.default {
  position: relative;
  --pos-x: 0;
  margin-top: 0;
}

.slick-arrow:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
}

.slick-arrow.slick-next {
  margin-right: 0;
  margin-left: 50px;
  right: 0;
  left: auto;
}

.arrow-wrap .slick-arrow {
  opacity: 1;
  /* visibility: hidden; */
  background-color: transparent !important;
  top: 11rem;
}

.arrow-wrap .slick-arrow.slick-next {
  right: -3%;
  left: auto;
}
.arrow-wrap .slick-arrow.slick-prev {
  left: -3% !important;
  left: auto;
}

.btn-group.mytimer{padding-top: 15%;}

.arrow-wrap:hover .slick-arrow {
  opacity: 1;
  /* visibility: visible; */
  /* left: -3%; */
}

.arrow-wrap:hover .slick-arrow.slick-next {
  right: -3%;
  left: auto;
}

.team-slide2 .slick-arrow {
  top: 30%;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.arrow-wrap .slick-prev:before, .arrow-wrap .slick-next:before{
  display: none;
}
/* Extra large devices */
@media (max-width: 1500px) {
  /* .slick-arrow {
    --arrow-horizontal: -20px;
  }

  .arrow-wrap .slick-arrow {
    left: var(--pos-x, -80px);
  }

  .arrow-wrap .slick-arrow.slick-next {
    right: var(--pos-x, -80px);
    left: auto;
  }

  .arrow-wrap:hover .slick-arrow {
    left: var(--pos-x, -60px);
  }

  .arrow-wrap:hover .slick-arrow.slick-next {
    right: var(--pos-x, -60px);
    left: auto;
  } */
}

/* Medium Large devices */
@media (max-width: 1399px) {
  /* .slick-arrow {
    --arrow-horizontal: 40px;
  }

  .arrow-wrap:hover .slick-arrow {
    left: var(--pos-x, -15px);
  }

  .arrow-wrap:hover .slick-arrow.slick-next {
    right: var(--pos-x, -15px);
    left: auto;
  } */
}

/* Medium devices */
@media (max-width: 991px) {
  /* .slick-arrow {
    --icon-size: 40px;
    margin-right: 40px;
  }

  .slick-arrow .long-arrow {
    width: 62px;
    height: 2px;
  }

  .slick-arrow.slick-next {
    margin-right: 0;
    margin-left: 40px;
  } */
}

/* Small devices */
@media (max-width: 767px) {
  /* .slick-arrow {
    --arrow-horizontal: 40px;
  }

  .arrow-wrap:hover .slick-arrow {
    left: var(--pos-x, 0);
  }

  .arrow-wrap:hover .slick-arrow.slick-next {
    right: var(--pos-x, 0);
    left: auto;
  } */
}

/*------------------- 2.5. Mobile Menu -------------------*/
.vs-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  width: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
  opacity: 0;
  visibility: hidden;
}

.vs-menu-wrapper .mobile-logo {
  padding-bottom: 30px;
  padding-top: 40px;
  display: block;
  text-align: center;
  background-color: rgba(28, 185, 200, 0.1);
}

.vs-menu-wrapper .mobile-logo svg {
  max-width: 185px;
}

.vs-menu-wrapper .vs-menu-toggle {
  border: none;
  font-size: 22px;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  line-height: 1;
  width: 33px;
  height: 33px;
  line-height: 33px;
  font-size: 18px;
  z-index: 1;
  color: var(--white-color);
  background-color: var(--theme-color);
  border-radius: 50%;
}

.vs-menu-wrapper .vs-menu-toggle:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.vs-menu-wrapper .vs-menu-area {
  width: 100%;
  max-width: 310px;
  background-color: #fff;
  border-right: 3px solid var(--theme-color);
  height: 100%;
  position: relative;
  left: -110%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 1s;
  transition: all ease 1s;
  z-index: 1;
}

.vs-menu-wrapper.vs-body-visible {
  opacity: 1;
  visibility: visible;
}

.vs-menu-wrapper.vs-body-visible .vs-menu-area {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.vs-mobile-menu {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
  margin-top: 33px;
  text-align: left;
}

.vs-mobile-menu ul {
  margin: 0;
  padding: 0 0;
}

.vs-mobile-menu ul li {
  border-bottom: 1px solid #fdedf1;
  list-style-type: none;
}

.vs-mobile-menu ul li li:first-child {
  border-top: 1px solid #fdedf1;
}

.vs-mobile-menu ul li a {
  display: block;
  position: relative;
  padding: 12px 0;
  line-height: 1;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--title-color);
}

.vs-mobile-menu ul li a:before {
  content: "";
  /* font-family: var(--icon-font); */
  font-family: 'Mont-Bold', sans-serif!important;
  position: relative;
  left: 0;
  top: 0;
  margin-right: 10px;
  display: inline-block;
}

.vs-mobile-menu ul li.vs-active>a {
  color: var(--theme-color);
}

.vs-mobile-menu ul li.vs-active>a:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.vs-mobile-menu ul li ul li {
  padding-left: 20px;
}

.vs-mobile-menu ul li ul li:last-child {
  border-bottom: none;
}

.vs-mobile-menu ul .vs-item-has-children>a .vs-mean-expand {
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 400;
  font-size: 12px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-top: -12.5px;
  display: inline-block;
  text-align: center;
  background-color: var(--smoke-color);
  color: var(--title-color);
  -webkit-box-shadow: 0 0 20px -8px rgba(173, 136, 88, 0.5);
  box-shadow: 0 0 20px -8px rgba(173, 136, 88, 0.5);
  border-radius: 50%;
}

.vs-mobile-menu ul .vs-item-has-children>a .vs-mean-expand:before {
  content: "";
  font-family: var(--icon-font);
}

.vs-mobile-menu ul .vs-item-has-children>a:after {
  content: "";
  /* font-family: var(--icon-font); */
  font-family: 'Mont-Bold', sans-serif!important;
  width: 18px;
  height: 18px;
  line-height: 18px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  border-radius: 50px;
  background-color: var(--smoke-color);
  float: right;
}

.vs-mobile-menu ul .vs-item-has-children.vs-active>a .vs-mean-expand:before {
  content: "";
}

.vs-mobile-menu ul .vs-item-has-children.vs-active>a:after {
  content: "";
}

.vs-mobile-menu>ul {
  padding: 0 40px;
}

.vs-mobile-menu>ul>li:last-child {
  border-bottom: none;
}

.vs-menu-toggle {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 20px;
  border: none;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  border-radius: 50%;
}

.vs-menu-toggle:hover {
  background-color: var(--title-color);
}

.vs-menu-toggle.style-text,
.vs-menu-toggle.style-text-white {
  width: auto;
  height: auto;
  background-color: transparent;
  color: var(--title-color);
  font-size: 20px;
}

.vs-menu-toggle.style-text i,
.vs-menu-toggle.style-text-white i {
  margin-right: 10px;
}

.vs-menu-toggle.style-text-white {
  color: var(--white-color);
}

@media (max-width: 400px) {
  .vs-menu-wrapper .vs-menu-area {
    width: 100%;
    max-width: 270px;
  }

  .vs-mobile-menu>ul {
    padding: 0 20px;
  }
}

/*=================================
    03. Utilities
==================================*/
/*------------------- 3.1. Preloader -------------------*/
.preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: var(--white-color);
}

.preloader .vs-btn {
  border-radius: 0;
  padding: 12px 22px;
}

.preloader-inner {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.loader {
  width: 90px;
  height: 90px;
  margin: 70px auto;
  position: relative;
  -webkit-animation: move 0.5s ease infinite alternate;
  animation: move 0.5s ease infinite alternate;
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loader:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 32px solid var(--theme-color);
  border-color: var(--theme-color) transparent var(--theme-color) transparent;
  -webkit-animation: loaderAni 1.6s infinite;
  animation: loaderAni 1.6s infinite;
}

@-webkit-keyframes loaderAni {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg);
  }
}

@keyframes loaderAni {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg);
  }
}

/*------------------- 3.2. Buttons -------------------*/
/* .vs-btn {
  background-color: var(--theme-color);
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), color-stop(var(--theme-color2)), color-stop(var(--theme-color2)), to(var(--theme-color)));
  background-image: linear-gradient(to right, var(--theme-color), var(--theme-color2), var(--theme-color2), var(--theme-color));
  background-size: 300% 100%;
  -webkit-box-shadow: 0px 11px 21px 0px rgba(240, 64, 27, 0.31);
  box-shadow: 0px 11px 21px 0px rgba(240, 64, 27, 0.31);
  color: var(--white-color);
  font-size: 14px;
  font-weight: 700;
  padding: 20.5px 35px;
  line-height: 1;
  text-transform: uppercase;
  min-width: 150px;
  text-align: center;
  border-radius: 0;
  border: none;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  z-index: 2;
  border-radius: 9999px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
} */
.vs-btn {
  background-color: var(--theme-color);
  background-image: linear-gradient(to right, #0283ca, #008edd, #0399eb, #0da9ff);
  background-size: 300% 100%;
  box-shadow: 0px 11px 21px 0px rgb(240 64 27 / 31%);
  color: var(--white-color);
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0.25rem;
  padding: 20.5px 35px;
  line-height: 1;
  text-transform: uppercase;
  min-width: 150px;
  text-align: center;
  border-radius: 0;
  border: none;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  z-index: 2;
  border-radius: 9999px;
  transition: 0.5s ease-in-out;
}

.vs-btn i {
  font-size: 14px;
  margin-right: 5px;
  line-height: 1;
  white-space: nowrap;
}

.vs-btn:hover,
.vs-btn.active {
  color: var(--white-color);
  background-color: var(--title-color);
  background-position: 100% 0;
}

.vs-btn.btn-white {
  background-color: var(--white-color);
  color: var(--theme-color);
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.vs-btn.btn-white:hover,
.vs-btn.btn-white.active {
  color: var(--white-color);
  /* background-color: var(--theme-color); */
  background-image: linear-gradient(to right, #f8972c 0%, #cd3c27  51%, #f8972c 100%);
}

.vs-btn.small-btn {
  padding: 9px 33px;
}

.vs-btn.height-60 {
  height: 60px;
}

.vs-btn.style1 {
  background-color: #eee9ff;
  background-image: none;
  color: var(--theme-color);
  min-width: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.vs-btn.style1:hover,
.vs-btn.style1.active {
  background-color: var(--theme-color);
  color: var(--white-color);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(69, 23, 241, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(69, 23, 241, 0.2);
}

.vs-btn.style2 {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), color-stop(var(--theme-color)), color-stop(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color), var(--theme-color), var(--theme-color), var(--theme-color2));
  -webkit-box-shadow: none;
  box-shadow: none;
  background-size: 300% 100%;
  -webkit-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
}

.vs-btn.style2:hover {
  background-position: 100% 100%;
}

.vs-btn.style3 {
  background-color: transparent;
  background-image: none;
  border: 1px solid var(--border-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--body-color);
}

.vs-btn.style3:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}

.vs-btn.style4 {
  background-color: var(--white-color);
  color: var(--body-color);
  -webkit-box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.04);
  box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.04);
  border: 1px solid #e7e8ec;
  background-image: none;
}

.vs-btn.style4:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.vs-btn.style5 {
  background-color: transparent;
  background-image: none;
  border: 2px solid var(--white-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--white-color);
  padding: 18px 35px;
}

.vs-btn.style5:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}

.home4 .vs-btn {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.icon-btn {
  display: inline-block;
  width: var(--btn-size, 50px);
  height: var(--btn-size, 50px);
  line-height: var(--btn-size, 52px);
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  border-radius: 50%;
  border: none;
}

.icon-btn:hover,
.icon-btn.active {
  background-color: var(--title-color);
  color: var(--white-color);
}

.icon-btn.style-2 {
  background-color: transparent;
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
  line-height: 1;
}

.icon-btn.style-2:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.icon-btn.style-3 {
  background-color: #ffded6;
  color: var(--theme-color);
  line-height: 1;
}

.icon-btn.style-3:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.icon-btn.style-4 {
  background-color: var(--white-color);
  color: var(--theme-color);
  line-height: 1;
}

.icon-btn.style-4:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.play-btn {
  --icon-size: 110px;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  color: var(--white-color);
  background-color: var(--theme-color);
  border: none;
  width: var(--icon-size);
  height: var(--icon-size);
  z-index: 2;
  /* Extra small devices */
}

.play-btn::before,
.play-btn::after {
  content: "";
  position: absolute;
  background-color: var(--theme-color);
  width: var(--icon-size);
  height: var(--icon-size);
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 50%;
}

.play-btn::after {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.play-btn>i {
  color: var(--white-color);
  position: relative;
  z-index: 2;
  line-height: var(--icon-size);
  text-align: center;
  font-size: 32px;
  padding-right: 0.1em;
  border-radius: inherit;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.play-btn.style2::before,
.play-btn.style2::after {
  content: "";
  background-color: transparent;
  border: 2px solid var(--theme-color);
}

.play-btn.style3 {
  --icon-size: 120px;
  background-color: var(--white-color);
}

.play-btn.style3::before,
.play-btn.style3::after {
  background-color: var(--white-color);
}

.play-btn.style3>i {
  font-size: 24px;
  color: var(--theme-color);
  margin-left: -4px;
}

.play-btn.style4 {
  --icon-size: 55px;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%);
}

.play-btn.style4>i {
  font-size: 20px !important;
  margin-left: 0 !important;
}

@media (max-width: 575px) {
  .play-btn {
    --icon-size: 50px !important;
    width: var(--icon-size);
    height: var(--icon-size);
  }

  .play-btn>i {
    font-size: 20px !important;
    margin-left: 0 !important;
  }
}

.video-btn {
  --icon-size: 55px;
  position: relative;
  display: inline-block;
  color: var(--theme-color);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  border: none;
}

.video-btn>i {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%);
  color: var(--white-color);
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: var(--icon-size);
  text-align: center;
  font-size: 20px;
  margin-right: 20px;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.video-btn::before,
.video-btn::after {
  content: "";
  position: absolute;
  background-color: var(--theme-color);
  width: var(--icon-size);
  height: var(--icon-size);
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 50%;
}

.video-btn::after {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.link-btn {
  font-size: 18px;
  font-weight: bold;
  position: relative;
}

.link-btn i {
  margin-left: 2px;
}

.link-btn:before {
  content: "";
  height: 2px;
  width: 0;
  background-color: var(--title-color);
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.link-btn:hover,
.link-btn.active {
  color: var(--title-color);
}

.link-btn:hover:before,
.link-btn.active:before {
  width: 100%;
}

.link-btn.style2 {
  color: var(--title-color);
}

.link-btn.style2:before {
  height: 2px;
  background-color: var(--theme-color);
  bottom: -2px;
}

.link-btn.style2:hover,
.link-btn.style2.active {
  color: var(--theme-color);
}

.simple-icon {
  border: none;
  background-color: transparent;
  font-size: 20px;
  color: var(--theme-color);
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.simple-icon:hover,
.simple-icon.ative {
  color: var(--title-color);
}

.simple-icon .btn-text {
  font-size: 16px;
  color: var(--body-color);
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 2px;
}

.scroll-btn {
  position: fixed;
  bottom: 300px;
  right: 30px;
  z-index: 94;
  opacity: 0;
  visibility: hidden;
  display: inline-block;
  border-radius: 50%;
}

.scroll-btn i {
  display: inline-block;
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  font-size: 16px;
  width: var(--btn-size, 50px);
  height: var(--btn-size, 50px);
  line-height: var(--btn-size, 50px);
  z-index: 2;
  border-radius: inherit;
  position: relative;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
}

.scroll-btn:focus i,
.scroll-btn:hover i {
  background-color: var(--title-color);
  color: var(--white-color);
}

.scroll-btn.show {
  bottom: 120px;
  opacity: 1;
  visibility: visible;
}

.scroll-btn.style2 {
  width: 52px;
  position: absolute;
  top: -26px;
  bottom: unset;
  left: 50%;
  right: unset;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.scroll-btn.style2 i {
  width: var(--btn-size, 52px);
  height: var(--btn-size, 52px);
  line-height: var(--btn-size, 40px);
  border: 6px solid var(--white-color);
}

.scrollToTop {
  position: fixed;
  right: 60px;
  bottom: 500px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  z-index: 96;
}

.scrollToTop.show {
  bottom: 55px;
  opacity: 1;
  visibility: visible;
}

/* Small devices */
@media (max-width: 767px) {
  .play-btn {
    --icon-size: 60px;
  }

  .scrollToTop {
    right: 20px;
  }

  .scrollToTop.show {
    bottom: 75px;
  }
}

/*------------------- 3.3. Titles -------------------*/
.sec-title {
  font-size: 48px;
  margin-top: -0.22em;
  line-height: 1.208;
  margin-bottom: 27px;
}

.shape-title {
  height: 12px;
  width: 80px;
  margin-bottom: 9px;
  /* background-color: var(--theme-color); */
  background-color: #9c3519;
  display: inline-block;
  -webkit-clip-path: path("M80,8.723v3.848l-8-9-8,9-8-9-8,9-8-9-8,9-8-9-8,9-8-9-8,9V8.725L8,0l8,8.727L24,0l8,8.727L40,0l8,8.727L56,0l8,8.727L72,0l8,8.727Z");
  clip-path: path("M80,8.723v3.848l-8-9-8,9-8-9-8,9-8-9-8,9-8-9-8,9-8-9-8,9V8.725L8,0l8,8.727L24,0l8,8.727L40,0l8,8.727L56,0l8,8.727L72,0l8,8.727Z");
}

.sub-title2 {
  /* font-family: var(--title-font2); */
  font-family: 'Mont-Bold', sans-serif!important;
  font-size: 30px;
  color: var(--theme-color);
  color: #9c3519;
  display: inline-block;
  position: relative;
  margin-top: -0.35em;
  margin-bottom: 20px;
}

.sub-title2 .title-shape {
  position: absolute;
  left: calc(100% + 12px);
}

.sub-title2.text-white .title-shape {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.sec-title2 {
  font-size: 60px;
  line-height: 1.167;
  color: var(--theme-color);
  margin-bottom: calc(var(--section-title-space) - 12px);
  margin-top: -0.22em;
}

.sub-title {
  font-size: 18px;
  font-weight: bold;
  /* color: var(--theme-color); */
  color: #9c3519;
  display: block;
  margin-bottom: 25px;
  margin-top: -0.26em;
}

.sub-title3 {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--theme-color);
  display: block;
  margin-bottom: 19px;
  margin-top: -0.21em;
}

.title-white .sub-title,
.title-white .sec-title {
  color: var(--white-color);
}

.title-white .shape-title {
  background-color: var(--white-color);
}

.title-area {
  margin-bottom: calc(var(--section-title-space) - 12px);
}

.title-area .line {
  -webkit-transform: translateY(-12px);
  transform: translateY(-12px);
  width: 100px;
  display: inline-block;
  position: relative;
}

.title-area .line:before,
.title-area .line:after {
  content: "";
  height: 3px;
  width: 65px;
  /* background-color: var(--theme-color); */
  background-color: #b4320e;
  position: absolute;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: 999px;
}

.title-area .line:before {
  left: 0;
}

.title-area .line:after {
  /* background-color: var(--theme-color2); */
  background-color: #b4320e;
  width: 25px;
  right: 0;
}

section:hover .title-area .line:before,
div:hover .title-area .line:before {
  width: 25px;
}

section:hover .title-area .line:after,
div:hover .title-area .line:after {
  width: 65px;
}

.home2 .title-shape,
.home3 .title-shape,
.home4 .title-shape {
  -webkit-filter: sepia(500%) hue-rotate(203deg) saturate(1200%);
  filter: sepia(500%) hue-rotate(203deg) saturate(1200%);
}

.title-area2 {
  margin-bottom: calc(var(--section-title-space) - 2px);
}

.title-area2 .sec-title {
  margin-bottom: 0;
}

.border-title {
  margin-top: -0.3em;
  padding-bottom: 22px;
  margin-bottom: 40px;
  position: relative;
}

.border-title:after {
  content: "";
  height: 2px;
  width: 60px;
  background-color: var(--theme-color);
  position: absolute;
  bottom: 0;
  left: 0;
}

.title-wrap {
  --bs-gutter-y: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: calc(var(--section-title-space) - 12px);
}

.title-wrap .sec-title,
.title-wrap .sec-title2 {
  margin-bottom: 0;
}

.title-wrap .title-area {
  margin-bottom: 0;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .sec-title {
    font-size: 46px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .sec-title {
    font-size: 42px;
  }

  .sec-title.big-title {
    font-size: 48px;
    margin-top: -0.8rem;
  }

  .sec-title2 {
    font-size: 48px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  /* :root {
    --section-title-space: 50px;
    position: absolute;
  } */

  .title-area {
    --section-title-space: 45px;
  }

  .sub-title {
    margin-bottom: 22px;
  }

  .sub-title2 {
    font-size: 22px;
  }

  .sub-title2 .title-shape {
    width: 80px;
  }

  .sec-title {
    font-size: 36px;
    margin-bottom: 15px;
  }

  .sec-title2 {
    font-size: 40px;
  }

  .sec-title2.mb-20 {
    margin-bottom: 12px;
  }

  .sec-title2.mb-30 {
    margin-bottom: 20px;
  }

  .title-area.mb-40 {
    margin-bottom: 30px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .sec-title-ndm {
    /* font-size: 30px; */
     font-size: 35px!important;
     margin-top: ;
  }
  .sec-title-ndm1 {
    /* font-size: 30px; */
     font-size: 32px!important;
  }

  .sec-title2 {
    font-size: 32px;
  }

  .title-wrap {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .title-wrap .title-area {
    text-align: center;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .sub-title {
    font-size: 16px;
  }

  .sub-title2 .title-shape {
    display: none;
  }
}

/*------------------- 3.4. Common -------------------*/
.image-scale-hover {
  overflow: hidden;
}

.image-scale-hover img {
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  -webkit-transform: scale(1.001);
  transform: scale(1.001);
}

.image-scale-hover:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

hr.style1 {
  height: 2px;
  margin: 0;
  background-color: #f7f6f8;
  opacity: 1;
}

hr.style2 {
  margin: 0;
  opacity: 1;
  background-color: #eae8f1;
}

.z-index-step1 {
  position: relative;
  z-index: 4 !important;
}

.z-index-common {
  position: relative;
  z-index: 4;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4 !important;
}

.z-index-negative {
  z-index: -1;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

section {
  position: relative;
}

.shape-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.section-before {
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: auto;
}

.section-before.style-2 {
  top: -10px;
}

.section-after {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: auto;
}

.section-after.style-2 {
  bottom: -10px;
}

.shape-after {
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
  right: 0;
  z-index: 2;
}

.shape-before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(100% - 8px);
  z-index: 2;
}

.shape-mockup-wrap {
  z-index: 2;
}

.shape-mockup {
  position: absolute;
  z-index: -1;
}

.shape-mockup.z-index-3 {
  z-index: 3;
}

.vs-hero-wrapper .shape-mockup {
  z-index: 0;
}

.vs-social {
  --icon-size: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.vs-social a {
  display: inline-block;
  background-color: var(--white-color);
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: var(--icon-size);
  color: var(--theme-color);
  font-size: 16px;
  text-align: center;
  margin-right: 15px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.vs-social a:last-child {
  margin-right: 0;
}

.vs-social a:hover {
  -webkit-transition-delay: 0s !important;
  transition-delay: 0s !important;
  color: var(--white-color);
  background-color: var(--theme-color);
}

.vs-social.style2 {
  --icon-size: 45px;
}

.vs-social.style2 a {
  border-radius: 50%;
  background-color: #171543;
  color: #8b89cd;
  margin-right: 10px;
}

.vs-social.style2 a:last-child {
  margin-right: 0;
}

.vs-social.style2 a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
}

.simple-social a {
  color: var(--body-color);
  font-size: 18px;
  margin-right: 20px;
}

.simple-social a:last-child {
  margin-right: 0;
}

.simple-social a:hover {
  color: var(--theme-color);
}

.multi-social {
  --icon-size: 45px;
  list-style: none;
}

.multi-social i.fa-facebook-f {
  --theme-color: #3b5997;
}

.multi-social i.fa-twitter {
  --theme-color: #54abf0;
}

.multi-social i.fa-pinterest-p {
  --theme-color: #c8232c;
}

.multi-social i.fa-linkedin-in {
  --theme-color: #0077b5;
}

.multi-social i.fa-vimeo-v {
  --theme-color: #86c9ef;
}

.multi-social i.fa-youtube {
  --theme-color: #ff0000;
}

.multi-social i.fa-instagram {
  --theme-color: #d63084;
}

.multi-social a {
  display: inline-block;
}

.multi-social a i {
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: var(--icon-size);
  text-align: center;
  color: var(--white-color);
  background-color: var(--theme-color);
  border-radius: 50%;
  margin-right: 7px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.multi-social a:last-child i {
  margin-right: 0;
}

.multi-social a i:hover {
  background-color: var(--title-color);
}

.check-list ul {
  padding-left: 0;
  list-style: none;
  text-align: left;
}

.check-list li {
  color: var(--body-color);
  margin-bottom: 10px;
  font-weight: 400;
  position: relative;
  padding-left: 35px;
}

.check-list li:before {
  content: "";
  /* font-family: var(--icon-font); */
  font-family: 'Mont-Bold', sans-serif!important;
  font-weight: 600;
  color: var(--theme-color);
  font-size: 18px;
  display: inline-block;
  position: absolute;
  top: -2px;
  left: 0;
}

.check-list.style-2 li {
  padding-left: 30px;
}

.check-list.style-2 li:before {
  content: "";
}

.btn-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* .slider-shadow .slick-list {
  padding-bottom: 30px;
  margin-bottom: -30px;
  padding-top: 30px;
  margin-top: -30px;
} */

.shape-mockup-wrap {
  position: relative;
  overflow: hidden;
}

.fa,
.fab,
.fad,
.fal,
.far,
.fas {
  line-height: 2.2;
  /* color: #b4320e; */
  /* padding: 9px; */
}

.d-hd-block {
  display: block !important;
}

@media (min-width: 1921px) {

  .shape-after img,
  .shape-before img,
  .section-after img,
  .section-before img {
    width: 100%;
  }
}

/* Extra large devices */
@media (max-width: 1680px) {
  .d-hd-block {
    display: none !important;
  }
}

/* Large devices */
@media (max-width: 1199px) {

  .shape-after img,
  .shape-before img,
  .section-after img,
  .section-before img {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    height: 65px;
    width: 100%;
  }

  .space-double .shape-after img,
  .space-double .shape-before img,
  .space-double .section-after img,
  .space-double .section-before img {
    height: 80px;
    width: 100%;
    -o-object-position: center left;
    object-position: center left;
  }

  .space-double .shape-after img,
  .space-double .section-after img {
    -o-object-position: center right;
    object-position: center right;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .shape-before {
    bottom: calc(100% - 5px);
  }

  .shape-after {
    top: calc(100% - 5px);
  }

  .section-before {
    top: -5px;
  }

  .section-before.style-2 {
    top: -5px;
  }

  .section-after {
    bottom: -5px;
  }

  .section-after.style-2 {
    bottom: -5px;
  }

  .counter-number {
    font-size: 36px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .check-list.style-4 ul {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .simple-social a {
    margin-right: 15px;
  }

  .simple-social a:last-child {
    margin-right: 0;
  }
}

/* Extra small devices */
@media (max-width: 416px) {
  .check-list.style-4 ul {
    grid-template-columns: repeat(1, 1fr);
  }
}

/*------------------- 3.6. Font -------------------*/
.font-icon {
  /* font-family: var(--icon-font); */
  font-family: 'Mont-Bold', sans-serif;
}

.font-title {
  /* font-family: var(--title-font); */
  font-family: 'Mont-Bold', sans-serif;
}

.font-body {
  /* font-family: var(--body-font); */
  font-family: 'Mont-Bold', sans-serif;
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-extrabold {
  font-weight: 800;
}

.fs-md {
  font-size: 18px;
}

.fs-xs {
  font-size: 14px;
}

.fs-40 {
  font-size: 40px;
}

/* Large devices */
@media (max-width: 1199px) {
  .fs-40 {
    font-size: 36px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .fs-40 {
    font-size: 32px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .fs-md {
    font-size: 16px;
  }

  .fs-40 {
    font-size: 26px;
  }
}

/*------------------- 3.7. Background -------------------*/
.bg-theme {
  background-color: var(--theme-color) !important;
}

.bg-smoke {
  background-color: var(--smoke-color) !important;
}

.bg-smoke2 {
  background-color: var(--smoke-two) !important;
}

.bg-white {
  background-color: var(--white-color) !important;
}

.bg-black {
  background-color: var(--black-color) !important;
}

.bg-black2 {
  background-color: var(--black-color2) !important;
}

.bg-title {
  background-color: var(--title-color) !important;
}

.bg-smoke3 {
  background-color: #f6f3f8;
}

.bg-smoke4 {
  background-color: #f8f8f8;
}

.bg-gradient1 {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(90deg, var(--theme-color) 0%, var(--theme-color2) 100%);
}

.bg-gradient2 {
  background-image: linear-gradient(-45deg, #510ba8 0%, #2e0875 50%, #041a57 100%);
}

.background-image,
[data-bg-src] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-fluid {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.bg-auto {
  background-size: auto auto;
}

.bg-auto-repeat {
  background-size: auto;
  background-repeat: repeat;
}

.bg-contain-top-left {
  background-size: contain;
  background-position: top left;
}

.bg-auto-top-left {
  background-size: auto;
  background-position: top left;
}

.bg-contain-top-center {
  background-size: contain;
  background-position: top center;
}

.bg-auto-top-center {
  background-size: auto;
  background-position: top center;
}

.bg-contain-top-right {
  background-size: contain;
  background-position: top right;
}

.bg-bottom-right {
  background-size: auto;
  background-position: bottom right;
}

/*------------------- 3.8. Text Color -------------------*/
.text-theme {
  color: var(--theme-color) !important;
}

.text-title {
  color: var(--title-color) !important;
}

.text-body {
  color: var(--body-color) !important;
}

.text-light {
  color: var(--light-color) !important;
}

.text-white {
  color: var(--white-color) !important;
}

.text-yellow {
  color: var(--yellow-color) !important;
}

.text-success {
  color: var(--success-color) !important;
}

.text-blue {
  color: var(--blue-color) !important;
}

.text-error {
  color: var(--error-color) !important;
}

.text-inherit {
  color: inherit;
}

.text-inherit:hover {
  color: var(--theme-color);
}

a.text-theme:hover,
.text-reset:hover {
  text-decoration: underline;
}

.text-title a,
a.text-title {
  color: var(--title-color);
}

.text-title a:hover,
a.text-title:hover {
  color: var(--theme-color) !important;
}

.text-white a,
a.text-white {
  color: var(--white-color);
}

.text-white a:hover,
a.text-white:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}

/*------------------- 3.9. Overlay -------------------*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

[data-overlay] {
  position: relative;
}

[data-overlay] [class*=container] {
  position: relative;
  z-index: 4;
}

[data-overlay] [class^=col-],
[data-overlay] [class*=col-] {
  z-index: 1;
}

[data-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

[data-overlay=theme]:before {
  background-color: var(--theme-color);
}

[data-overlay=title]:before {
  background-color: var(--title-color);
}

[data-overlay=white]:before {
  background-color: var(--white-color);
}

[data-overlay=black]:before {
  background-color: var(--black-color);
}

[data-overlay=black2]:before {
  background-color: var(--black-color2);
}

[data-overlay=overlay1]:before {
  background-color: #0d0f15;
}

[data-overlay=overlay2]:before {
  background-color: #090230;
}

[data-overlay=overlay3]:before {
  background-color: #040117;
}

[data-opacity="1"]:before {
  opacity: 0.1;
}

[data-opacity="2"]:before {
  opacity: 0.2;
}

[data-opacity="3"]:before {
  opacity: 0.3;
}

[data-opacity="4"]:before {
  opacity: 0.4;
}

[data-opacity="5"]:before {
  opacity: 0.5;
}

[data-opacity="6"]:before {
  opacity: 0.6;
}

[data-opacity="7"]:before {
  opacity: 0.7;
}

[data-opacity="8"]:before {
  opacity: 0.8;
}

[data-opacity="9"]:before {
  opacity: 0.9;
}

[data-opacity="10"]:before {
  opacity: 1;
}

/*------------------- 3.10. Animation -------------------*/
.ripple-animation,
.banner-card .banner-shape::before,
.banner-card .banner-shape::after,
.play-btn::before,
.play-btn::after,
.video-btn::before,
.video-btn::after {
  -webkit-animation-duration: var(--ripple-ani-duration);
  animation-duration: var(--ripple-ani-duration);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: ripple;
  animation-name: ripple;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }

  30% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }

  30% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7);
    opacity: 0;
  }
}

.scalein.vs-animated {
  --animation-name: scalein;
}

.scaleinX.vs-animated {
  --animation-name: scaleinX;
}

.slidetopleft.vs-animated {
  --animation-name: slidetopleft;
}

.slidebottomright.vs-animated {
  --animation-name: slidebottomright;
}

.slideinleft.vs-animated {
  --animation-name: slideinleft;
}

.slideinright.vs-animated {
  --animation-name: slideinright;
}

.slideinup.vs-animated {
  --animation-name: slideinup;
}

.slideindown.vs-animated {
  --animation-name: slideindown;
}

.rollinleft.vs-animated {
  --animation-name: rollinleft;
}

.rollinright.vs-animated {
  --animation-name: rollinright;
}

.scalein,
.scaleinX,
.slidetopleft,
.slidebottomright,
.slideinleft,
.slideinright,
.slideindown,
.slideinup,
.rollinleft,
.rollinright {
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-name: var(--animation-name);
  animation-name: var(--animation-name);
}

.vs-animated {
  opacity: 1;
}

@-webkit-keyframes slideinup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideinup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slideinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(70px);
    transform: translateX(70px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(70px);
    transform: translateX(70px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes slideindown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideindown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slideinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-70px);
    transform: translateX(-70px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-70px);
    transform: translateX(-70px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes slidebottomright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px) translateY(100px);
    transform: translateX(100px) translateY(100px);
  }

  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}

@keyframes slidebottomright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px) translateY(100px);
    transform: translateX(100px) translateY(100px);
  }

  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}

@-webkit-keyframes slidetopleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px) translateY(-100px);
    transform: translateX(-100px) translateY(-100px);
  }

  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}

@keyframes slidetopleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px) translateY(-100px);
    transform: translateX(-100px) translateY(-100px);
  }

  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}

@-webkit-keyframes scalein {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scalein {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes scaleinX {
  0% {
    opacity: 0;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes scaleinX {
  0% {
    opacity: 0;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@-webkit-keyframes rollinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }

  to {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
}

@keyframes rollinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }

  to {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
}

@-webkit-keyframes rollinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg);
  }

  to {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
}

@keyframes rollinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg);
  }

  to {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
}

.shapePulse {
  -webkit-animation: shapePulse 6s linear infinite;
  animation: shapePulse 6s linear infinite;
}

@-webkit-keyframes shapePulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes shapePulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.spin {
  -webkit-animation: spin 30s linear infinite;
  animation: spin 30s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.rotate {
  -webkit-animation: rotate 7s linear infinite;
  animation: rotate 7s linear infinite;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.jump {
  -webkit-animation: jumpAni 7s linear infinite;
  animation: jumpAni 7s linear infinite;
}

@-webkit-keyframes jumpAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes jumpAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.jump-reverse {
  -webkit-animation: jumpReverseAni 7s linear infinite;
  animation: jumpReverseAni 7s linear infinite;
}

@-webkit-keyframes jumpReverseAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes jumpReverseAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.movingX {
  -webkit-animation: movingX 7s linear infinite;
  animation: movingX 7s linear infinite;
}

@-webkit-keyframes movingX {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes movingX {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.movingX-reverse {
  -webkit-animation: movingX-reverse 7s linear infinite;
  animation: movingX-reverse 7s linear infinite;
}

@-webkit-keyframes movingX-reverse {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes movingX-reverse {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.movingTopLeft {
  -webkit-animation: movingTopLeft 10s linear infinite;
  animation: movingTopLeft 10s linear infinite;
}

@-webkit-keyframes movingTopLeft {
  0% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-200px, -200px);
    transform: translate(-200px, -200px);
  }
}

@keyframes movingTopLeft {
  0% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-200px, -200px);
    transform: translate(-200px, -200px);
  }
}

.movingBottomLeft {
  -webkit-animation: movingBottomLeft 10s linear infinite;
  animation: movingBottomLeft 10s linear infinite;
}

@-webkit-keyframes movingBottomLeft {
  0% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(150px, 150px);
    transform: translate(150px, 150px);
  }
}

@keyframes movingBottomLeft {
  0% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(150px, 150px);
    transform: translate(150px, 150px);
  }
}

@-webkit-keyframes shadowPulse {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px #572aff;
    box-shadow: 0px 0px 0px 0px #572aff;
  }

  50% {
    -webkit-box-shadow: 0px 0px 0px 16px #572aff;
    box-shadow: 0px 0px 0px 16px #572aff;
  }

  100% {
    -webkit-box-shadow: 0px 0px 0px 0px #572aff;
    box-shadow: 0px 0px 0px 0px #572aff;
  }
}

@keyframes shadowPulse {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px #572aff;
    box-shadow: 0px 0px 0px 0px #572aff;
  }

  50% {
    -webkit-box-shadow: 0px 0px 0px 16px #572aff;
    box-shadow: 0px 0px 0px 16px #572aff;
  }

  100% {
    -webkit-box-shadow: 0px 0px 0px 0px #572aff;
    box-shadow: 0px 0px 0px 0px #572aff;
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/*=================================
    04. Template Style
==================================*/
/*------------------- 4.1. Widget  -------------------*/
.widget_nav_menu ul,
.widget_meta ul,
.widget_pages ul,
.widget_archive ul,
.widget_categories ul {
  list-style: none;
  margin: -22px 0 0 0;
  padding: 0;
}

.widget_nav_menu a,
.widget_meta a,
.widget_pages a,
.widget_archive a,
.widget_categories a {
  position: relative;
  display: block;
  font-size: 16px;
  padding: 22px 40px 22px 25px;
  border-bottom: 1px solid #eaeaea;
  color: var(--title-color);
  font-weight: 400;
  line-height: 1.56;
}

/* .widget_nav_menu a:before, */
.widget_meta a:before,
.widget_pages a:before,
.widget_archive a:before,
.widget_categories a:before {
  content: "";
  /* font-family: var(--icon-font); */
  font-family: 'Mont-Bold', sans-serif;
  font-weight: 900;
  margin-right: 10px;
  color: var(--theme-color);
  position: relative;
  position: absolute;
  left: 0;
}

.widget_nav_menu a:hover,
.widget_meta a:hover,
.widget_pages a:hover,
.widget_archive a:hover,
.widget_categories a:hover {
  color: var(--theme-color);
}

.widget_nav_menu li,
.widget_meta li,
.widget_pages li,
.widget_archive li,
.widget_categories li {
  display: block;
  position: relative;
}

.widget_nav_menu li span,
.widget_meta li span,
.widget_pages li span,
.widget_archive li span,
.widget_categories li span {
  position: absolute;
  top: 20px;
  right: 0;
  color: var(--theme-color);
  background-color: transparent;
  border: 1px solid rgba(240, 64, 27, 0.4);
  min-width: 30px;
  height: 30px;
  line-height: 29px;
  text-align: center;
  border-radius: 9999999px;
  font-size: 12px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.widget_nav_menu .children,
.widget_meta .children,
.widget_pages .children,
.widget_archive .children,
.widget_categories .children {
  margin-left: 10px;
  margin-top: 0;
}

.widget_nav_menu a,
.widget_meta a,
.widget_pages a {
  padding-right: 20px;
}

.widget_nav_menu .sub-menu {
  margin-left: 10px;
}

.wp-block-archives {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.wp-block-archives a:not(:hover) {
  color: inherit;
}

.vs-blog ul.wp-block-archives li {
  margin: 5px 0;
}

.widget {
  --border-color: rgb(242, 242, 242);
  margin-bottom: 40px;
}

.widget select,
.widget input {
  color: var(--body-color);
  padding-left: 30px;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid var(--border-color);
  height: 60px;
  width: 100%;
  border-radius: 9999px;
}

.widget select::-moz-placeholder,
.widget input::-moz-placeholder {
  color: var(--body-color);
  opacity: 1;
}

.widget select::-webkit-input-placeholder,
.widget input::-webkit-input-placeholder {
  color: var(--body-color);
  opacity: 1;
}

.widget select:-ms-input-placeholder,
.widget input:-ms-input-placeholder {
  color: var(--body-color);
  opacity: 1;
}

.widget select::-webkit-input-placeholder,
.widget input::-webkit-input-placeholder {
  color: var(--body-color);
  opacity: 1;
}

.widget select::-moz-placeholder,
.widget input::-moz-placeholder {
  color: var(--body-color);
  opacity: 1;
}

.widget select:-ms-input-placeholder,
.widget input:-ms-input-placeholder {
  color: var(--body-color);
  opacity: 1;
}

.widget select::-ms-input-placeholder,
.widget input::-ms-input-placeholder {
  color: var(--body-color);
  opacity: 1;
}

.widget select::placeholder,
.widget input::placeholder {
  color: var(--body-color);
  opacity: 1;
}

.widget_title {
  font-size: 24px;
  line-height: 1em;
  margin-bottom: 28px;
  margin-top: -0.1em;
  font-weight: 700;
}

.widget .search-form {
  position: relative;
}

.widget .search-form input {
  padding-right: 50px;
}

.widget .search-form button {
  text-align: center;
  padding: 0;
  color: var(--body-color);
  background-color: transparent;
  border: none;
  position: absolute;
  right: 30px;
  line-height: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-left: 1px solid var(--border-color);
  padding-left: 23px;
}

.widget .search-form button:hover {
  color: var(--theme-color);
}

.wp-block-tag-cloud a,
.tagcloud a {
  display: inline-block;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  padding: 10px 20px;
  margin-right: 5px;
  margin-bottom: 10px;
  color: var(--body-color);
  background-color: var(--tag-bg, #fff);
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.04);
  box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.04);
  font-weight: 400;
  border-radius: 999px;
}

.wp-block-tag-cloud a:hover,
.tagcloud a:hover {
  background-color: var(--theme-color);
  color: var(--white-color) !important;
}

.tagcloud {
  margin-right: -5px;
  margin-bottom: -10px;
}

.widget .tagcloud a:not(:hover) {
  color: var(--body-color);
  background-color: var(--tag-bg, #fff);
}

.widget.widget_banner {
  padding: 0;
}

.widget.widget_banner .content {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 55px;
  left: 0;
}

.widget.widget_banner .banner-btn {
  position: absolute;
  bottom: 60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.recent-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.recent-post:last-child {
  margin-bottom: 0;
}

.recent-post .post-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
  color: var(--title-color);
  line-height: 24px;
}

.recent-post .post-title a {
  color: inherit;
}

.recent-post .post-title a:hover {
  color: var(--theme-color);
}

.recent-post .media-img {
  margin-right: 17px;
  width: 90px;
  border-radius: 8px;
  overflow: hidden;
}

.recent-post .recent-post-meta {
  line-height: 1.8;
}

.recent-post .recent-post-meta a {
  text-transform: capitalize;
  margin-right: 15px;
  font-size: 12px;
  color: #8f959b;
}

.recent-post .recent-post-meta a:hover {
  color: var(--theme-color);
}

.recent-post .recent-post-meta a i {
  margin-right: 2px;
  font-size: 12px;
}

.recent-post .recent-post-meta a:last-child {
  margin-right: 0;
}

.widget-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  max-width: 390px;
}

.widget-gallery .gallery-thumb {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.widget-gallery .gallery-thumb img {
  width: 100%;
}

.widget-gallery .gallery-thumb:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--title-color);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  border-radius: inherit;
}

.widget-gallery .gallery-thumb .icon-thumb {
  font-size: 20px;
  line-height: 14px;
  color: var(--white-color);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0.6);
  transform: translate(-50%, -50%) scale(0.6);
  visibility: hidden;
  opacity: 0;
}

.widget-gallery .gallery-thumb .icon-thumb:hover {
  color: var(--theme-color);
}

.widget-gallery .gallery-thumb:hover .icon-thumb {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

.widget-gallery .gallery-thumb:hover:before {
  opacity: 0.7;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sidebar-area ul.wp-block-latest-posts {
  margin-bottom: 0;
}

.sidebar-area ul.wp-block-latest-posts li:last-child {
  margin-bottom: 0;
}

.sidebar-area .widget {
  position: relative;
}

.sidebar-area .recent-post:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.sidebar-area .recent-post .media-img {
  margin-right: 20px;
  width: 90px;
  height: 80px;
  overflow: hidden;
}

.sidebar-area .recent-post .post-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  color: var(--title-color);
  line-height: 20px;
}

.sidebar-area .recent-post .recent-post-meta {
  margin-top: 5px;
}

.sidebar-area .recent-post .recent-post-meta a,
.sidebar-area .recent-post .recent-post-meta span {
  font-size: 14px;
  padding-right: 15px;
  margin-right: 10px;
  border-right: 1px solid #dfdfdf;
}

.sidebar-area .recent-post .recent-post-meta a:last-child,
.sidebar-area .recent-post .recent-post-meta span:last-child {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}

.sidebar-area .recent-post .recent-post-meta i {
  font-size: 14px;
}

.sidebar-area .bg {
  border-radius: 20px;
  background-color: #e3e3e3;
  position: absolute;
  left: 1262px;
  top: 1523px;
  width: 90px;
  height: 80px;
  z-index: 127;
}

.sidebar-area .widget .wp-block-search {
  margin-bottom: 0;
}

.sidebar-area .wp-block-group__inner-container h2 {
  font-size: 20px;
  line-height: 1em;
  margin-bottom: 20px;
  margin-top: -0.07em;
}

.sidebar-area ol.wp-block-latest-comments {
  padding: 0;
  margin: 0;
}

.sidebar-area ol.wp-block-latest-comments li {
  line-height: 1.5;
  margin: 0 0 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}

.sidebar-area ol.wp-block-latest-comments li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

/* Medium devices */
@media (max-width: 991px) {

  .wp-block-tag-cloud a,
  .tagcloud a {
    padding: 10.5px 18px;
  }
}

/* Small devices */
/* Extra small devices */
@media (max-width: 575px) {
  .widget.widget_banner .content {
    top: 25px;
  }

  .widget.widget_banner .banner-btn {
    bottom: 30px;
  }
}

.shop-sidebar .widget {
  padding: 0;
  border: none;
  border-radius: 0;
  overflow: visible;
}

.shop-sidebar .widget:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.shop-sidebar .widget_nav_menu ul,
.shop-sidebar .widget_meta ul,
.shop-sidebar .widget_pages ul,
.shop-sidebar .widget_archive ul,
.shop-sidebar .widget_categories ul {
  margin: -15px 0 0 0;
}

.shop-sidebar .widget_nav_menu a,
.shop-sidebar .widget_meta a,
.shop-sidebar .widget_pages a,
.shop-sidebar .widget_archive a,
.shop-sidebar .widget_categories a {
  color: var(--body-color);
  padding: 15px 0 15px 0;
}
.owl-item.cloned {
    padding: 0 0.5rem;
}
.shop-sidebar .widget_nav_menu a:before,
.shop-sidebar .widget_meta a:before,
.shop-sidebar .widget_pages a:before,
.shop-sidebar .widget_archive a:before,
.shop-sidebar .widget_categories a:before {
  display: none;
}

.shop-sidebar .widget_nav_menu a:hover,
.shop-sidebar .widget_meta a:hover,
.shop-sidebar .widget_pages a:hover,
.shop-sidebar .widget_archive a:hover,
.shop-sidebar .widget_categories a:hover {
  color: var(--theme-color);
}

.shop-sidebar .widget_nav_menu li,
.shop-sidebar .widget_meta li,
.shop-sidebar .widget_pages li,
.shop-sidebar .widget_archive li,
.shop-sidebar .widget_categories li {
  display: block;
  position: relative;
}

.shop-sidebar .widget_nav_menu li span,
.shop-sidebar .widget_meta li span,
.shop-sidebar .widget_pages li span,
.shop-sidebar .widget_archive li span,
.shop-sidebar .widget_categories li span {
  color: var(--body-color);
  font-size: 16px;
  top: 13px;
  right: 0;
  border: none;
}

.price_slider_wrapper .price_label span {
  display: inline-block;
  color: var(--body-color);
}

.price_slider_wrapper .ui-slider {
  height: 8px;
  position: relative;
  width: 100%;
  background-color: #f6f6f6;
  border: none;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 3px;
}

.price_slider_wrapper .ui-slider-range {
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  display: block;
  background-color: var(--theme-color);
}

.price_slider_wrapper .ui-slider-handle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: -2px;
  text-align: center;
  line-height: 10.5px;
  padding: 0;
  border: none;
  cursor: pointer;
  position: absolute;
  margin-top: -4px;
  z-index: 2;
  -webkit-box-shadow: 0px 8px 13px 0px rgba(239, 65, 28, 0.21);
  box-shadow: 0px 8px 13px 0px rgba(239, 65, 28, 0.21);
  background-color: var(--theme-color);
  -webkit-transform: translateX(-1px);
  transform: translateX(-1px);
}

.price_slider_wrapper .ui-slider-handle:focus {
  outline: none;
  -webkit-box-shadow: 1.5px 2.598px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 1.5px 2.598px 10px 0px rgba(0, 0, 0, 0.15);
}

.price_slider_wrapper .ui-slider-handle:before {
  content: "";
  position: absolute;
  background-color: #fff;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.price_slider_wrapper .ui-slider-handle:last-child {
  -webkit-transform: translateX(-15px);
  transform: translateX(-15px);
}

.price_slider_wrapper button,
.price_slider_wrapper .button {
  background-color: var(--theme-color);
  color: var(--white-color);
  font-weight: 500;
  line-height: 1.6;
  text-transform: capitalize;
  text-align: center;
  border-radius: 50px;
  border: none;
  display: inline-block;
  overflow: hidden;
  position: relative;
  z-index: 2;
  padding: 7px 20px;
  min-width: 100px;
  font-size: 16px;
  -webkit-transition: 0.4s ease-in;
  transition: 0.4s ease-in;
}

.price_slider_wrapper button:hover,
.price_slider_wrapper .button:hover {
  background-color: var(--title-color);
}

.product_list_widget {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.product_list_widget .recent-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 0;
  border-bottom: none;
}

.product_list_widget .recent-post:last-child {
  margin-bottom: 0;
}

.product_list_widget .recent-post .media-img {
  width: 80px;
  margin-right: 16px;
}

.product_list_widget .recent-post .media-img img {
  border-radius: 20px;
}

.product_list_widget .recent-post-title {
  margin-bottom: 3px;
}

.product_list_widget .recent-post-title a {
  color: inherit;
}

.product_list_widget .recent-post-title a:hover {
  color: var(--theme-color);
}

.product_list_widget .star-rating {
  font-size: 12px;
  margin-bottom: 3px;
}

.footer-widget {
  margin-bottom: 40px;
}

.footer-widget,
.footer-widget .widget {
  padding: 0;
  border: none;
  padding-bottom: 0;
  background-color: transparent;
}

.footer-widget .widget_title {
  position: relative;
  border-left: none;
  /* font-family: var(--title-font); */
  font-family: 'Mont-Bold', sans-serif;
  color: var(--white-color);
  margin-bottom: 42px;
  border-bottom: none;
  font-weight: bold;
  position: relative;
}

.footer-widget p,
.footer-widget span {
  color: #a5afc7;
}

.footer-widget p a,
.footer-widget span a {
  color: var(--white-color);
}

.footer-widget p a:hover,
.footer-widget span a:hover {
  color: var(--theme-color);
}

.footer-widget.widget_meta,
.footer-widget.widget_pages,
.footer-widget.widget_archive,
.footer-widget.widget_categories,
.footer-widget.widget_nav_menu {
  margin-bottom: 45px;
}

.footer-widget.widget_meta ul,
.footer-widget.widget_pages ul,
.footer-widget.widget_archive ul,
.footer-widget.widget_categories ul,
.footer-widget.widget_nav_menu ul {
  margin-top: -5px;
}

.footer-widget.widget_meta a,
.footer-widget.widget_pages a,
.footer-widget.widget_archive a,
.footer-widget.widget_categories a,
.footer-widget.widget_nav_menu a {
  background-color: transparent;
  color: #a5afc7;
  padding: 0;
  position: relative;
  margin-bottom: 19px;
  padding-left: 20px;
  display: inline-block;
  font-size: 17px;
  font-weight: 700;
  border: none;
}

.footer-widget.widget_meta a:before,
.footer-widget.widget_pages a:before,
.footer-widget.widget_archive a:before,
.footer-widget.widget_categories a:before {
  content: "";
  /* font-family: var(--icon-font); */
  font-family: 'Mont-Bold', sans-serif;
  margin-right: 10px;
  font-weight: 300;
  color: inherit;
}
.footer-widget.widget_nav_menu a i {
  /* content: "";
  font-family: var(--icon-font); */
  margin-right: 10px;
  /* font-weight: 300; */
  color: inherit;
}

.footer-widget.widget_meta a:hover,
.footer-widget.widget_pages a:hover,
.footer-widget.widget_archive a:hover,
.footer-widget.widget_categories a:hover,
.footer-widget.widget_nav_menu a:hover {
  background-color: transparent;
  color: var(--theme-color);
}

.footer-widget.widget_meta a:hover::before,
.footer-widget.widget_pages a:hover::before,
.footer-widget.widget_archive a:hover::before,
.footer-widget.widget_categories a:hover::before,
.footer-widget.widget_nav_menu a:hover::before {
  color: inherit;
}

.footer-widget.widget_meta li,
.footer-widget.widget_pages li,
.footer-widget.widget_archive li,
.footer-widget.widget_categories li,
.footer-widget.widget_nav_menu li {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.footer-widget.widget_meta li>span,
.footer-widget.widget_pages li>span,
.footer-widget.widget_archive li>span,
.footer-widget.widget_categories li>span,
.footer-widget.widget_nav_menu li>span {
  width: auto;
  height: auto;
  position: relative;
  background-color: transparent;
  color: #a5afc7;
  line-height: 1;
}

.footer-widget.widget_meta li:last-child a,
.footer-widget.widget_pages li:last-child a,
.footer-widget.widget_archive li:last-child a,
.footer-widget.widget_categories li:last-child a,
.footer-widget.widget_nav_menu li:last-child a {
  margin-bottom: 0;
}

.footer-widget .recent-post {
  max-width: 260px;
  margin-bottom: 25px;
}

.footer-widget .recent-post .post-title {
  color: var(--white-color);
}

.footer-widget .recent-post .recent-post-meta a {
  color: #a5afc7;
}

.footer-widget .recent-post .recent-post-meta a:hover {
  color: var(--theme-color);
}

.footer-widget .vs-widget-contact {
  max-width: 490px;
}

.footer-widget .vs-widget-contact .map {
  margin-bottom: 35px;
}

.footer-widget .location-info {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
}

.footer-widget .location-info span,
.footer-widget .location-info i,
.footer-widget .location-info h5 {
  color: var(--white-color);
}

.footer-widget .location-info .info-text {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0;
}

.footer-widget .location-info i {
  font-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1;
}

.footer-widget .location-info .info-title {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
  display: block;
  margin-top: -0.3em;
}

.footer-widget .location-info li {
  padding-left: 35px;
  position: relative;
}

.footer-widget .vs-widget-about {
  max-width: 310px;
}

.vs-widget-about .vs-btn {
  margin-top: 8px;
}

.footer-layout1 .vs-widget-about .vs-btn:not(:hover) {
  border-color: #353c5a;
}

.footer-layout2 .widget_title {
  font-weight: bold;
}

.footer-layout2 .footer-widget {
  text-align: center;
}

.footer-layout2 .footer-widget p,
.footer-layout2 .footer-widget span {
  color: var(--white-color);
}

.footer-layout2 .footer-widget p a,
.footer-layout2 .footer-widget span a {
  color: var(--white-color);
}

.footer-layout2 .footer-widget p a:hover,
.footer-layout2 .footer-widget span a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.footer-layout2 .footer-widget.widget_meta,
.footer-layout2 .footer-widget.widget_pages,
.footer-layout2 .footer-widget.widget_archive,
.footer-layout2 .footer-widget.widget_categories,
.footer-layout2 .footer-widget.widget_nav_menu {
  min-width: 230px;
}

.footer-layout2 .footer-widget.widget_meta a,
.footer-layout2 .footer-widget.widget_pages a,
.footer-layout2 .footer-widget.widget_archive a,
.footer-layout2 .footer-widget.widget_categories a,
.footer-layout2 .footer-widget.widget_nav_menu a {
  color: var(--white-color);
  padding-left: 0;
  margin-bottom: 14px;
}

.footer-layout2 .footer-widget.widget_meta a:before,
.footer-layout2 .footer-widget.widget_pages a:before,
.footer-layout2 .footer-widget.widget_archive a:before,
.footer-layout2 .footer-widget.widget_categories a:before,
.footer-layout2 .footer-widget.widget_nav_menu a:before {
  display: none;
}

.footer-layout2 .footer-widget.widget_meta a:hover,
.footer-layout2 .footer-widget.widget_pages a:hover,
.footer-layout2 .footer-widget.widget_archive a:hover,
.footer-layout2 .footer-widget.widget_categories a:hover,
.footer-layout2 .footer-widget.widget_nav_menu a:hover {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.7);
}

.footer-layout2 .footer-widget .vs-widget-about {
  max-width: 390px;
}

.footer-layout3 .footer-widget.widget_meta a,
.footer-layout3 .footer-widget.widget_pages a,
.footer-layout3 .footer-widget.widget_archive a,
.footer-layout3 .footer-widget.widget_categories a,
.footer-layout3 .footer-widget.widget_nav_menu a {
  color: #a9a8c9;
}

.footer-layout3 .footer-widget.widget_meta a:hover,
.footer-layout3 .footer-widget.widget_pages a:hover,
.footer-layout3 .footer-widget.widget_archive a:hover,
.footer-layout3 .footer-widget.widget_categories a:hover,
.footer-layout3 .footer-widget.widget_nav_menu a:hover {
  color: var(--theme-color);
}

.footer-layout3 .footer-widget.widget_meta li>span,
.footer-layout3 .footer-widget.widget_pages li>span,
.footer-layout3 .footer-widget.widget_archive li>span,
.footer-layout3 .footer-widget.widget_categories li>span,
.footer-layout3 .footer-widget.widget_nav_menu li>span {
  color: #a9a8c9;
}

.footer-layout3 .vs-widget-about {
  max-width: 400px;
}

.footer-layout4 .footer-widget.widget_meta a,
.footer-layout4 .footer-widget.widget_pages a,
.footer-layout4 .footer-widget.widget_archive a,
.footer-layout4 .footer-widget.widget_categories a,
.footer-layout4 .footer-widget.widget_nav_menu a {
  color: #c9d0e5;
}

.footer-layout4 .footer-widget.widget_meta a:hover,
.footer-layout4 .footer-widget.widget_pages a:hover,
.footer-layout4 .footer-widget.widget_archive a:hover,
.footer-layout4 .footer-widget.widget_categories a:hover,
.footer-layout4 .footer-widget.widget_nav_menu a:hover {
  color: var(--theme-color);
}

.footer-layout4 .footer-widget.widget_meta li>span,
.footer-layout4 .footer-widget.widget_pages li>span,
.footer-layout4 .footer-widget.widget_archive li>span,
.footer-layout4 .footer-widget.widget_categories li>span,
.footer-layout4 .footer-widget.widget_nav_menu li>span {
  color: #c9d0e5;
}

.footer-event-info .info {
  display: block;
  color: var(--white-color);
  text-transform: uppercase;
  margin-bottom: 25px;
  line-height: 1.6;
}

/* Large devices */
@media (max-width: 1199px) {
  .footer-event-info {
    max-width: 284px;
  }

  .footer-layout3 .vs-widget-about {
    max-width: 368px;
  }

  .footer-widget .vs-widget-contact {
    max-width: 400px;
  }

  .footer-widget .vs-widget-contact .map {
    height: 130px;
  }

  .footer-widget .vs-widget-contact .map iframe {
    height: 100%;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .footer-widget .widget_title {
    margin-bottom: 30px;
  }

  .footer-widget.widget_meta a,
  .footer-widget.widget_pages a,
  .footer-widget.widget_archive a,
  .footer-widget.widget_categories a,
  .footer-widget.widget_nav_menu a {
    margin-bottom: 15px;
  }

  .footer-widget .vs-widget-contact {
    max-width: 100%;
  }

  .footer-widget .vs-widget-contact .map {
    aspect-ratio: 16/5;
    height: auto;
  }

  .footer-widget .vs-widget-contact .map iframe {
    height: 100%;
  }

  .footer-layout2 .footer-widget .vs-widget-about {
    max-width: 100%;
  }

  .footer-layout2 .footer-widget.widget_meta a,
  .footer-layout2 .footer-widget.widget_pages a,
  .footer-layout2 .footer-widget.widget_archive a,
  .footer-layout2 .footer-widget.widget_categories a,
  .footer-layout2 .footer-widget.widget_nav_menu a {
    margin-bottom: 12px;
  }

  .footer-layout3 .vs-social {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .footer-layout3 .vs-widget-about {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .vs-widget-about {
    text-align: left;
    margin: unset;
    max-width: 450px;
  }

  .footer-layout2 .footer-widget .vs-widget-about {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .footer-widget .location-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
  }

  .footer-widget .location-info .info-title {
    margin-bottom: 5px;
  }

  .footer-layout3 .vs-social {
    text-align: left;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .footer-layout3 .vs-widget-about {
    margin-left: unset;
    margin-right: unset;
    text-align: left;
  }

  .footer-layout3 .footer-event-info {
    max-width: 100%;
  }
}

/*------------------- 4.2. Header  -------------------*/
.header-layout1 {
  --main-container: 1665px;
}

.header-layout2 {
  --main-container: 1220px;
}

.vs-header {
  position: relative;
}

.vs-header .container,
.vs-header .container-xl {
  position: relative;
}

.header-top {
  background-color: var(--theme-color);
  /* background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2))); */
  /* background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%); */
  color: var(--white-color);
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 1;
}

.header-top.style-2 {
  background-color: var(--white-color);
  color: var(--body-color);
  border-bottom: 1px solid #efefef;
}

.header-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-links ul li {
  display: inline-block;
  position: relative;
  font-weight: 400;
  margin-right: 30px;
  /* font-family: var(--body-font); */
  font-family: 'Mont-Bold', sans-serif;
}

.header-links ul li>i {
  margin-right: 10px;
}

.header-links ul li:last-child {
  margin-right: 0;
}

.header-links a {
  color: var(--white-color);
}

.header-links a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.header-links-title {
  margin-right: 20px;
}

.header-notice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-notice .date {
  margin-right: 20px;
  padding-right: 20px;
  position: relative;
}

.header-notice .date:after {
  content: "";
  height: 20px;
  width: 1px;
  position: absolute;
  top: 5px;
  right: 0;
  background-color: #ff886c;
}

.header-notice .day {
  font-size: 30px;
  font-weight: bold;
  margin-right: 11px;
}

.header-notice .month {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  width: 25px;
  display: inline-block;
  line-height: 1.1;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

.header-info {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
}

.header-info a {
  font-size: inherit;
  color: var(--title-color);
  display: block;
}

.header-info a:hover {
  color: var(--theme-color);
}

.header-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: end;
}

.header-button .vs-btn,
.header-button button {
  margin-left: 35px;
}

.header-button .custom-icon {
  margin-left: 30px;
}

.custom-icon {
  line-height: 0;
  border: none;
  background-color: transparent;
  margin-right: -6px;
}

.three-square {
  width: var(--icon-size, 7px);
  height: var(--icon-size, 7px);
  line-height: var(--icon-size, 7px);
  display: inline-block;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%);
  -webkit-box-shadow: 0px 11px 21px 0px rgba(240, 64, 27, 0.31);
  box-shadow: 0px 11px 21px 0px rgba(240, 64, 27, 0.31);
  position: relative;
  margin-right: var(--icon-size, 7px);
  border-radius: 50%;
}

.three-square:before,
.three-square:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--theme-color);
  position: absolute;
  top: calc(var(--icon-size, 7px)*2);
  left: 0;
  border-radius: inherit;
}

.three-square:before {
  bottom: calc(var(--icon-size, 7px)*2);
  top: unset;
}

.three-square:last-child {
  margin-right: 0;
}

.vs-menu-wrapper .vs-menu-toggle {
  right: -20px;
  top: 40px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
}

.vs-menu-wrapper .vs-menu-toggle i {
  margin-right: 0;
  line-height: 1.6;
}

.vs-menu-toggle {
  height: 40px;
  width: auto;
  padding: 5px 20px;
  border-radius: 50px;
  line-height: 1.6;
  font-size: 16px;
}

.vs-menu-toggle i {
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.will-sticky .sticky-active {
  position: fixed;
  top: -100%;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  z-index: 20;
}

.will-sticky .sticky-active.active {
  top: 0;
}

.header-logo {
  padding: 20px 0;
}

.main-menu a {
  display: block;
  position: relative;
  font-weight: bold;
  font-size: 16px;
  color: var(--title-color);
}

.main-menu a:hover {
  /* color: var(--theme-color); */
  color: #9c3519;
}
.main-menu .active {
  /* color: var(--theme-color) !important; */
  color:  #9c3519 !important;
  text-decoration: underline;
}

.main-menu>ul>li {
  margin-right: 40px;
}

.main-menu>ul>li>a {
  padding: 46px 0;
  text-transform: uppercase;
}

.main-menu>ul>li>a:hover {
  color: var(--theme-color);
}

.main-menu ul {
  margin: 0;
  padding: 0;
}

.main-menu ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
}

.main-menu ul li.menu-item-has-children>a:after {
  content: "";
  position: relative;
  font-family: 'fontawesome';
  margin-left: 4px;
  top: 0;
}

.main-menu ul li:last-child {
  margin-right: 0;
}

.main-menu ul li:first-child {
  margin-left: 0;
}

.main-menu ul li:hover>ul.sub-menu,
.main-menu ul li:hover>ul.mega-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  z-index: 9;
}

.main-menu ul.sub-menu,
.main-menu ul.mega-menu {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background-color: var(--white-color);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  visibility: hidden;
  min-width: 190px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 7px;
  left: -14px;
  margin-top: 50px;
  opacity: 0;
  z-index: -1;
  border-bottom: 3px solid var(--theme-color);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09), 0px 3px 0px 0px rgba(231, 13, 60, 0.004);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09), 0px 3px 0px 0px rgba(231, 13, 60, 0.004);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, z-index 0s;
  transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, z-index 0s;
}

.main-menu ul.sub-menu {
  padding: 18px 20px;
  left: -27px;
}

.main-menu ul.sub-menu:before {
  content: "";
  position: absolute;
  left: 34.5px;
  top: 30px;
  width: 1px;
  background-color: #ededed;
  height: calc(100% - 60px);
}

.main-menu ul.sub-menu li {
  display: block;
  margin: 0 0;
  padding: 3px 9px;
  line-height: 1.556;
}

.main-menu ul.sub-menu li.menu-item-has-children>a:after {
  content: "";
  float: right;
  top: 3px;
}

.main-menu ul.sub-menu li .nav-sub {
  position: relative;
  padding-left: 21px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.main-menu ul.sub-menu li .nav-sub:before {
  content: "";
  position: absolute;
  top: 2em;
  left: 0;
  /* font-family: var(--icon-font); */
  font-family: 'Mont-Bold', sans-serif;
  width: 11px;
  height: 11px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 0.18em;
  line-height: 11px;
  color: var(--theme-color);
  font-weight: 700;
  background-color: var(--white-color);
  -webkit-box-shadow: inset 0px 2px 4px 0px rgba(173, 136, 88, 0.4);
  box-shadow: inset 0px 2px 4px 0px rgba(173, 136, 88, 0.4);
}

.main-menu ul.sub-menu li .nav-sub:hover {
  /* color: var(--theme-color); */
  color: #9c3519;
}

.main-menu ul.sub-menu li ul.sub-menu {
  left: 100%;
  right: auto;
  top: 0;
  margin: 0 0;
  margin-left: 20px;
}

.main-menu ul.sub-menu li ul.sub-menu li ul {
  left: 100%;
  right: auto;
}

.main-menu .mega-menu-wrap {
  position: static;
}

.main-menu ul.mega-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  max-width: var(--main-container);
  padding: 20px 15px 23px 15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.main-menu ul.mega-menu li {
  display: block;
  width: 100%;
  padding: 0 15px;
}

.main-menu ul.mega-menu li li {
  padding: 4px 0;
}

.main-menu ul.mega-menu li a {
  display: inline-block;
}

.main-menu ul.mega-menu>li>a {
  display: block;
  padding: 0;
  padding-bottom: 15px;
  margin-bottom: 10px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 700;
  color: var(--title-color);
  border-color: var(--theme-color);
}

.main-menu ul.mega-menu>li>a::after,
.main-menu ul.mega-menu>li>a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15px;
  height: 1px;
  background-color: var(--theme-color);
}

.main-menu ul.mega-menu>li>a::after {
  width: calc(100% - 20px);
  left: 20px;
}

.main-menu ul.mega-menu>li>a:hover {
  padding-left: 0;
}

.main-menu.menu-style2>ul>li {
  padding: 15px 0;
}

.header-layout3 .header-logo {
  float: right;
  width: 180px;
  text-align: center;
  /* border: 3px solid var(--theme-color); */
  border: 3px solid  #9c3519;
  border-radius: 20px;
  background-color: var(--white-color);
  padding: 8px 0;
  height: 160px;
  -webkit-transform: translateY(-25px);
  transform: translateY(-25px);
  -webkit-box-shadow: 0px 11px 43px 0px rgba(240, 64, 27, 0.1);
  box-shadow: 0px 11px 43px 0px rgba(240, 64, 27, 0.1);
}

.header-layout3 .header-logo img,
.header-layout3 .header-logo svg {
  max-width: 145px;
}
.header-layout3 .header-logo-right {
  width: 180px;
  position: relative;
  z-index: 9;
  text-align: center;
  /* border: 3px solid var(--theme-color); */
  border: 3px solid  #9c3519;
  border-radius: 20px;
  background-color: var(--white-color);
  padding: 0 0;
  -webkit-transform: translateY(-25px);
  transform: translateY(-25px);
  -webkit-box-shadow: 0px 11px 43px 0px rgba(240, 64, 27, 0.1);
  box-shadow: 0px 11px 43px 0px rgba(240, 64, 27, 0.1);
}

.header-layout3 .header-logo-right img,
.header-layout3 .header-logo-right svg {
  max-width: 155px;
}

.header-layout3 .header-top {
  /* background-image: -webkit-gradient(linear, left top, right top, from( #9c3519), to(var  #9c3519)); */
  /* background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%); */
  /* background-image:  */
  background-color:  #9c3519!important;
  padding-top: 15px;
  padding-bottom: 15px;
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0 0 999px 999px;
}

/* .header-layout3 .header-top .container {
  padding-left: 260px;
} */

.header-layout3 .header-button .vs-btn,
.header-layout3 .header-button button {
  margin-left: 80px;
}

.header-layout3 .header-links ul li {
  margin-right: 15px;
}

.header-layout3 .header-links ul li:last-child {
  margin-right: 0;
}

.header-layout3 .vs-menu-toggle {
  margin-left: auto;
  display: block;
}

.header-layout3 .sticky-active.active .header-logo {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  padding: 26px 0;
}

.header-layout4 .header-top {
  background-image: none;
}

.header-layout4 .three-square {
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--theme-color);
}

/* Extra large devices */
@media (max-width: 1680px) {
  .simple-icon .btn-text {
    display: none;
  }

  .main-menu>ul>li {
    margin-right: 30px;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .main-menu>ul>li {
    margin-right: 30px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .header-links ul li:nth-child(3) {
    display: none;
  }

  .header-layout3 .header-top .container {
    padding-left: 247px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .sticky-active {
    padding: 15px 0;
  }

  .header-logo {
    padding: 15px 0;
  }

  .header-layout3 .header-logo {
    width: auto;
    text-align: left;
    border: none;
    background-color: transparent;
    padding: 15px 0 !important;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .header-layout3 .header-logo-right {
    width: auto;
    text-align: left;
    border: none;
    background-color: transparent;
    padding: 0px 0 !important;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

/* Extra small devices */
@media (max-width: 416px) {
  .header-logo {
    max-width: 140px;
  }
}

/*------------------- 4.3. Footer  -------------------*/
.footer-wrapper {
  position: relative;
}

.footer-wrapper .widget-area {
  padding-top: var(--section-space);
  padding-bottom: calc(var(--section-space) - 50px);
}

.copyright {
  text-align: center;
  margin-bottom: 0;
  color: var(--title-color);
  padding: 27px 0;
}

.copyright a {
  color: inherit;
}

.copyright a:hover {
  color: var(--theme-color);
}

.footer-layout1 {
  background-color: #131a36;
}

.footer-layout1 p {
  color: #a5afc7;
}

.footer-layout1 p a {
  color: var(--white-color);
}

.footer-layout1 p a:hover {
  color: var(--theme-color);
}

.footer-layout1 .copyright a:hover {
  color: var(--title-color);
}

.footer-top {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: var(--section-space);
}

.footer-layout2 .widget-area {
  background-color: #131a36;
}

.footer-layout2 .footer-copyright {
  background-color: var(--theme-color);
}

.footer-layout2 .copyright {
  text-align: left;
  color: var(--white-color);
}

.footer-layout2 .copyright a:hover {
  color: var(--light-color) !important;
}

.footer-layout2 .footer-bottom-menu ul li a {
  color: var(--white-color);
}

.footer-layout2 .footer-bottom-menu ul li a:hover {
  color: var(--light-color);
}

.newsletter-form .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 14px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

.newsletter-form input {
  background-color: #232323;
  color: var(--white-color);
  border-radius: 0;
  border: none;
  max-width: calc(100% - 150px);
}

.newsletter-form input:focus {
  border: none;
  outline: none;
  background-color: #323232;
}

.newsletter-form.style2 input {
  width: 100%;
  max-width: 100%;
  border: 1px solid #efefef;
  -webkit-box-shadow: 0px 17px 30px 0px rgba(15, 15, 18, 0.08);
  box-shadow: 0px 17px 30px 0px rgba(15, 15, 18, 0.08);
  border-radius: 9999px;
  background-color: var(--white-color);
}

.newsletter-form.style2 .vs-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.newsletter-form.style3 input {
  height: 65px;
  width: 100%;
  max-width: 100%;
  border: none;
  -webkit-box-shadow: 0px 17px 30px 0px rgba(15, 15, 18, 0.08);
  box-shadow: 0px 17px 30px 0px rgba(15, 15, 18, 0.08);
  border-radius: 9999px;
  background-color: var(--white-color);
  padding-left: 30px;
}

.newsletter-form.style3 .vs-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.newsletter-form.style3 .vs-btn i {
  display: none;
}

.newsletter-wrap {
  position: relative;
  z-index: 2;
  padding: 80px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 17px 46px 0px rgba(24, 32, 61, 0.15);
  box-shadow: 0px 17px 46px 0px rgba(24, 32, 61, 0.15);
}

.newsletter-wrap .shape {
  position: absolute;
  top: 15px;
  left: 30px;
  width: calc(100% - 60px);
  height: 100%;
  background-color: rgba(255, 255, 255, 0.12);
  z-index: -1;
  border-radius: inherit;
}

.newsletter-wrap .sec-title {
  margin-bottom: -0.25em;
}

.newsletter-wrap .newsletter-title {
  font-size: 40px;
  color: var(--white-color);
  margin-bottom: -0.34em;
  margin-top: -0.25em;
}

.about-logo {
  margin-bottom: 40px;
  margin-top: -12px;
}

.about-text {
  margin-bottom: 23px;
}

.footer-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-info a {
  color: inherit;
}

.footer-info a:hover {
  color: var(--theme-color);
}

.footer-info i {
  color: var(--theme-color);
  margin-right: 10px;
  line-height: 1.6;
}

.footer-copyright {
  position: relative;
}

.footer-layout1 .footer-copyright {
  background-color: var(--theme-color);
}

.footer-layout1 .footer-copyright .copyright {
  color: var(--white-color);
}

.footer-layout3 {
  background-color: #0c0b2f;
}

.footer-layout3 p {
  color: #a9a8c9;
}

.footer-layout3 p a {
  color: #a9a8c9;
}

.footer-layout3 p a:hover {
  color: var(--theme-color);
}

.footer-layout3 .footer-copyright {
  border-top: 1px solid #252353;
  background-color: #171543;
}

.footer-menu-wrapper {
  background-color: #171543;
  padding: 28px 0;
  text-align: center;
}

.footer-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-menu li {
  display: inline-block;
  margin: 8px 20px;
}

.footer-menu li a {
  color: var(--white-color);
  font-weight: 500;
}

.footer-menu li a:hover {
  color: var(--theme-color);
}

.footer-bottom-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-bottom-menu ul li {
  display: inline-block;
  margin-left: 25px;
}

.footer-bottom-menu ul li a {
  color: var(--title-color);
}

.footer-bottom-menu ul li a:hover {
  color: var(--theme-color);
}

.footer-layout4 p {
  color: #c9d0e5;
}

.footer-layout4 p a {
  color: #c9d0e5;
}

.footer-layout4 p a:hover {
  color: var(--theme-color);
}

.footer-layout4 .vs-social {
  --icon-size: 40px;
}

.footer-layout4 .vs-social a {
  background-color: transparent;
  /* border: 2px solid #2d3d66; */
  border: 2px solid #912929;
  border-radius: 50%;
  font-size: 14px;
  color: #fcb421;
  margin-right: 10px;
}

.footer-layout4 .vs-social a:last-child {
  margin-right: 0;
}

.footer-layout4 .vs-social a:hover {
  /* background-color: #2d3d66; */
  background-color: #9c3519;
  color: white!important;
}

.footer-layout4 .footer-copyright {
  /* background-color: var(--theme-color); */
  background-color:  #9c3519;
}

.footer-layout4 .copyright {
  color: var(--white-color);
}

.footer-layout4 .copyright a {
  color: var(--white-color);
}

.footer-layout4 .copyright a:hover {
  color: var(--title-color) !important;
}

/* Large devices */
@media (max-width: 1199px) {
  .newsletter-wrap {
    padding: 50px 40px;
  }

  .newsletter-wrap .newsletter-title {
    font-size: 34px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .about-logo {
    margin-bottom: 30px;
    margin-top: 0;
  }

  .copyright {
    padding: 17px 0;
  }

  .footer-wrapper .widget-area {
    padding-top: var(--section-space-mobile);
    padding-bottom: calc(var(--section-space-mobile) - 40px);
  }

  .footer-layout2 .copyright {
    text-align: center;
    padding: 40px 0 25px 0;
  }

  .footer-top {
    padding-top: 90px;
  }

  .footer-layout4 .about-logo {
    margin-bottom: 28px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .newsletter-wrap .newsletter-title {
    font-size: 28px;
  }

  .footer-menu li {
    margin: 8px 10px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .newsletter-form input {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .newsletter-wrap {
    padding: 50px 20px;
  }

  .newsletter-form.style2 .vs-btn {
    position: relative;
  }
}

/* Extra small devices */
@media (max-width: 416px) {
  .newsletter-form.style3 .form-control {
    padding-left: 25px;
  }

  .newsletter-form.style3 .vs-btn {
    min-width: auto;
    font-size: 0;
    height: 55px;
    width: 55px;
    padding: 0;
  }

  .newsletter-form.style3 .vs-btn i {
    margin-right: 0;
    display: block;
  }
}

/*------------------- 4.4. Breadcumb  -------------------*/
.breadcumb-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #171b2a;
  background-color: var(--title-color);
  background-position:center;
  position: relative;
  z-index: 3;
}
@media (max-width: 600px) {
  .breadcumb-wrapper {
    background-size: contain;
  }
}

.breadcumb-wrapper[data-overlay]::before {
  z-index: -1;
}

.breadcumb-content {
  padding-top: 155px;
  padding-bottom: 155px;
}

.breadcumb-menu {
  max-width: 100%;
  margin: 23px 0 -0.48em 0;
  padding: 0;
  list-style-type: none;
}

.breadcumb-menu li {
  display: inline;
  margin-right: 3px;
  padding-right: 2px;
  list-style: none;
  position: relative;
}

.breadcumb-menu li:after {
  content: "/";
  margin-left: 10px;
}

.breadcumb-menu li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.breadcumb-menu li:last-child:after {
  display: none;
}

.breadcumb-menu li,
.breadcumb-menu a,
.breadcumb-menu span {
  white-space: normal;
  color: inherit;
  word-break: break-word;
  font-weight: 500;
  font-size: 18px;
  color: var(--white-color);
}

.breadcumb-menu li i,
.breadcumb-menu a i,
.breadcumb-menu span i {
  margin-right: 10px;
  font-size: 0.8rem;
  position: relative;
  top: -1px;
}

.breadcumb-menu a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.breadcumb-title {
  font-size: 48px;
  line-height: 1.13;
  font-weight: bold;
  margin-top: -0.2em;
  color: var(--white-color);
  margin-bottom: -0.2em;
}

/* Large devices */
@media (max-width: 1199px) {
  .breadcumb-title {
    font-size: 44px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .breadcumb-content {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .breadcumb-menu {
    margin: 18px 0 -0.48em 0;
  }

  .breadcumb-title {
    font-size: 40px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .breadcumb-title {
    font-size: 36px;
  }

  .breadcumb-menu li,
  .breadcumb-menu a,
  .breadcumb-menu span {
    font-size: 16px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .breadcumb-title {
    font-size: 32px;
  }

  .breadcumb-content {
    padding-top: var(--section-space-mobile);
    padding-bottom: var(--section-space-mobile);
  }

  .breadcumb-menu {
    margin: 16px 0 -0.48em 0;
  }
}

/*------------------- 4.5. Pagination  -------------------*/
.vs-pagination ul {
  margin: 0;
  padding: 0;
}

.vs-pagination li {
  display: inline-block;
  margin: 0 3px;
  list-style-type: none;
}

.vs-pagination li:last-child {
  margin-right: 0;
}

.vs-pagination li:first-child {
  margin-left: 0;
}

.vs-pagination span,
.vs-pagination a {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: var(--title-color);
  text-align: center;
  position: relative;
  background-color: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.04);
  box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.04);
  z-index: 1;
  font-weight: 600;
  color: var(--theme-color);
  font-size: 16px;
  border-radius: 9999px;
}

.vs-pagination span i,
.vs-pagination a i {
  position: relative;
  top: 0.01em;
  font-size: 16px;
  line-height: 1;
}

.vs-pagination span.active,
.vs-pagination span:hover,
.vs-pagination a.active,
.vs-pagination a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

/* Small devices */
@media (max-width: 767px) {

  .vs-pagination span,
  .vs-pagination a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }

  .vs-pagination span i,
  .vs-pagination a i {
    font-size: 12px;
  }
}

/* Extra small devices */
@media (max-width: 416px) {

  .vs-pagination span,
  .vs-pagination a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

/*------------------- 4.6. Blog  -------------------*/
.blog-box {
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 11px 21px 0px rgba(230, 231, 237, 0.31);
  box-shadow: 0px 11px 21px 0px rgba(230, 231, 237, 0.31);
  border-radius: 15px;
  overflow: hidden;
}

.blog-box .blog-content {
  background-color: var(--white-color);
  padding: 40px;
}

.blog-box .blog-meta {
  margin-top: -0.18em;
  margin-bottom: 3px;
}

.blog-box .blog-title {
  margin-bottom: 23px;
  font-size: 24px;
}

.blog-box .vs-btn {
  background-color: transparent;
  background-image: none;
  border: 1px solid var(--border-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--title-color);
  padding: 19.5px 35px;
}

.blog-box .vs-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}

.blog-box:hover .blog-img img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}

.blog-card {
  margin-bottom: 30px;
}

.blog-card .blog-content {
  -webkit-box-shadow: 0px 9px 46px 0px rgba(153, 153, 153, 0.14);
  box-shadow: 0px 9px 46px 0px rgba(153, 153, 153, 0.14);
  border-radius: 0 15px 15px 15px;
  max-width: 490px;
  padding: 40px 40px 32px 40px;
  background-color: var(--white-color);
  position: relative;
  margin-top: -60px;
  z-index: 2;
}

.blog-card .blog-title {
  border-bottom: 1px solid #eef1f2;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 24px;
}

.blog-card .blog-img {
  border-radius: 15px;
  overflow: hidden;
}

.blog-card .blog-meta {
  margin-top: -0.22em;
  margin-bottom: 4px;
}

.blog-card .blog-meta a,
.blog-card .blog-meta span {
  font-weight: 400;
}

.blog-grid {
  margin-bottom: 30px;
}

.blog-grid .blog-img {
  margin-bottom: 35px;
  border-radius: 15px;
}

.blog-grid .blog-meta {
  margin-bottom: 10px;
}

.blog-grid .blog-title {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 22px;
  margin-bottom: 20px;
  font-size: 24px;
}

.blog-grid:hover .blog-img img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}

.blog-block {
  background-color: var(--white-color);
  border-radius: 15px;
  -webkit-box-shadow: 0px 10px 25px 0px rgba(15, 15, 18, 0.04);
  box-shadow: 0px 10px 25px 0px rgba(15, 15, 18, 0.04);
  border: 1px solid #efefef;
  overflow: hidden;
}

.blog-block .blog-img {
  position: relative;
  margin: -1px;
}

.blog-block .blog-img img {
  width: 100%;
}

.blog-block .category {
  position: absolute;
  top: 40px;
  left: 40px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: var(--white-color);
  padding: 10.5px 20px;
  border-radius: 999px;
}

.blog-block .category:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.blog-block .blog-content {
  padding: 40px 40px 32px 40px;
}

.blog-block .blog-meta {
  margin-top: -0.2em;
}

.blog-block .blog-meta a,
.blog-block .blog-meta span {
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 25px;
}

.blog-block .blog-meta a:last-child,
.blog-block .blog-meta span:last-child {
  margin-right: 0;
}

.blog-block .blog-meta i {
  color: var(--theme-color);
}

.blog-block .blog-title {
  font-size: 24px;
}

.blog-block .link-btn {
  font-size: 16px;
  font-weight: bold;
}

.blog-block .link-btn:before {
  background-color: var(--theme-color);
}

.blog-block .link-btn:hover {
  color: var(--theme-color);
}

.blog-img {
  overflow: hidden;
}

.blog-img img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.vs-blog {
  position: relative;
}

.author-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.author-meta .author-avater {
  margin-right: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.author-meta .author-name {
  margin-bottom: -3px;
  font-weight: 600;
  font-size: 18px;
}

.author-meta .author-name a {
  color: inherit;
}

.author-meta .author-name a:hover {
  color: var(--theme-color);
}

.author-meta .blog-meta {
  margin-bottom: 0;
}

.author-meta .blog-meta a,
.author-meta .blog-meta span {
  margin-top: 8px;
  margin-bottom: 0;
}

.vs-quote {
  font-size: 22px;
  line-height: 36px;
  padding: 50px 60px 50px 60px;
  font-weight: 400;
  display: block;
  position: relative;
  background-color: var(--theme-color);
  overflow: hidden;
  margin: 35px 0;
  color: var(--white-color);
  border-radius: 15px;
}

.vs-quote p {
  font-size: inherit;
  /* font-family: inherit; */
  font-family: 'Mont-Bold', sans-serif;
  margin-bottom: 0 !important;
  line-height: inherit;
  color: inherit;
  width: 100%;
  position: relative;
  z-index: 3;
}

.vs-quote:before {
  content: "";
  font-family: var(--icon-font);
  position: absolute;
  right: 55px;
  bottom: 50px;
  font-size: 8rem;
  font-weight: 100;
  opacity: 1;
  line-height: 6rem;
  color: #fd6747;
  text-shadow: 3px 0px 0px #fd6747;
}

.vs-quote p {
  margin-bottom: 0;
}

.vs-quote p a {
  color: inherit;
}

.vs-quote cite {
  display: inline-block;
  font-size: 18px;
  position: relative;
  border-color: inherit;
  line-height: 1;
  font-weight: 700;
  margin-top: 25px;
  padding-left: 15px;
  font-style: normal;
  /* font-family: var(--body-font); */
  font-family: 'Mont-Bold', sans-serif;
  padding-left: 30px;
  margin-left: 10px;
}

.vs-quote cite:before {
  content: "";
  height: 3px;
  width: 30px;
  background-color: var(--white-color);
  position: absolute;
  top: 7px;
  left: -10px;
}

.vs-quote.style-left-icon {
  font-size: 18px;
  color: var(--body-color);
  font-weight: 400;
  line-height: 1.556;
  background-color: var(--smoke-color);
  padding: 55px 60px;
  padding-left: 160px;
}

.vs-quote.style-left-icon:before {
  right: unset;
  left: 56px;
  top: 60px;
  font-size: 6rem;
  font-weight: 400;
  line-height: 4rem;
  color: var(--theme-color);
  text-shadow: none;
}

.vs-quote.style-left-icon cite {
  color: var(--title-color);
}

.vs-quote.style-left-icon cite:before {
  background-color: var(--title-color);
  top: 8px;
}

.blog-meta span,
.blog-meta a {
  display: inline-block;
  margin-right: 18px;
  font-size: 16px;
  color: var(--body-color);
  margin-bottom: 8px;
}

.blog-meta span:last-child,
.blog-meta a:last-child {
  margin-right: 0;
}

.blog-meta span i,
.blog-meta a i {
  margin-right: 8px;
  color: inherit;
  font-size: 14px;
  position: relative;
  top: -1px;
}

.blog-meta span:hover,
.blog-meta a:hover {
  color: var(--theme-color);
}

.blog-meta span a::before {
  content: ",";
  margin-right: 5px;
  color: var(--body-color) !important;
}

.blog-meta span a:first-of-type::before {
  content: "";
}

.blog-category {
  margin-bottom: -10px;
}

.blog-category a {
  display: inline-block;
  color: #fff;
  padding: 4.5px 24.5px;
  margin-right: 5px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  background-color: var(--theme-color);
}

.blog-category a:hover {
  background-color: var(--white-color);
  color: var(--body-color);
  border-color: var(--theme-color);
}

.blog-title a {
  color: inherit;
}

.blog-title a:hover {
  color: var(--theme-color);
}

.blog-inner-title {
  margin-bottom: 30px;
  margin-top: -0.3em;
}

.blog-author {
  margin-top: 60px;
  margin-bottom: 55px;
  padding: 60px;
  border-right: 8px solid var(--theme-color);
  position: relative;
  background-color: var(--smoke-color);
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-author .author-degi {
  margin-bottom: 5px;
  color: var(--theme-color);
  font-weight: 400;
  font-size: 14px;
}

.blog-author .media-img {
  display: inline-block;
  background-color: #e4e4e4;
  margin-right: 25px;
  border-radius: 15px;
  overflow: hidden;
  width: 150px;
  height: 150px;
}

.blog-author .media-img img {
  border-radius: inherit;
}

.blog-author .author-name {
  margin-bottom: 10px;
  color: var(--title-color);
}

.blog-author .author-name a {
  color: inherit;
}

.blog-author .author-name a:hover {
  color: var(--theme-color);
}

.blog-single {
  margin-bottom: 60px;
}

.blog-single .blog-meta span,
.blog-single .blog-meta a {
  font-size: 14px;
}

.blog-single .blog-meta span i,
.blog-single .blog-meta a i {
  margin-right: 5px;
}

.blog-single .blog-img,
.blog-single .blog-audio,
.blog-single .blog-video {
  position: relative;
  margin-bottom: 40px;
  border-radius: 15px;
  overflow: hidden;
}

.blog-single .blog-audio {
  line-height: 1;
}

.blog-single .blog-title {
  line-height: 1.278;
  color: var(--title-color);
  margin-bottom: 30px;
  margin-top: -0.29em;
}

.blog-single .blog-title a {
  color: inherit;
}

.blog-single .blog-title a:hover {
  color: var(--theme-color);
}

.blog-single .blog-text {
  margin-bottom: 0;
  margin-top: 20px;
}

.blog-single .vs-btn {
  margin-top: 30px;
}

.blog-single:hover .blog-img img,
.blog-single:hover .blog-audio img,
.blog-single:hover .blog-video img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.share-links {
  margin-top: 50px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--border-color);
}

.share-links-title {
  font-size: 18px;
  color: var(--title-color);
  display: block;
  margin-bottom: 10px;
  font-family: var(--title-font);
  font-weight: 600;
  margin-top: -0.45em;
}

.social-links {
  margin-top: -0.05em;
}

.blog-single .blog-img {
  position: relative;
}

/* .blog-single .blog-img .slick-arrow {
  border: none;
  position: absolute;
  left: 40px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  --title-color: #fff;
}

.blog-single .blog-img .slick-arrow.slick-next {
  left: auto;
  right: 40px;
} */

.blog-single .blog-img .play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* .blog-single .slick-arrow {
  --icon-size: 50px;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.15);
  color: var(--white-color);
  border-radius: 50%;
}

.blog-single:hover .blog-img .slick-arrow {
  opacity: 1;
  visibility: visible;
} */

.blog-details .blog-single {
  position: relative;
  margin-bottom: 30px;
  border: none;
}

.blog-details .vs-btn {
  margin-top: unset;
}

.blog-details .author-meta {
  margin-bottom: 20px;
}

.blog-details .multi-social {
  --icon-size: 35px;
  margin-bottom: 0;
  padding-left: 0;
}

.blog-details .multi-social li {
  display: inline-block;
}

.blog-details .multi-social a {
  margin-left: 5px;
  font-size: 14px;
}

.blog-inner-list ul {
  list-style: none;
  padding-left: 30px;
}

.blog-inner-list ul li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 25px;
}

.blog-inner-list ul li:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--theme-color);
}

.blog-inner-list ul li:last-child {
  margin-bottom: 0;
}

/* Large devices */
@media (max-width: 1199px) {
  .blog-card .blog-content {
    max-width: 420px;
  }

  .blog-block .blog-content {
    padding: 40px 20px 32px 20px;
  }

  .blog-block .category {
    top: 20px;
    left: 20px;
  }

  .blog-author {
    padding: 35px;
  }

  .vs-quote {
    padding: 30px 40px;
    font-size: 20px;
    line-height: 1.5;
  }

  .vs-quote.style-left-icon {
    padding: 30px 40px;
    padding-left: 135px;
  }

  .vs-quote.style-left-icon:before {
    left: 36px;
    top: 35px;
  }

  .blog-details .blog-single .blog-title {
    font-size: 32px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .blog-box .blog-content {
    padding: 30px;
  }

  .blog-box .blog-title {
    margin-bottom: 20px;
  }

  .blog-card .blog-content {
    max-width: 480px;
  }

  .vs-quote {
    font-size: 16px;
    line-height: 1.65;
  }

  .vs-quote cite {
    font-size: 16px;
    margin-top: 20px;
  }

  .vs-quote cite:before {
    height: 2px;
  }

  .vs-quote:before {
    right: 23px;
    bottom: 20px;
    font-size: 8rem;
    line-height: 5rem;
    text-shadow: 3px 0 0;
  }

  .vs-quote.style-left-icon {
    padding-left: 40px;
  }

  .vs-quote.style-left-icon:before {
    left: unset;
    top: unset;
    right: 30px;
    bottom: 30px;
  }

  .blog-author .media-img {
    width: 100px;
    height: 100px;
  }

  .blog-single .blog-title {
    line-height: 1.2;
    font-size: 30px;
  }

  .blog-single .mb-50,
  .blog-single .mb-40 {
    margin-bottom: 30px;
  }

  .vs-pagination.mt-60 {
    margin-top: 30px;
  }

  .blog-details .blog-single .blog-title {
    font-size: 28px;
  }

  .sidebar-area {
    margin-top: 40px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .blog-block .blog-title {
    font-size: 22px;
  }

  .blog-box .blog-title,
  .blog-grid .blog-title,
  .blog-card .blog-title {
    font-size: 22px;
  }

  .blog-meta span,
  .blog-meta a {
    margin-right: 20px;
    font-size: 16px;
  }

  .share-links .row {
    gap: 30px 0;
  }

  .blog-author {
    padding: 35px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 20px;
  }

  .vs-quote {
    padding: 30px 20px;
    font-size: 16px;
    line-height: 26px;
  }

  .vs-quote:before {
    right: 22px;
    font-size: 5rem;
    line-height: 3rem;
    text-shadow: 2px 0 0;
  }

  .vs-quote.style-left-icon {
    padding: 25px;
    padding-left: 25px;
  }

  .vs-quote.style-left-icon:before {
    right: 25px;
    bottom: 17px;
    font-size: 4rem;
  }

  .blog-single .share-links-title {
    margin-bottom: 6px;
  }

  .blog-details .blog-single {
    position: relative;
  }

  .blog-details .blog-single .blog-title {
    line-height: 1.2;
    font-size: 26px;
  }

  .blog-details .blog-single .blog-meta li {
    font-size: 14px;
    margin-right: 20px;
  }

  .blog-details .multi-social {
    --icon-size: 35px;
  }

  .blog-details .multi-social a {
    margin-left: 0;
    margin-right: 5px;
  }

  .blog-author .media-img {
    width: 100px;
    height: 100px;
  }

  .share-links {
    margin-top: 20px;
    padding-bottom: 25px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .blog-card .blog-content {
    max-width: 100%;
    padding: 40px 20px 32px 20px;
    margin-top: 0;
    border-radius: 0 0 15px 15px;
  }

  .blog-card .blog-img {
    border-radius: 15px 15px 0 0;
  }

  .blog-inner-list ul {
    padding-left: 0;
  }

  .blog-single .blog-title {
    font-size: 24px;
  }

  .author-meta {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 18px;
  }

  .vs-comment-form {
    padding: 25px 20px;
  }
}

@media (max-width: 375px) {

  .blog-meta span,
  .blog-meta a {
    font-size: 14px;
  }

  .blog-meta span i,
  .blog-meta a i {
    font-size: 12px;
  }
}

/*------------------- 4.7. Comments  -------------------*/
.vs-comment-wrap .blog-inner-title {
  margin-bottom: 0;
}

ul.comment-list,
.children {
  list-style: none;
  margin: 0;
  padding: 0;
}

.vs-post-comment {
  --border-color: rgb(241, 240, 239);
  padding: 40px 0 34px 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-bottom: 1px solid var(--border-color);
}

.vs-post-comment .comment-avater {
  width: 100px;
  height: 100px;
  margin-right: 30px;
  overflow: hidden;
  border-radius: 50%;
  float: left;
}

.vs-post-comment .comment-content {
  position: relative;
  line-height: 1;
  padding-left: 130px;
}

.vs-post-comment .name {
  margin-bottom: 15px;
  margin-top: -5px;
  font-size: 20px;
  font-weight: bold;
}

.vs-post-comment .commented-on {
  font-size: 14px;
  display: block;
  font-weight: 400;
  margin-bottom: 10px;
}

.vs-post-comment .reply_and_edit {
  margin-top: 20px;
}

.vs-post-comment .star-rating {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

.vs-post-comment .star-rating {
  width: 92px;
}

.vs-post-comment .reply-btn {
  color: var(--white-color);
  background-color: var(--theme-color);
  min-width: 110px;
  /* font-family: var(--body-font); */
  font-family: 'Mont-Bold', sans-serif;
  text-transform: uppercase;
  padding: 0 20px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  border-radius: 999px;
  font-size: 14px;
}

.vs-post-comment .reply-btn:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 500;
  margin-right: 5px;
}

.vs-post-comment .reply-btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.vs-post-comment p {
  line-height: 1.625;
}

.vs-post-comment .text {
  margin-bottom: 0;
  line-height: 1.625;
}

.children .vs-post-comment {
  padding-left: 70px;
}

ul.comment-list .vs-comment:first-child:not(.children .vs-comment)>.vs-post-comment {
  padding-top: 30px;
}

ul.comment-list .vs-comment:last-child:not(.children .vs-comment)>.vs-post-comment {
  border-bottom: none;
}

.Rectangle_683_copy_3 {
  background-color: white;
  position: absolute;
  left: 350px;
  top: 3621px;
  width: 812px;
  height: 622px;
  z-index: 157;
}

.vs-comment-form {
  background-color: var(--white-color);
  -webkit-box-shadow: 0px 8px 40px 0px rgba(208, 208, 208, 0.3);
  box-shadow: 0px 8px 40px 0px rgba(208, 208, 208, 0.3);
  padding: 60px;
  border-radius: 15px;
  margin-top: 15px;
}

.vs-comment-form .row {
  --bs-gutter-x: 20px;
}

.vs-comment-form .form-group:last-child {
  margin-top: 10px;
}

.vs-comment-form .blog-inner-title {
  margin-bottom: 0;
}

.vs-comment-form .form-text {
  margin-bottom: 25px;
}

.form-text {
  font-size: 1em;
}

.vs-register-form {
  margin-top: 40px;
}

.vs-register-form .form-title {
  margin-bottom: 30px;
}

/* Large devices */
@media (max-width: 1199px) {

  .vs-comment-form,
  .vs-register-form {
    padding: 45px 40px;
  }
}

/* Medium devices */
@media (max-width: 991px) {

  .vs-comment-form,
  .vs-register-form {
    margin-bottom: 30px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .vs-post-comment {
    padding: 25px 0 20px 0;
  }

  .vs-post-comment .comment-avater {
    float: none;
  }

  .vs-post-comment .comment-content {
    padding-left: 0;
    margin-top: 25px;
  }

  .children .vs-post-comment {
    padding-left: 50px;
  }

  .vs-comment-form,
  .vs-register-form {
    margin-top: 20px;
    padding: 45px 20px;
  }

  .vs-comment-form .mb-40,
  .vs-register-form .mb-40 {
    margin-bottom: 20px;
  }

  .form-title .form-text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .vs-post-comment {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .vs-post-comment .comment-avater {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .vs-post-comment .comment-content {
    width: 100%;
  }
}

/*------------------- 4.8. Hero Area  -------------------*/
.vs-hero-wrapper {
  position: relative;
}

.vs-hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vs-hero-bg img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.55;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}

.hero-title {
  color: var(--white-color);
}

.hero-style1 {
  position: relative;
  z-index: 2;
  padding: 190px 0 275px 0;
  color: var(--white-color);
}

.hero-style1 .hero-title {
  font-size: 72px;
  margin-bottom: 27px;
  font-weight: 800;
  letter-spacing: -0.05em;
  line-height: 1.1;
}

.hero-style1 .hero-title:nth-of-type(1) {
  font-size: 84px;
  margin-bottom: 0px;
}

.hero-style1 .hero-subtitle {
  margin-bottom: 18px;
  display: block;
  font-size: 18px;
  font-family: var(--title-font);
  font-weight: bold;
}

.hero-style1 .event-info {
  margin-bottom: 45px;
}

.hero-style1 .event-info span {
  font-weight: bold;
}

.hero-img {
  position: absolute;
  bottom: 38px;
}

.link {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}

.link i {
  margin-right: 4px;
}

.link:hover {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: underline;
}

.hero-slider-2 .hero-shape {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 61%;
}

.hero-slider-2 .hero-shape img {
  height: 100%;
  width: 100%;
}

.date-box {
  border: 2px solid var(--white-color);
  display: inline-block;
  text-align: center;
  margin-bottom: 28px;
  border-radius: 15px;
  overflow: hidden;
}

.date-box .date {
  color: var(--white-color);
  padding: 10px 42px;
  margin-bottom: 0;
  display: block;
}

.date-box .month {
  font-weight: bold;
  background-color: var(--white-color);
  color: var(--theme-color);
  display: block;
  padding: 10px 10px 8px 10px;
}

.hero-content-style2 {
  padding: 180px 0;
}

.hero-content-style2 .event-info {
  margin-bottom: 44px;
}

.hero-content-style2 .event-info span {
  font-size: 18px;
  font-weight: 500;
  color: var(--white-color);
}

.hero-content-style2 .hero-title {
  font-size: 80px;
  color: var(--white-color);
  line-height: 1;
  margin-bottom: 34px;
  letter-spacing: -0.02em;
}

.hero-content-style2 .hero-title:nth-of-type(1) {
  font-size: 60px;
  letter-spacing: -0.04em;
  margin-bottom: 20px;
}

.hero-content-style2 .btn-group {
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}

.hero-content-style3 {
  text-align: center;
  color: var(--white-color);
  padding: 197px 0 202px 0;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.hero-content-style3 .event-info {
  margin-bottom: 45px;
}

.hero-content-style3 .event-info span {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
}

.hero-content-style3 .hero-title {
  margin-bottom: 27px;
  font-size: 80px;
  color: var(--white-color);
  line-height: 1.2;
}

.hero-content-style3 .hero-date {
  color: var(--white-color);
  font-weight: 500;
  margin-bottom: 20px;
}

/* .vs-hero-wrapper .slick-arrow {
  --icon-size: 80px;
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: var(--icon-size);
  position: absolute;
  top: 50%;
  left: 14.5%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 24px;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--white-color);
  border-radius: 50%;
  border: none;
}

.vs-hero-wrapper .slick-arrow.slick-next {
  left: unset;
  right: 14.5%;
}

.vs-hero-wrapper .slick-arrow:hover {
  background-color: rgba(0, 0, 0, 0.2);
} */

.ripple-shape {
  position: absolute;
  top: 0;
  left: 0;
}

.ripple-1,
.ripple-2,
.ripple-3,
.ripple-4,
.ripple-5 {
  height: 1px;
  width: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 50%;
  -webkit-transform: translate3d(-50%, 50%, 0);
  transform: translate3d(-50%, 50%, 0);
  -webkit-animation-name: heroripple;
  animation-name: heroripple;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  will-change: transform, opacity;
}

.ripple-1 {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

.ripple-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.ripple-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.ripple-4 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.ripple-4 {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.ripple-5 {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

@-webkit-keyframes heroripple {
  0% {
    -webkit-transform: translate3d(-50%, 50%, 0) scale(0);
    transform: translate3d(-50%, 50%, 0) scale(0);
    opacity: 0.05;
  }

  100% {
    -webkit-transform: translate3d(-50%, 50%, 0) scale(1000);
    transform: translate3d(-50%, 50%, 0) scale(1000);
    opacity: 0;
  }
}

@keyframes heroripple {
  0% {
    -webkit-transform: translate3d(-50%, 50%, 0) scale(0);
    transform: translate3d(-50%, 50%, 0) scale(0);
    opacity: 0.05;
  }

  100% {
    -webkit-transform: translate3d(-50%, 50%, 0) scale(1000);
    transform: translate3d(-50%, 50%, 0) scale(1000);
    opacity: 0;
  }
}

.hero-content-style4 {
  position: relative;
  z-index: 5;
  padding: 200px 15px 270px 15px;
  text-align: center;
}

.hero-content-style4 .sub-title2 {
  color: var(--white-color);
  margin-bottom: 25px;
}

.hero-content-style4 .title-shape {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.hero-content-style4 .hero-title {
  font-size: 96px;
  line-height: 1;
  font-weight: 800;
  margin-bottom: 20px;
  letter-spacing: -0.04em;
  text-transform: capitalize;
}

.hero-content-style4 .hero-title:nth-of-type(2) {
  font-size: 72px;
  margin-bottom: 38px;
}

.hero-content-style4 .event-info {
  margin-bottom: 45px;
}

.hero-content-style4 .event-info span {
  font-size: 20px;
  color: var(--white-color);
  font-weight: 500;
  margin-bottom: 10px;
}

.hero-content-style4 .event-info span i {
  font-size: 8px;
  top: -3px;
  position: relative;
}

.hero-content-style4 .btn-group {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.hero-triangle {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

/* .hero-slider-4 .slick-arrow {
  background-color: rgba(255, 255, 255, 0.22);
  left: 8%;
}

.hero-slider-4 .slick-arrow:hover {
  background-color: var(--white-color);
  color: var(--theme-color);
}

.hero-slider-4 .slick-arrow.slick-next {
  left: unset;
  right: 8%;
} */

/* Extra large devices */
@media (max-width: 1680px) {
  .hero-img {
    bottom: -4px;
  }
}

/* Extra large devices */
@media (max-width: 1500px) {
  .hero-content-style3 .hero-title {
    font-size: 62px;
  }

  .hero-style1 {
    padding: 140px 0 225px 0;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .hero-style1 .hero-title {
    font-size: 60px;
    line-height: 1.2;
  }

  .hero-style1 .hero-title:nth-of-type(1) {
    font-size: 72px;
  }

  /* .vs-hero-wrapper .slick-arrow {
    left: 8%;
  }

  .vs-hero-wrapper .slick-arrow.slick-next {
    left: unset;
    right: 8%;
  } */

  .hero-content-style3 {
    padding: 137px 0 132px 0;
  }

  .shape-mockup.circle-shape {
    max-width: 220px;
    right: -110px !important;
  }

  .hero-content-style4 .hero-title {
    font-size: 80px;
  }

  .hero-content-style4 .hero-title:nth-of-type(2) {
    font-size: 64px;
  }

  .hero-content-style2 {
    padding: 120px 0;
  }

  .hero-content-style2 .hero-title {
    font-size: 72px;
  }

  .hero-content-style2 .hero-title:nth-of-type(1) {
    font-size: 54px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .hero-shape1 {
    top: 2% !important;
    max-width: 70px;
  }

  .hero-shape2 {
    bottom: 3% !important;
    left: 1% !important;
  }

  .hero-shape4 {
    top: 3% !important;
    right: 1% !important;
  }

  .hero-style1 {
    padding: 120px 0 180px 0;
  }

  .hero-style1 .hero-title {
    font-size: 54px;
  }

  .hero-style1 .hero-title:nth-of-type(1) {
    font-size: 64px;
  }

  .hero-content-style3 .hero-title {
    font-size: 50px;
  }

  .hero-slider-2 .hero-shape {
    width: auto;
  }

  .hero-content-style2 .hero-title {
    font-size: 64px;
  }

  .hero-content-style2 .hero-title:nth-of-type(1) {
    font-size: 48px;
  }

  .hero-content-style3 {
    padding: 97px 0 92px 0;
  }

  .hero-content-style4 {
    padding: 160px 15px 230px 15px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .hero-style1 {
    padding: 100px 0 150px 0;
  }

  .hero-style1 .hero-title {
    font-size: 46px;
  }

  .hero-style1 .hero-title:nth-of-type(1) {
    font-size: 54px;
  }

  .hero-style1 .event-info span {
    display: block;
    margin-bottom: 15px;
  }

  .hero-style1 .event-info span:last-of-type {
    margin-bottom: 0;
  }

  .hero-img {
    position: absolute;
    bottom: 10px;
    right: 0;
    max-width: 380px;
  }

  .hero-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .hero-slider-2 .vs-hero-bg {
    background-position: right center;
  }

  .hero-slider-2 .hero-shape {
    opacity: 0.7;
  }

  .hero-content-style2 {
    padding: 90px 0;
  }

  .hero-content-style2 .hero-title {
    font-size: 52px;
    margin-bottom: 24px;
  }

  .hero-content-style2 .hero-title:nth-of-type(1) {
    font-size: 40px;
    margin-bottom: 15px;
  }

  .hero-content-style2 .event-info {
    margin-bottom: 34px;
  }

  .hero-content-style4 {
    padding: 100px 15px 170px 15px;
  }

  .hero-content-style4 .hero-title {
    font-size: 64px;
  }

  .hero-content-style4 .hero-title:nth-of-type(2) {
    font-size: 48px;
  }

  .hero-content-style4 .event-info span {
    font-size: 18px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .hero-style1 .hero-title {
    font-size: 34px;
  }

  .hero-style1 .hero-title:nth-of-type(1) {
    font-size: 40px;
  }

  .hero-style1 .hero-subtitle {
    font-size: 14px;
  }

  .hero-img {
    max-width: 280px;
  }

  .hero-content-style3 .hero-date {
    font-size: 18px;
  }

  .hero-content-style4 .hero-title {
    font-size: 48px;
  }

  .hero-content-style4 .hero-title:nth-of-type(2) {
    font-size: 40px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .hero-img {
    display: none;
  }

  .hero-style1 {
    text-align: center;
  }

  .hero-style1 .btn-group {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .hero-style1 .event-info span {
    margin-right: 0;
  }

  .hero-content-style2 {
    text-align: center;
  }

  .hero-content-style2 .btn-group {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .hero-content-style2 .event-info span {
    font-size: 16px;
  }

  .hero-content-style2 .hero-title {
    font-size: 42px;
  }

  .hero-content-style2 .hero-title:nth-of-type(1) {
    font-size: 34px;
  }

  .hero-content-style3 .event-info {
    margin-bottom: 30px;
  }

  .hero-content-style3 .event-info span {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .hero-content-style3 .hero-title {
    font-size: 34px;
    line-height: 1.4;
  }

  .hero-content-style4 .hero-title {
    font-size: 40px;
  }

  .hero-content-style4 .hero-title:nth-of-type(2) {
    font-size: 32px;
  }

  .hero-content-style4 .event-info span {
    font-size: 16px;
  }
}

/* Extra small devices */
@media (max-width: 416px) {
  .hero-content-style4 .event-info span {
    margin-right: 0;
  }

  .hero-style1 .hero-title {
    font-size: 30px;
  }

  .hero-style1 .hero-title:nth-of-type(1) {
    font-size: 34px;
  }
}

/*------------------- 4.9. Error  -------------------*/
.success-title {
  font-size: 80px;
  line-height: 1;
  margin-top: -0.13em;
}
.error-title {
  font-size: 80px;
  line-height: 1;
  margin-top: -0.13em;
  background: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.font-bold-7 {
    font-weight: 700;
}
/* Small devices */
@media (max-width: 767px) {
  .error-title {
    font-size: 90px;
  }
}

/*------------------- 4.00. Popup Search  -------------------*/
.popup-search-box {
  position: fixed;
  top: 0;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.95);
  height: 0;
  width: 0;
  overflow: hidden;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.popup-search-box button.searchClose {
  width: 60px;
  height: 60px;
  line-height: 60px;
  position: absolute;
  top: 40px;
  right: 40px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--theme-color);
  background-color: transparent;
  font-size: 22px;
  border-radius: 50%;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.popup-search-box button.searchClose:hover {
  color: var(--body-color);
  background-color: #fff;
  border-color: transparent;
  border-color: transparent;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.popup-search-box form {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  padding-bottom: 40px;
  cursor: auto;
  width: 100%;
  max-width: 700px;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  -webkit-transition: -webkit-transform ease 0.4s;
  transition: -webkit-transform ease 0.4s;
  transition: transform ease 0.4s;
  transition: transform ease 0.4s, -webkit-transform ease 0.4s;
  /* Large devices */
}

@media (max-width: 1199px) {
  .popup-search-box form {
    max-width: 600px;
  }
}

.popup-search-box form input {
  font-size: 18px;
  height: 70px;
  width: 100%;
  border-width: 2px;
  border-style: solid;
  border-color: var(--theme-color);
  background-color: transparent;
  padding-left: 30px;
  color: #fff;
  border-radius: 50px;
}

.popup-search-box form input::-moz-placeholder {
  color: #fff;
}

.popup-search-box form input::-webkit-input-placeholder {
  color: #fff;
}

.popup-search-box form input:-ms-input-placeholder {
  color: #fff;
}

.popup-search-box form input::-ms-input-placeholder {
  color: #fff;
}

.popup-search-box form input::placeholder {
  color: #fff;
}

.popup-search-box form button {
  position: absolute;
  top: 0px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  right: 12px;
  color: var(--white-color);
  cursor: pointer;
  width: 70px;
  height: 70px;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  -webkit-transform: scale(1.001);
  transform: scale(1.001);
}

.popup-search-box form button:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.popup-search-box.show {
  opacity: 1;
  visibility: visible;
  width: 100.1%;
  height: 100%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  border-radius: 0;
}

.popup-search-box.show form {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

/*------------------- 4.00. Popup Side Menu  -------------------*/
.sidemenu-wrapper {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
}

.sidemenu-wrapper .closeButton {
  display: inline-block;
  border: 2px solid;
  width: 50px;
  height: 50px;
  line-height: 1;
  font-size: 24px;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--white-color);
  border-radius: 50%;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.sidemenu-wrapper .closeButton:hover {
  color: var(--theme-color);
  border-color: var(--theme-color);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sidemenu-wrapper .sidemenu-content {
  background-color: var(--white-color);
  width: 400px;
  margin-left: auto;
  padding: 80px 50px;
  height: 100%;
  overflow: scroll;
  position: relative;
  right: -500px;
  cursor: auto;
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  -webkit-transition: right ease 1s;
  transition: right ease 1s;
}

.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
}

.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar {
  width: 2px;
  background-color: #F5F5F5;
}

.sidemenu-wrapper .widget {
  padding: 0;
  border: none;
  background-color: transparent;
}

.sidemenu-wrapper.show {
  opacity: 1;
  visibility: visible;
  width: 100%;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
}

.sidemenu-wrapper.show .sidemenu-content {
  right: 0;
  opacity: 1;
  visibility: visible;
}

/*------------------- 4.00. Wocommerce  -------------------*/
.woocommerce-message,
.woocommerce-info {
  position: relative;
  border: none;
  padding: 11px 20px;
  padding-left: 45px;
  background-color: var(--body-color);
  color: var(--white-color);
  font-size: 14px;
  line-height: 1.6;
  font-weight: 700;
  margin-bottom: 30px;
  border-radius: 0;
}

.woocommerce-message a,
.woocommerce-info a {
  color: inherit;
  text-decoration: underline;
}

.woocommerce-message a:hover,
.woocommerce-info a:hover {
  text-decoration: underline;
}

.woocommerce-message:before,
.woocommerce-info:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 900;
  margin-right: 10px;
  position: absolute;
  left: 20px;
}

.woocommerce-notices-wrapper .woocommerce-message {
  background-color: var(--theme-color);
}

.woocommerce-notices-wrapper .woocommerce-message:before {
  content: "";
  font-weight: 300;
}

.woocommerce-form-login-toggle .woocommerce-info {
  background-color: var(--theme-color);
}

.woocommerce-error {
  background-color: var(--error-color);
  color: #fff;
  list-style: none;
  padding: 10px 26px;
  margin: 0 0 30px 0;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
}

nav.woocommerce-MyAccount-navigation li {
  border: 1px solid #ddd;
  margin: 0;
  border-top: none;
}

nav.woocommerce-MyAccount-navigation li:first-child {
  border-top: 1px solid #ddd;
}

nav.woocommerce-MyAccount-navigation li a {
  color: var(--title-color);
  font-weight: 700;
  padding: 7px 17px;
  display: block;
}

nav.woocommerce-MyAccount-navigation li.is-active a,
nav.woocommerce-MyAccount-navigation li a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
}

.woocommerce-MyAccount-content h3 {
  margin-top: -0.3em;
}

.woocommerce-MyAccount-content .btn {
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 14px;
  padding: 10px 25px;
  font-weight: 700;
}

.woocommerce-MyAccount-content .btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

table.variations,
.woocommerce-grouped-product-list-item {
  border-collapse: separate;
  border-spacing: 0 15px;
  margin-bottom: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

table.variations td,
.woocommerce-grouped-product-list-item td {
  border: none;
  vertical-align: middle;
  padding: 0 5px;
}

table.variations td:first-child,
.woocommerce-grouped-product-list-item td:first-child {
  padding: 0;
}

table.variations label,
.woocommerce-grouped-product-list-item label {
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
}

table.variations label a,
.woocommerce-grouped-product-list-item label a {
  color: var(--title-color);
}

table.variations label a:hover,
.woocommerce-grouped-product-list-item label a:hover {
  color: var(--theme-color);
}

.woocommerce-product-attributes th,
.woocommerce-product-attributes td {
  border: 1px solid var(--border-color);
}

.woocommerce-product-attributes th p:last-child,
.woocommerce-product-attributes td p:last-child {
  margin-bottom: 0;
}

.woocommerce-grouped-product-list.group_table {
  border-collapse: collapse;
  margin-bottom: 15px;
}

.woocommerce-grouped-product-list.group_table .woocommerce-Price-amount.amount {
  font-size: 16px;
  color: var(--title-color);
}

.woocommerce-grouped-product-list.group_table label {
  margin: 0 0 0 10px;
  margin: 0 0 0 10px;
  font-family: var(--title-font);
  font-size: 18px;
}

.woocommerce-grouped-product-list.group_table .qty-input {
  border-color: #e3e6e9;
}

.woocommerce-grouped-product-list.group_table tr {
  border-bottom: 1px solid #e3e6e9;
}

.woocommerce-grouped-product-list.group_table tr:last-child {
  border-bottom: none;
}

.woocommerce-grouped-product-list.group_table td {
  padding: 30px 5px;
}

table.variations {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
}

table.variations td {
  padding: 0;
}

table.variations td.label {
  padding-right: 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

table.variations select {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: middle;
  margin: 0;
  padding-right: 54px;
  padding-left: 20px;
  height: 50px;
}

table.variations .reset_variations {
  margin-left: 16px;
  display: inline-block;
  position: absolute;
  left: 100%;
  bottom: 25px;
}

.woocommerce-variation.single_variation {
  margin-bottom: 30px;
}

.woocommerce-variation.single_variation .price {
  color: var(--title-color);
  font-weight: 700;
}

.wooscp-table-items td.woocommerce-product-attributes-item__value {
  padding-left: 15px !important;
}

.wooscp-table-items a.added_to_cart.wc-forward {
  margin-left: 15px;
  text-decoration: underline;
}

#woosq-popup .product_meta {
  margin-top: 20px;
}

#woosq-popup .product_title {
  font-size: 24px;
  margin-bottom: 5px;
}

#woosq-popup .single-product .product .actions {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}

#woosq-popup .single-product .product .actions .vs-btn {
  padding: 17px 30px;
}

#woosq-popup .single-product .product .actions>div {
  height: auto;
  overflow: visible;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

#woosq-popup .single-product .product .actions>div .quantity.style2.woocommerce-grouped-product-list-item__quantity {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.login-tab {
  margin-bottom: 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.login-tab button.nav-link {
  background-color: var(--smoke-color);
  color: var(--title-color);
  padding: 11px 39px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 15px ​15px 0;
}

.login-tab button.nav-link.active {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.star-rating {
  overflow: hidden;
  position: relative;
  width: 100px;
  height: 1.2em;
  line-height: 1.2em;
  display: block;
  font-family: var(--icon-font);
  font-weight: 700;
  font-size: 14px;
}

.star-rating:before {
  content: "";
  color: #dab1b1;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  letter-spacing: 3px;
}

.star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
}

.star-rating span:before {
  content: "";
  top: 0;
  position: absolute;
  left: 0;
  color: var(--yellow-color);
  letter-spacing: 3px;
}

.rating-select label {
  margin: 0;
  margin-right: 10px;
}

.rating-select p.stars {
  margin-bottom: 0;
  line-height: 1;
}

.rating-select p.stars a {
  position: relative;
  height: 14px;
  width: 18px;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none;
}

.rating-select p.stars a::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 14px;
  line-height: 1;
  font-family: var(--icon-font);
  content: "";
  font-weight: 400;
  text-indent: 0;
  color: var(--yellow-color);
}

.rating-select p.stars a:hover~a::before {
  content: "";
  font-weight: 400;
}

.rating-select p.stars:hover a::before {
  content: "";
  font-weight: 700;
}

.rating-select p.stars.selected a.active::before {
  content: "";
  font-weight: 700;
}

.rating-select p.stars.selected a.active~a::before {
  content: "";
  font-weight: 400;
}

.rating-select p.stars.selected a:not(.active)::before {
  content: "";
  font-weight: 700;
}

/* Small devices */
@media (max-width: 767px) {

  .woocommerce-message,
  .woocommerce-info {
    font-size: 14px;
    padding: 10px;
    padding-left: 35px;
  }

  .woocommerce-message:before,
  .woocommerce-info:before {
    left: 10px;
  }
}

/*------------------- 4.00. Products  -------------------*/
.vs-product-box {
  text-align: center;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  border-radius: 15px;
}

.vs-product-box .rating-wrap {
  display: inline-block;
  margin-bottom: 8px;
}

.vs-product-box .rating-wrap:empty {
  display: none;
}

.vs-product-box .star-rating {
  margin-right: -0.7em;
}

.vs-product-box .product-category {
  color: var(--body-color);
  margin-bottom: 8px;
  display: inline-block;
}

.vs-product-box .product-category:hover {
  color: var(--theme-color);
}
.space .product-title{
  cursor: pointer;
}
.vs-product-box .product-title {
  margin-bottom: 6px;
  font-size: 22px;
}

.vs-product-box .product-title a {
  color: inherit;
}

.vs-product-box .product-title a:hover {
  color: var(--theme-color);
}

.vs-product-box .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.vs-product-box .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
}

.vs-product-box .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
.vs-product-box .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before {
  position: relative;
  top: 0;
  left: 0;
  line-height: inherit;
  margin: 0;
  font-size: 24px;
}

.vs-product-box .tinv-wishlist a {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: var(--white-color);
  color: var(--title-color);
  border-radius: 50%;
}

.vs-product-box .tinv-wishlist a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.vs-product-box .product-content {
  padding: 29px 20px 5px 20px;
  border-top: none;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.vs-product-box .product-img {
  background-color: #f3f6f7;
  position: relative;
  overflow: hidden;
  z-index: 2;
  border-radius: 15px;
}

.vs-product-box .product-img img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.speaker-details img{
  aspect-ratio: 3/2;
  object-fit: contain;
  object-position: center;
}
.vs-product-box .product-img:before {
  content: "";
  width: 100%;
  height: 100%;
  /* background-color: var(--title-color); */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: inherit;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.vs-product-box .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%) scale(0.7);
  transform: translateY(-50%) scale(0.7);
  z-index: 2;
}

.vs-product-box .add_to_cart_button.added {
  display: none;
}

.vs-product-box .added_to_cart {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: var(--white-color);
  color: var(--title-color);
  font-size: 0;
  text-align: center;
  border-radius: 50%;
}

.vs-product-box .added_to_cart:after {
  content: "";
  position: relative;
  font-family: var(--icon-font);
  font-size: 16px;
  font-weight: 700;
}

.vs-product-box .added_to_cart:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.vs-product-box:hover .product-content {
  border-color: transparent;
  background-color: var(--white-color);
}

.vs-product-box:hover .product-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.vs-product-box:hover .product-img:before {
  visibility: visible;
  opacity: 0.7;
}

.vs-product-box:hover .actions {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}

.vs-product-box .product-tag {
  width: 56px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 15px;
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 2;
}

.vs-product-box.product-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid var(--smoke-two);
}

.vs-product-box.product-list .product-title {
  font-size: 18px;
}

.vs-product-box.product-list .product-content {
  text-align: left;
  padding: 15px 15px 15px 25px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.vs-product-box.product-list .product-img {
  max-width: 180px;
  border-radius: 15px 0 0 15px;
}

.vs-product-box.product-list .product-tag {
  top: 12px;
  right: 12px;
}

.vs-product-box.product-list .star-rating {
  font-size: 12px;
  width: 86px;
}

.vs-product-box.product-list .actions {
  gap: 5px;
}

.vs-product-box.product-list .icon-btn {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 13px;
}

.vs-product-box.product-list .icon-btn i {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
}

.vs-product-box.product-list .added_to_cart,
.vs-product-box.product-list .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.vs-product-box.product-list .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.vs-product-box.product-list .added_to_cart:before {
  font-size: 14px;
}

.vs-product-box.product-list .added_to_cart:after {
  font-size: 13px;
}

.vs-product-box.product-list .tinv-wraper.tinv-wishlist {
  line-height: 1;
}

.price,
.amount {
  color: var(--theme-color);
  font-weight: bold;
  font-size: 16px;
  /* font-family: var(--body-font); */
  font-family: 'Mont-Bold', sans-serif;
}

.price del,
.amount del {
  color: var(--body-color);
  margin-left: 12px;
}

del {
  color: var(--body-color);
}

del .price,
del .amount {
  color: var(--body-color);
}

.vs-sort-bar {
  background-color: var(--smoke-color);
  padding: 15px 25px;
  border-radius: 15px;
}

.vs-sort-bar>.row {
  --bs-gutter-y: 15px;
}

.vs-sort-bar .icon-btn {
  margin-right: 10px;
  width: 45px;
  height: 45px;
  line-height: 47px;
}

.vs-sort-bar .icon-btn:last-child {
  margin-right: 0;
}

.vs-sort-bar select {
  height: 45px;
  border: 1px solid var(--border-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: auto;
  color: var(--body-color);
  padding-right: 46px;
  padding-left: 20px;
  font-size: 16px;
  margin: 0;
  border-radius: 30px;
}

.vs-sort-bar label {
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 15px;
  font-weight: 500;
  line-height: 1;
  color: var(--title-color);
}

.vs-sort-bar p.woocommerce-result-count {
  margin-bottom: 0;
  color: var(--title-color);
}

.product-search .widget {
  padding: 0;
  margin: 0;
}

/* .product-big-img .slick-arrow {
  position: absolute;
  left: -60px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  width: auto;
  height: auto;
  padding: 0;
  line-height: 1;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  margin: 0;
}

.product-big-img .slick-arrow.slick-next {
  left: auto;
  right: -60px;
}

.product-big-img .slick-dots {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 25px;
  margin-bottom: 0;
}

.product-big-img .slick-arrow {
  left: 30px;
}

.product-big-img .slick-arrow.slick-next {
  left: auto;
  right: 30px;
}

.container .row:hover .product-big-img .slick-arrow {
  opacity: 1;
  visibility: visible;
} */

.product-thumb {
  cursor: pointer;
}

.quantity {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 0;
  text-align: center;
  width: 150px;
  color: var(--title-color);
  border-radius: 999px;
}

.quantity input {
  width: 50px;
  height: 48px;
  text-align: center;
  border: 1px solid #eeeeee;
  border-top: none;
  border-bottom: none;
  color: var(--title-color);
  font-weight: bold;
  font-size: 16px;
  padding-left: 0;
  /* Firefox */
}

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity input[type=number] {
  -moz-appearance: textfield;
}

.quantity .qut-btn {
  border: none;
  background-color: transparent;
  padding: 0;
  line-height: 1;
  color: var(--title-color);
  font-size: 14px;
  text-align: center;
  width: 50px;
  font-weight: bold;
}

.quantity .qut-btn:hover {
  color: var(--theme-color);
}

.mfp-content {
  margin: 1.5rem auto;
}

.mfp-content .product-details-img {
  padding-top: 15px;
}

.mfp-content .product-about {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-content .container {
  position: relative;
  border-radius: 15px;
}

.mfp-content .product-big-img {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.product-inner-list>ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.product-inner-list li {
  position: relative;
  padding-left: 15px;
}

.product-inner-list li:before {
  content: "-";
  position: absolute;
  left: 0;
}

.product-about>.price {
  margin-top: -0.2em;
  font-weight: 600;
  color: var(--theme-color);
  display: block;
  margin-bottom: 25px;
}

.product-about>.price del {
  color: var(--body-color);
  font-size: 0.8em;
}

.product-about .actions-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.product-about .actions-btn .vs-btn {
  font-size: 16px;
  padding: 8px 28px;
  height: 50px;
}

.product-about .vs-comments-wrap {
  margin-top: 0;
}

.product-about .border-title {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.product-about .border-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 80px;
  background-color: var(--theme-color);
}

/* Large devices */
@media (max-width: 1199px) {
  .vs-product-box .product-title {
    font-size: 20px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .vs-product-box.product-list .product-img {
    max-width: 150px;
  }

  .vs-product-box.product-list .product-title {
    font-size: 16px;
  }

  .vs-product-box.product-list .price,
  .vs-product-box.product-list .amount {
    font-size: 14px;
  }

  .vs-product-box.product-list .product-category {
    font-size: 14px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .woocommerce-result-count {
    text-align: center;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .vs-sort-bar select {
    margin-left: auto;
    margin: auto;
  }

  .vs-product-box.product-list .product-img {
    width: 130px;
    max-width: 130px;
  }

  .vs-product-box.product-list .product-content .product-title {
    font-size: 16px;
  }
}

/* Extra small devices */
@media (max-width: 416px) {
  .vs-product-box .actions .vs-btn {
    height: 38px;
    padding: 8px 15px;
  }

  .vs-product-box.product-list .product-content {
    padding: 15px 20px 15px 15px;
  }
}

/*------------------- 4.00. Products  -------------------*/
.product-big-img {
  --space: 50px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}

.product-big-img .product-thumb-area {
  width: calc(100% - var(--space) * 2);
  position: absolute;
  bottom: var(--space);
  left: var(--space);
}

.product-thumb img {
  border-radius: 15px;
}

.product-about .woocommerce-product-rating {
  display: inline-block;
  margin-bottom: 8px;
}

.product-about .star-rating {
  display: inline-block;
  vertical-align: middle;
}

.product-about .woocommerce-review-link {
  color: var(--body-color);
}

.product-about .woocommerce-review-link:hover {
  color: var(--theme-color);
}

.product-about .product-title {
  font-size: 40px;
}

.product-about .price {
  font-size: 24px;
}

.product-about .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 32px;
  margin-bottom: 35px;
}

.product-about .actions .icon-btn {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%);
}

.product-about .actions .icon-btn:hover {
  background-image: none;
}

.woocommerce-table td,
.woocommerce-table th {
  padding: 10px;
}

.product_meta {
  text-transform: capitalize;
}

.product_meta>span {
  display: block;
  margin-bottom: 5px;
  color: var(--title-color);
  font-weight: bold;
}

.product_meta>span:last-child {
  margin-bottom: 0;
}

.product_meta>span a {
  color: inherit;
}

.product_meta>span a:hover {
  color: var(--theme-color);
}

.product_meta>span>a,
.product_meta>span>span {
  position: relative;
  color: var(--body-color);
  display: inline-block;
  font-weight: 400;
}

.product_meta>span>a:after,
.product_meta>span>span:after {
  content: ",";
  margin-right: 5px;
}

.product_meta>span>a:last-child:after,
.product_meta>span>span:last-child:after {
  display: none;
}

.product_meta>span>a:first-child,
.product_meta>span>span:first-child {
  margin-left: 7px;
}

.actions.d-flex {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 12px;
}

.product-tab-style1 {
  border-bottom: 1px solid #ececec;
  position: relative;
}

.product-tab-style1 a {
  color: var(--title-color);
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  display: inline-block;
  position: relative;
  line-height: 1;
  padding: 0 0 13px 0;
  margin-right: 45px;
  font-family: var(--title-font);
  position: relative;
}

.product-tab-style1 a.active {
  color: var(--theme-color);
}

.product-tab-style1 .indicator {
  position: absolute;
  left: 0;
  top: auto !important;
  height: 0 !important;
  bottom: -1px;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  border-bottom: 2px solid var(--theme-color);
}

.product-desc-area p {
  margin-bottom: 25px;
}

.product-desc-area ul,
.product-desc-area .check-list {
  margin-bottom: 25px;
}

#description>p:first-child {
  margin-top: -7px;
}

#description,
#additional {
  margin-bottom: -30px;
}

/* Large devices */
@media (max-width: 1199px) {
  .product-about .product-title {
    font-size: 36px;
  }

  .product-about .price {
    font-size: 22px;
  }

  .product-big-img {
    --space: 20px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .product-about {
    margin-top: 0;
  }

  .product-about .product-title {
    font-size: 30px;
  }

  .product-tab-style1 a {
    font-size: 18px;
    margin-right: 30px;
  }

  .product-big-img {
    --space: 50px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .product-about .product-title {
    font-size: 26px;
    margin-bottom: 7px;
  }

  .product-about .price {
    font-size: 20px;
  }

  .product-about .actions .quantity {
    margin-right: 0;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .product-tab-style1 .indicator {
    display: none;
  }

  .product-big-img {
    --space: 20px;
  }

  .product-big-img .product-thumb-area {
    bottom: 15px;
  }
}

/*------------------- 4.00. Cart  -------------------*/
.woocommerce-cart-form {
  text-align: center;
}

.cart_table {
  border: 1px solid #eaf0f2;
  margin-bottom: 45px;
}

.cart_table thead {
  background-color: var(--smoke-color);
}

.cart_table thead th {
  border: none !important;
}

.cart_table td:before,
.cart_table th {
  /* font-family: var(--title-font); */
  font-family: 'Mont-Bold', sans-serif;
  color: var(--title-color);
  font-weight: 600;
  border: none;
  padding: 27px 15px;
}

.cart_table td:before {
  content: attr(data-title);
  position: absolute;
  left: 15px;
  top: 50%;
  vertical-align: top;
  padding: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
}

.cart_table td {
  border: none;
  border-bottom: 1px solid #f3f3f3;
  color: #8b8b8b;
  padding: 20px 10px;
  position: relative;
  vertical-align: middle;
}

.cart_table .product-quantity {
  color: var(--title-color);
}

.cart_table .product-quantity input {
  position: relative;
  top: -2px;
}

.cart_table .cart-productname {
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--body-color);
}

.cart_table .cart-productimage {
  display: inline-block;
  border: 1px solid var(--border-color);
}

.cart_table .remove {
  color: var(--theme-color);
  font-size: 18px;
}

.cart_table .quantity {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
}

.cart_table .qut-btn {
  border: 1px solid var(--border-color);
  background-color: transparent;
  color: #b8c6d0;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 25px;
  font-size: 16px;
  border-radius: 4px;
}

.cart_table .qut-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.cart_table .qty-input {
  vertical-align: middle;
  border: 1px solid var(--border-color);
  width: 70px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  color: #2c3e50;
  font-weight: 700;
  margin: 0 10px;
  border-radius: 4px;
  /* Firefox */
}

.cart_table .qty-input::-webkit-outer-spin-button,
.cart_table .qty-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart_table .qty-input[type=number] {
  -moz-appearance: textfield;
}

.cart_table .actions {
  text-align: right;
  vertical-align: middle;
}

.cart_table .actions>.vs-btn {
  font-size: 14px;
  padding: 18px 28px;
  margin-right: 15px;
}

.cart_table .actions>.vs-btn:last-child {
  margin-right: 0;
  padding: 16.5px 28px;
}

.cart_table .vs-cart-coupon {
  float: left;
  margin: 0;
  width: 455px;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cart_table .vs-cart-coupon input {
  height: 50px;
  width: calc(100% - 200px);
  margin-right: 10px;
}

.cart_table .vs-cart-coupon .vs-btn {
  font-size: 14px;
  padding: 18px 22px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.cart_table .actions .vs-btn {
  display: inline;
}

.cart_totals {
  border: 1px solid #ecf0f1;
}

.cart_totals th,
.cart_totals td {
  vertical-align: top;
  padding: 20px 20px;
  border: none;
  border-bottom: 1px solid #ecf0f1;
  font-size: 14px;
  color: var(--title-color);
  width: 55%;
}

.cart_totals th:first-child,
.cart_totals td:first-child {
  width: 45%;
  background-color: var(--smoke-color);
  font-weight: 700;
  font-size: 14px;
  color: #333333;
}

.cart_totals .shipping-calculator-button {
  display: inline-block;
  border-bottom: 1px solid;
  color: var(--title-color);
  font-weight: 700;
}

.cart_totals .shipping-calculator-button:hover {
  color: var(--theme-color);
}

.cart_totals .woocommerce-shipping-destination {
  margin-bottom: 10px;
}

.cart_totals .woocommerce-shipping-methods {
  margin-bottom: 0;
}

.cart_totals .woocommerce-shipping-methods input[type=radio]~label::before {
  padding-right: 1.5px;
  padding-top: 0;
}

.cart_totals .shipping-calculator-form {
  display: none;
}

.cart_totals .shipping-calculator-form p:first-child {
  margin-top: 20px;
}

.cart_totals .shipping-calculator-form p:last-child {
  margin-bottom: 0;
}

.cart_totals .shipping-calculator-form .vs-btn {
  padding: 9px 20px;
  min-width: 120px;
}

.cart_totals .amount {
  font-weight: 700;
}

.cart_totals .order-total .amount {
  color: var(--theme-color);
}

/* Medium devices */
@media (max-width: 991px) {
  .cart_table th {
    padding: 23px 8px;
    font-size: 14px;
  }

  .cart_table .cart-productname {
    font-size: 14px;
  }

  .cart_table .vs-cart-coupon {
    width: 100%;
    margin-bottom: 20px;
  }

  .cart_table .actions {
    text-align: center;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .cart_table {
    text-align: left;
    min-width: auto;
    border-collapse: separate;
    border-spacing: 0 20px;
    border: none;
    margin-top: -20px;
  }

  .cart_table thead {
    display: none;
  }

  .cart_table td {
    padding: 15px;
    display: block;
    width: 100%;
    padding-left: 25%;
    text-align: right;
    border: 1px solid #f3f3f3;
    border-bottom: none;
  }

  .cart_table td::before {
    display: block;
  }

  .cart_table td:last-child {
    border-bottom: 1px solid #f3f3f3;
  }

  .cart_table td.actions {
    padding-left: 15px;
    text-align: center;
  }

  .cart_table td.actions>.vs-btn {
    margin-top: 10px;
    margin-right: 0;
    display: block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }

  .cart_table td.actions>.vs-btn:last-child {
    margin-right: auto;
  }

  .cart_table .vs-cart-coupon {
    width: 100%;
    text-align: center;
    float: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: block;
    padding-bottom: 10px;
  }

  .cart_table .vs-cart-coupon input {
    width: 100%;
    margin-bottom: 10px;
  }

  .cart_totals th,
  .cart_totals td {
    padding: 15px 10px;
  }

  .cart_totals th:first-child,
  .cart_totals td:first-child {
    width: 17%;
    line-height: 1.4;
  }
}

/* Extra small devices */
@media (max-width: 416px) {
  .cart_table .quantity {
    width: 150px;
  }
}

/*------------------- 4.00. Checkout  -------------------*/
.woocommerce-checkout .form-group {
  margin-bottom: var(--bs-gutter-x);
}

.woocommerce-checkout .select2-container--open .select2-dropdown--below {
  margin-top: 30px;
}

.woocommerce-checkout .select2-container--open .select2-dropdown--above {
  position: relative;
  bottom: -30px;
}

.woocommerce-checkout .select2-dropdown {
  border: 1px solid #e3e6e9;
  border-top: none;
}

.woocommerce-checkout .select2-container--default .select2-selection--single .select2-selection__rendered,
.woocommerce-checkout .form-control:focus {
  color: var(--body-color);
}

.woocommerce-form-login select,
.woocommerce-form-login .form-select,
.woocommerce-form-login .form-control,
.woocommerce-form-coupon select,
.woocommerce-form-coupon .form-select,
.woocommerce-form-coupon .form-control,
.woocommerce-checkout select,
.woocommerce-checkout .form-select,
.woocommerce-checkout .form-control {
  background-color: var(--white-color);
}

.woocommerce-form-login span,
.woocommerce-form-coupon span,
.woocommerce-checkout span {
  display: inline;
}

.select2-container--default .select2-selection--single {
  height: 60px;
  border: 1px solid #e3e6e9;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 60px;
  padding-left: 30px;
  padding-right: 25px;
}

.woocommerce-billing-fields .form-row {
  margin-bottom: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b:before {
  content: "";
  font-family: var(--icon-font);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin: 0;
  border: none;
  top: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 60px;
  line-height: 60px;
  margin-right: 18px;
}

span.select2-selection.select2-selection--single:focus {
  outline: none;
}

.shipping-calculator-form .form-select,
.shipping-calculator-form .form-control {
  height: 40px;
  padding-left: 15px;
  font-size: 16px;
  border-radius: 0;
  background-position: right 13px top 13px !important;
}

.shipping-calculator-form .vs-btn {
  font-size: 14px;
  padding: 0 20px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 40px;
}

.woocommerce-form-login {
  display: none;
  padding: 30px 30px 25px 30px;
  margin-bottom: 30px;
}

.woocommerce-form-login .form-group {
  margin-bottom: 20px;
}

.woocommerce-form-login .form-group:last-child {
  margin-bottom: 0;
}

.woocommerce-form-login label {
  margin-bottom: 0.3em;
}

.woocommerce-form-coupon {
  display: none;
  padding: 30px;
}

.woocommerce-form-coupon .form-group {
  margin-bottom: 20px;
}

.woocommerce-form-coupon .form-group:last-child {
  margin-bottom: 0;
}

.woocommerce-checkout .shipping_address {
  display: none;
}

.checkout-ordertable th,
.checkout-ordertable td {
  border: none;
  vertical-align: top;
  padding: 5px 0;
  font-size: 14px;
  line-height: 1.6;
  font-weight: 700;
  color: #2c3e50;
}

.checkout-ordertable ul {
  margin: 0;
  padding: 0;
}

.checkout-ordertable .order-total .amount {
  color: var(--theme-color);
}

.checkout-ordertable input[type=hidden]~label {
  color: var(--theme-color);
}

.woocommerce-checkout .form-group input:not(:last-child) {
  margin-bottom: var(--bs-gutter-x);
}

.checkout-ordertable th,
.checkout-ordertable td {
  border: 1px solid #ededed;
  text-align: right;
  padding: 5px 20px;
  line-height: 1.6;
}

.checkout-ordertable th {
  text-align: left;
}

.woocommerce-checkout-payment {
  text-align: left;
}

.woocommerce-checkout-payment ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.woocommerce-checkout-payment ul li {
  padding-top: 10px;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 16px;
}

.woocommerce-checkout-payment ul input[type=radio]~label {
  margin-bottom: 15px;
  color: var(--body-color);
  width: 100%;
}

.woocommerce-checkout-payment ul input[type=radio]~label img {
  margin-left: 30px;
  float: right;
  position: relative;
  top: 1px;
}

.woocommerce-checkout-payment .place-order {
  padding-top: 30px;
}

.woocommerce-checkout-payment .payment_box {
  color: #a1b1bc;
  background-color: #ecf0f1;
  border: 1px solid #d8d8d8;
  border-bottom: none;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 4px;
  display: none;
}

.woocommerce-checkout-payment .payment_box p {
  margin: 0;
}

.vs-checkout-wrapper form.woocommerce-form {
  margin-bottom: 25px;
}

/* Small devices */
@media (max-width: 767px) {
  tfoot.checkout-ordertable th {
    display: none;
  }

  .woocommerce-checkout-payment ul input[type=radio]~label img {
    max-width: 150px;
  }

  .checkout-ordertable th,
  .checkout-ordertable td {
    padding-left: 25%;
  }
}

/*------------------- 4.00. Wishlist  -------------------*/
.tinv-wishlist input[type=checkbox] {
  display: inline-block;
  opacity: 1;
  visibility: visible;
  width: auto;
  height: auto;
}

.tinv-wishlist .cart-empty {
  padding: 12px 25px;
  background-color: #eee;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
}

.tinv-wishlist p.return-to-shop .button {
  display: inline-block;
  background-color: var(--theme-color);
  color: #fff;
  font-size: 14px;
  padding: 10px 25px;
  border-radius: 4px;
  margin-top: 10px;
  font-weight: 700;
}

.tinv-wishlist p.return-to-shop .button:Hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.tinv-wishlist table th {
  color: var(--title-color);
}

.tinv-wishlist table td,
.tinv-wishlist table th {
  padding: 10px;
  border: 1px solid var(--border-color);
  text-align: center;
}

.tinv-wishlist .product-cb,
.tinv-wishlist .product-remove {
  width: 40px;
  text-align: center;
}

.tinv-wishlist .product-thumbnail {
  width: 110px;
}

.tinv-wishlist .stock.in-stock {
  margin-bottom: 0;
}

.tinv-wishlist ins {
  text-decoration: none;
}

.tinv-wishlist .product-remove button {
  border: none;
  height: 22px;
  width: 22px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}

.tinv-wishlist .tinvwl-mobile {
  display: none;
}

.tinv-wishlist .social-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 295px;
  margin-left: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tinv-wishlist .social-buttons ul {
  padding-left: 0;
  margin-bottom: 0;
  margin-left: auto;
}

.tinv-wishlist table.tinvwl-table-manage-list {
  font-size: 14px;
}

.tinv-wishlist table.tinvwl-table-manage-list button {
  border-radius: 999px;
}

.tinv-wishlist .product-stock i {
  margin-right: 5px;
}

.tinv-wishlist .tinv-modal .icon_big_times {
  margin-bottom: 5px;
  color: var(--theme-color);
}

.tinv-wishlist button.button {
  border: none;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 700;
  background-color: var(--theme-color);
  color: #fff;
  padding: 1px 15px;
}

.tinv-wishlist button.button i {
  font-size: 14px !important;
  margin-right: 3px !important;
}

.tinv-wishlist th,
.tinv-wishlist td.product-name {
  font-size: 16px;
  font-weight: 700;
  font-family: var(--title-font);
}

.tinv-wishlist td.product-name a {
  color: var(--title-color);
}

.tinv-wishlist td.product-name a:hover {
  color: var(--theme-color);
}

.tinv-wishlist td.product-price del {
  font-size: 0.9em;
}

.tinv-wishlist .social-buttons>span {
  font-weight: 700;
  margin-right: 10px;
  font-family: var(--title-font);
  color: var(--title-color);
}

.tinv-wishlist .social-buttons li {
  display: inline-block;
}

.tinv-wishlist .social-buttons li a.social {
  background-color: var(--theme-color);
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  border-radius: 50px;
  margin-left: 3px;
}

.tinv-wishlist .social-buttons li a.social:first-child {
  margin-left: 0;
}

.tinv-wishlist .social-buttons li a.social i {
  line-height: inherit;
}

.tinv-wishlist .social-buttons li a.social:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

/* Medium devices */
@media (max-width: 991px) {
  .tinvwl-full {
    display: none;
  }

  .tinv-wishlist .tinvwl-mobile {
    display: block;
  }

  .tinvwl-txt {
    display: none;
  }

  .product-stock {
    width: 40px;
    text-align: center;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .tinv-wishlist table {
    table-layout: fixed;
  }

  .tinv-wishlist table.tinvwl-table-manage-list tbody td.product-remove,
  .tinv-wishlist table.tinvwl-table-manage-list thead th:not(.product-name) {
    display: none;
  }

  .tinv-wishlist table.tinvwl-table-manage-list tbody td {
    display: block;
    width: 100% !important;
    text-align: center;
  }

  .product-name {
    text-align: center;
  }

  .tinv-wishlist table td,
  .tinv-wishlist table th {
    border-bottom: none;
  }

  .tinv-wishlist table tbody {
    border-bottom: 1px solid var(--border-color);
  }

  .tinv-wishlist .social-buttons {
    max-width: 100%;
    margin-left: unset;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .tinv-wishlist .social-buttons ul {
    margin-left: unset;
    margin-top: 5px;
  }

  .tinvwl-txt {
    display: inline-block;
  }
}

/*------------------- 4.00. simple section  -------------------*/
.border-img {
  margin-top: -190px;
  -webkit-box-shadow: 0px 30px 39.2px 9.8px rgba(201, 174, 222, 0.31);
  box-shadow: 0px 30px 39.2px 9.8px rgba(201, 174, 222, 0.31);
  background-image: linear-gradient(130deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  padding: 15px;
}

.border-top {
  border-top: 1px solid #eae8f1 !important;
}

.info-card-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.info-card {
  padding-right: 40px;
  margin-right: 40px;
  border-right: 1px solid var(--border-color);
}

.info-card:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.info-card h3 a,
.info-card a.h3 {
  color: var(--title-color);
  margin-bottom: 0;
  line-height: 1;
}

.info-card h3 a i,
.info-card a.h3 i {
  margin-right: 15px;
  line-height: 1;
}

.info-card h3 a:hover,
.info-card a.h3:hover {
  color: var(--theme-color);
}

.info-card .simple-social a:not(:hover) {
  color: var(--title-color);
}

.info-card_title {
  text-transform: uppercase;
  display: block;
  margin-bottom: 17px;
  font-size: 14px;
}

.meeting-img {
  height: 100%;
}

.meeting-img img {
  width: 100%;
  height: 100%;
}

.info-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
}

.info-grid:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.info-grid i {
  font-size: 36px;
  color: var(--theme-color);
  margin-right: 15px;
  line-height: 1.3;
  width: 36px;
}

.info-grid_title {
  font-size: 18px;
  margin-bottom: 7px;
}

.info-grid_title a {
  color: inherit;
}

.info-grid_title a:hover {
  color: var(--theme-color);
}

.info-grid_text {
  margin-bottom: 0;
}

.upcoming-ticket {
  padding-right: 35px;
}

.vs-video {
  position: relative;
}

.vs-video .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.gr-overlay {
  position: relative;
  z-index: 2;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.gr-overlay:before {
  content: "";
  height: 100%;
  width: 0;
  background-image: linear-gradient(130deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: inherit;
  overflow: hidden;
}

.gr-overlay:hover {
  color: var(--white-color);
}

.gr-overlay:hover:before {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.vs-card {
  position: relative;
}

.vs-card_content {
  -webkit-box-shadow: 0px 15px 20px 0px rgba(196, 196, 196, 0.1);
  box-shadow: 0px 15px 20px 0px rgba(196, 196, 196, 0.1);
  background-color: var(--white-color);
  border-radius: 25px;
  text-align: center;
  padding: 55px 30px;
  position: relative;
  overflow: hidden;
  z-index: 2;
  padding-bottom: 107px;
  margin-bottom: 104px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.vs-card_content:before {
  content: "";
  height: 100%;
  width: 0;
  background-color: var(--theme-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: inherit;
  overflow: hidden;
}

.vs-card_shape {
  position: absolute;
  top: -100%;
  left: 0;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}

.vs-card_icon {
  --icon-size: 142px;
  position: absolute;
  bottom: -90px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: var(--icon-size);
  background-color: var(--white-color);
  text-align: center;
  border-radius: 50%;
  border: 19px solid var(--smoke-color);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 2;
}

.vs-card_icon:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--theme-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: inherit;
  overflow: hidden;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
}

.vs-card_title {
  font-size: 24px;
}

.vs-card:hover {
  color: var(--white-color);
}

.vs-card:hover .vs-card_content:before {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.vs-card:hover .vs-card_shape {
  top: 0;
}

.vs-card:hover .vs-card_title,
.vs-card:hover .vs-card_text {
  color: var(--white-color);
}

.vs-card:hover .vs-card_icon img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.vs-card:hover .vs-card_icon:before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.content-box-1 {
  max-width: 540px;
  margin-left: 120px;
  padding-right: 15px;
  padding-top: 90px;
  padding-bottom: 90px;
  height: 100%;
  display: grid;
  -ms-flex-line-pack: center;
  align-content: center;
}

.vs-box {
  text-align: center;
  padding: 50px;
  margin-bottom: 30px;
  border-radius: 15px;
}

.vs-box>i {
  --icon-size: 120px;
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: var(--icon-size);
  font-size: 44px;
  color: var(--theme-color);
  background-color: var(--white-color);
  text-align: center;
  display: block;
  border-radius: 50%;
  margin: 0 auto 38px auto;
}

.vs-box .line {
  display: block;
  height: 1px;
  width: 80px;
  background-color: var(--white-color);
  margin: 23px auto 23px auto;
}

.vs-box-subtitle,
.vs-box_title,
.vs-box_text {
  color: var(--white-color);
}

.vs-box_text {
  font-size: 16px;
  margin-bottom: 35px;
}

.vs-box-subtitle {
  margin-bottom: 5px;
  display: block;
}

.info-list {
  max-width: 360px;
  margin-bottom: 28px;
}

.info-list:last-child {
  margin-bottom: 0;
}

.info-list a {
  color: var(--body-color);
}

.info-list a:hover {
  color: var(--theme-color);
}

.info-list strong {
  font-weight: 500;
  color: var(--title-color);
}

.info-list_text {
  font-size: 18px;
  margin-bottom: 5px;
  display: block;
  line-height: 1.6;
}

.info-list_text:last-child {
  margin-bottom: 0;
}

.info-list_title {
  margin-bottom: 10px;
  font-weight: bold;
  position: relative;
  padding-left: 25px;
  font-size: 24px;
}

.info-list_title:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--theme-color);
  position: absolute;
  top: 14px;
  left: 0;
}

.group-img {
  position: relative;
}

.group-img .icon-btn {
  --btn-size: 55px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%);
  font-size: 18px;
  font-weight: 500;
}

.group-img .img-wrap {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.group-img .img-wrap:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #101523;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-transform: scale(0.4);
  transform: scale(0.4);
}

.group-img .img-wrap:hover:before {
  visibility: visible;
  opacity: 0.5;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.group-img .img-wrap:hover .icon-btn {
  visibility: visible;
  opacity: 1;
}

.group-img .img-1 {
  left: -56px;
}

.group-img .img-2 {
  left: -85px;
}

.group-img .img-3 {
  left: 120px;
  top: -25px;
}

.group-img .img-4 {
  right: -192px;
  top: 10px;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .group-img .img-1 {
    left: 0;
    max-width: 300px;
  }

  .group-img .img-2 {
    left: -15px;
  }

  .group-img .img-3 {
    left: 100px;
    top: -55px;
  }

  .group-img .img-4 {
    right: -132px;
    top: 20px;
  }

  .info-card h3,
  .info-card .h3 {
    font-size: 28px;
  }

  .content-box-1 {
    margin-left: 60px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .upcoming-ticket {
    padding-right: 0;
  }

  .vs-box {
    padding: 37px 10px;
  }

  .vs-card_content {
    padding: 40px 30px;
    padding-bottom: 107px;
  }

  .group-img {
    margin-bottom: 50px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
  }

  .group-img .img-wrap {
    inset: 0;
    display: block;
    max-width: 100%;
  }

  .group-img .img-wrap img {
    width: 100%;
  }

  .content-box-1 {
    margin-left: 45px;
    margin-right: 45px;
    max-width: 100%;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .border-img {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .event-counter.style1 {
    max-width: 590px;
    margin-left: auto;
    margin-right: auto;
  }

  .vs-box {
    padding: 40px 10px;
  }

  p.mb-35,
  p.mb-30 {
    margin-bottom: 20px;
  }

  p.mb-40,
  p.mb-45 {
    margin-bottom: 30px;
  }

  .group-img {
    grid-template-columns: repeat(2, 1fr);
  }

  .group-img .img-wrap:not(.img-1) {
    max-width: 230px;
    height: 230px;
  }

  .group-img .img-1 {
    left: -60px;
  }

  .group-img .img-2 {
    top: 100px;
  }

  .group-img .img-3 {
    left: 100px;
  }

  .group-img .img-4 {
    left: 60px;
    top: 40px;
  }

  .content-box-1 {
    padding-top: 40px;
    margin-left: 35px;
    margin-right: 35px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .vs-box {
    padding: 60px 10px;
  }

  .vs-card_title,
  .info-list_title {
    font-size: 22px;
  }

  .group-img .img-wrap:not(.img-1) {
    max-width: 170px;
    height: 170px;
  }

  .group-img .img-3 {
    left: 120px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .content-box-1 {
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
  }

  .info-card-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
  }

  .info-card {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }

  .info-list_text {
    font-size: 16px;
  }

  .group-img .icon-btn {
    --btn-size: 40px;
  }

  .group-img .img-wrap:not(.img-1) {
    max-width: 120px;
    height: 120px;
  }

  .group-img .img-2 {
    top: 70px;
  }

  .group-img .img-3 {
    left: 60px;
  }

  .group-img .img-4 {
    left: 0;
  }

  .upcoming-ticket {
    text-align: center;
  }
}

/* Extra small devices */
@media (max-width: 416px) {
  .group-img .img-wrap:not(.img-1) {
    max-width: 100px;
    height: 100px;
  }
}

/*------------------- 4.00. Service section  -------------------*/
.vs-service-wrapper .border-top {
  border-top: 1px solid #eae8f1;
}

.service-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

.service-box_icon {
  --icon-size: 87px;
  background-color: var(--white-color);
  width: 87px;
  height: 87px;
  line-height: 85px;
  text-align: center;
  border-radius: 99999px;
  margin-right: 30px;
}

.service-box_title {
  margin-top: -0.31em;
}

.service-box_title a {
  color: inherit;
}

.service-box_title a:hover {
  color: var(--theme-color);
}

.service-box_text {
  max-width: 340px;
  margin-bottom: 0;
}

.service-card {
  text-align: center;
  padding: 0 20px;
  margin-bottom: 30px;
}

.service-card_icon {
  margin-bottom: 32px;
}

.service-card_title {
  color: var(--white-color);
  font-weight: 600;
}

.service-card_title a {
  color: inherit;
}

.service-card_title a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.service-card_text {
  color: var(--white-color);
  font-size: 16px;
  max-width: 230px;
  margin-left: auto;
  margin-right: auto;
}

.service-grid {
  background-color: var(--white-color);
  border-radius: 15px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  padding: 80px 60px;
  margin-bottom: 30px;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 2;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-grid:before {
  content: "";
  width: 0;
  height: 100%;
  background-color: var(--theme-color);
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-grid_shape {
  position: absolute;
  top: -100%;
  left: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-grid_icon {
  background-color: var(--theme-color);
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 999px;
  margin: 0 auto 23px auto;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-grid_icon img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-grid_title {
  font-size: 24px;
}

.service-grid_title a {
  color: inherit;
}

.service-grid_text {
  margin-bottom: -0.5em;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-grid.style2::before {
  background-image: linear-gradient(145deg, var(--theme-color) 0%, var(--theme-color2) 100%);
}

.service-grid.style2 .service-grid_shape img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  opacity: 0.15;
}

.service-grid.style2 .service-grid_icon {
  background-image: linear-gradient(145deg, var(--theme-color) 0%, var(--theme-color2) 100%);
}

.service-grid:hover:before {
  width: 100%;
}

.service-grid:hover .service-grid_shape {
  top: 0;
}

.service-grid:hover .service-grid_icon {
  background-color: var(--white-color);
  background-image: none;
}

.service-grid:hover .service-grid_icon img {
  -webkit-filter: none;
  filter: none;
}

.service-grid:hover .service-grid_title,
.service-grid:hover .service-grid_text {
  color: var(--white-color);
}

@media (max-width: 1299px) {
  .service-grid {
    padding: 60px 15px;
  }

  .service-grid_shape {
    width: 175px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .service-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .service-box_icon {
    margin-bottom: 25px;
    margin-right: 0;
  }

  .service-card {
    margin-bottom: 35px;
  }

  .service-card_icon {
    margin-bottom: 15px;
  }

  .service-card_title {
    margin-bottom: 10px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .service-box_title {
    margin-bottom: 8px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .service-card {
    padding: 0 0;
  }
}

/*------------------- 4.00. About section  -------------------*/
.about-img {
  border-radius: 15px;
  overflow: hidden;
}

.about-img img {
  width: 100%;
}

.about-border {
  position: absolute;
  top: 60px;
  left: 20%;
  width: 80%;
  height: 100%;
  border: 8px solid #f1eff8;
  border-radius: 15px;
  z-index: -1;
}

.info-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 33px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 40px;
}

.info-box:last-of-type {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}

.info-box_icon {
  margin-right: 30px;
}

.info-box_title {
  margin-bottom: 5px;
  margin-top: -0.2em;
  font-size: 24px;
}

.info-box_text {
  margin-bottom: 0;
}

.rounded-15 {
  border-radius: 15px;
  overflow: hidden;
}

.simple-quote {
  position: relative;
}

.simple-quote:before {
  content: "";
  height: calc(100% - 10px);
  width: 5px;
  position: absolute;
  top: 7px;
  left: 0;
  background-color: var(--theme-color);
}

.simple-quote p {
  color: var(--title-color);
  font-weight: 500;
  padding-left: 20px;
  margin-bottom: 0;
}

.info-media-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  row-gap: 40px;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  border-top: 1px solid #faf0ed;
  border-bottom: 1px solid #faf0ed;
  padding: 23px 0;
  margin-bottom: -80px;
}

.info-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  max-width: 310px;
}

.info-media_icon {
  width: 70px;
  height: 70px;
  line-height: 70px;
  background-color: rgba(240, 64, 27, 0.08);
  color: var(--theme-color);
  text-align: center;
  font-size: 32px;
  border-radius: 50%;
}

.info-media_title {
  white-space: nowrap;
  font-size: 20px;
  margin-bottom: 5px;
}

.info-media_text {
  margin-bottom: 0;
  /* white-space: nowrap; */
}

.about-img4 {
  position: relative;
}

.about-img4 img {
  border-radius: 20px;
  width: 100%;
}

.speaker-count {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%);
  padding: 56px 52px;
  position: absolute;
  text-align: center;
  left: -108px;
  bottom: -40px;
  border-radius: 20px;
}

.speaker-count .counter-number {
  font-size: 72px;
  color: var(--white-color);
  font-weight: bold;
  font-family: var(--title-font);
}

.speaker-count .text {
  font-size: 16px;
  font-weight: 500;
  color: var(--white-color);
}

/* Extra large devices */
@media (max-width: 1500px) {
  .speaker-count {
    padding: 36px 32px;
    left: -30px;
    bottom: -30px;
  }

  .speaker-count .counter-number {
    font-size: 62px;
    margin-bottom: 5px;
  }
}

@media (max-width: 1299px) {
  .about-border {
    top: -30px;
    height: calc(100% + 60px);
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .title-area.mb-30 {
    margin-bottom: 20px;
  }

  .info-media {
    max-width: 255px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .about-border {
    height: 50%;
    top: calc(50% + 20px);
    left: -10px;
    width: calc(100% + 20px);
  }

  .about-img {
    margin-bottom: 40px;
  }

  .info-media {
    max-width: 300px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .info-box_title {
    font-size: 22px;
  }

  .speaker-count {
    left: 0;
  }

  .speaker-count .counter-number {
    font-size: 42px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .info-media-wrap {
    row-gap: 20px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .about-border {
    display: none;
  }

  .info-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .info-box_icon {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .info-box .media-body {
    text-align: center;
  }
}

/*------------------- 4.00. Counter section  -------------------*/
.counter-number {
  font-size: 48px;
  line-height: 1;
  margin-bottom: 13px;
  margin-top: -0.1em;
  font-weight: bold;
  color: var(--title-color);
}

.counter-text {
  margin-bottom: 0;
}

.counter-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: var(--white-color);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.counter-box .counter-number {
  color: var(--white-color);
}

.counter-box_icon {
  margin-right: 30px;
}

.counter-box_info {
  min-width: 130px;
}

.counter-pill {
  width: 195px;
  height: 195px;
  background-color: var(--theme-color);
  text-align: center;
  display: grid;
  -ms-flex-line-pack: center;
  align-content: center;
  border-radius: 50%;
  color: var(--white-color);
  border: 15px solid #f8f8f8;
}

.counter-pill .counter-number {
  color: var(--white-color);
  margin-bottom: 4px;
}

.counter-pill_text {
  font-size: 18px;
}

.img-box-style1 .img-content,
.img-box-style1 .counter-pill {
  position: absolute;
  left: 0;
  bottom: -35px;
}

.img-box-style1 .img-content {
  left: 47%;
  bottom: 30%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.img-box-style1 .counter-pill:nth-of-type(2) {
  left: unset;
  right: 0;
  bottom: 30px;
}

.img-box-style1 .img-content {
  color: var(--white-color);
  text-align: center;
}

.img-box-style1 .img-content .counter-number {
  font-size: 60px;
  color: var(--white-color);
  margin-bottom: 4px;
}

.img-box-style1 .img-content .text {
  font-size: 24px;
  font-weight: 500;
}

.counter-list {
  padding-left: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.counter-list li {
  list-style: none;
  display: inline-block;
  min-width: 230px;
  position: relative;
}

.counter-list li .count-name {
  display: block;
}

.counter-list li .count-number {
  font-size: 60px;
  font-weight: 900;
  margin-top: -0.13em;
  margin-bottom: 7px;
  font-family: var(--title-font);
}

.counter-list li:after {
  content: ":";
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.counter-list li:last-child:after {
  display: none;
}

.counter-list.style2 {
  padding-bottom: 18px;
  border-bottom: 1px solid var(--border-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 560px;
}

.counter-list.style2 li {
  line-height: 1;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 50px;
  text-align: center;
  margin-right: 50px;
  padding-right: 50px;
  margin-bottom: 30px;
}

.counter-list.style2 li .count-name {
  color: var(--title-color);
}

.counter-list.style2 li .count-number {
  font-size: 40px;
  font-weight: bold;
  margin-top: -0.14em;
  line-height: 1;
  margin-bottom: 7px;
  color: var(--theme-color);
}

.counter-list.style2 li:after {
  color: var(--theme-color);
  font-size: 40px;
  top: -9px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.counter-list.style2 li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.counter-list.style3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.counter-list.style3 li {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: auto;
  text-align: center;
  margin: 0 !important;
  background-color: var(--theme-color);
  height: 125px;
  width: 125px;
  max-width: 125px;
  line-height: 125px;
  display: grid;
  -ms-flex-line-pack: center;
  align-content: center;
  border-radius: 99999px;
}

.counter-list.style3 li .count-name {
  color: var(--white-color);
  margin-bottom: -0.3em;
}

.counter-list.style3 li .count-number {
  font-size: 30px;
  font-weight: bold;
  margin-top: -0.14em;
  line-height: 1;
  margin-bottom: 2px;
  color: var(--white-color);
}

.counter-list.style3 li:after {
  display: none;
}

.counter-list.style4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.counter-list.style4 li {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: auto;
  text-align: center;
  margin: 0 !important;
  background-color: transparent;
  border: 4px solid rgba(255, 255, 255, 0.4);
  height: 125px;
  width: 125px;
  max-width: 125px;
  line-height: 125px;
  display: grid;
  -ms-flex-line-pack: center;
  align-content: center;
  border-radius: 99999px;
}

.counter-list.style4 li .count-name {
  color: var(--white-color);
  margin-bottom: -0.3em;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}

.counter-list.style4 li .count-number {
  font-size: 30px;
  font-weight: bold;
  margin-top: -0.14em;
  line-height: 1;
  margin-bottom: 2px;
  color: var(--white-color);
}

.counter-list.style4 li:after {
  display: none;
}

.counter-list.style5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.counter-list.style5 li {
  flex-grow: 1;
  min-width: auto;
  text-align: center;
  margin: 0 !important;
  background-color: var(--white-color);
  box-shadow: 0px 10px 30px 0px rgb(0 0 0 / 9%);
  width: 120px;
  height: 120px;
  border: 1px solid rgb(0 0 0 / 9%);
  line-height: 200px;
  max-width: 200px;
  display: grid;
  align-content: center;
  border-radius: 99999px;
}

.counter-list.style5 li .count-name {
  color: var(--title-color);
  margin-bottom: -0.4em;
}

.counter-list.style5 li .count-number {
  font-size: 48px;
  font-weight: bold;
  margin-top: -0.14em;
  line-height: 1;
  margin-bottom: 5px;
  color: var(--theme-color);
}

.counter-list.style5 li:after {
  display: none;
}

/* Large devices */
@media (max-width: 1199px) {
  .counter-box {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .counter-box_icon {
    margin-right: 20px;
  }

  .counter-list.style3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
  }

  .counter-list.style3 li {
    height: 100px;
    width: 100px;
    max-width: 100px;
    line-height: 100px;
  }

  .counter-list.style3 li .count-number {
    font-size: 24px;
  }

  .counter-list.style4 {
    gap: 20px;
  }

  .counter-list.style4 li {
    height: 100px;
    width: 100px;
    max-width: 100px;
    line-height: 100px;
  }

  .counter-list.style4 li .count-number {
    font-size: 24px;
  }

  .counter-list.style4 li .count-name {
    font-size: 12px;
  }

  .counter-list.style5 li {
    width: 180px;
    height: 180px;
    line-height: 180px;
    max-width: 180px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .counter-number {
    margin-bottom: 7px;
  }

  .counter-box_icon {
    width: 60px;
    text-align: center;
  }

  .counter-list li {
    min-width: unset;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .counter-list li .count-number {
    font-size: 42px;
  }

  .counter-list.mb-60 {
    margin-bottom: 40px;
  }

  .counter-list.style5 {
    gap: 20px;
  }

  .counter-list.style5 li {
    width: 159px;
    height: 159px;
    line-height: 159px;
  }

  .counter-list.style5 li .count-number {
    font-size: 36px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .counter-pill {
    width: 100px;
    height: 100px;
    border: 8px solid #f8f8f8;
  }

  .counter-pill .counter-number {
    font-size: 26px;
    margin-bottom: 0;
  }

  .counter-pill_text {
    font-size: 14px;
  }

  .img-box-style1 .img-content,
  .img-box-style1 .counter-pill {
    left: 10%;
    bottom: 0;
  }

  .img-box-style1 .img-content {
    left: 50%;
    bottom: 40%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .img-box-style1 .counter-pill:nth-of-type(2) {
    left: unset;
    right: 10%;
    bottom: -15px;
  }

  .img-box-style1 .img-content .counter-number {
    font-size: 30px;
  }

  .img-box-style1 .img-content .text {
    font-size: 18px;
  }

  .counter-list li .count-number {
    font-size: 24px;
  }

  .counter-list li .count-name {
    font-size: 14px;
  }

  .counter-list.style5 li {
    width: 114px;
    height: 114px;
    line-height: 114px;
  }

  .counter-list.style5 li .count-number {
    font-size: 30px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {

  .img-box-style1 .img-content,
  .img-box-style1 .counter-pill {
    left: 5%;
  }

  .img-box-style1 .img-content {
    left: 50%;
    bottom: 40%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .img-box-style1 .counter-pill:nth-of-type(2) {
    right: 5%;
  }

  .img-box-style1 .img-content .text {
    font-size: 16px;
  }

  .counter-list.style2 li {
    margin-right: 30px;
    padding-right: 30px;
  }

  .counter-list.style2 li:after {
    font-size: 34px;
    right: -5px;
  }

  .counter-list.style2 li .count-number {
    font-size: 30px;
  }

  .counter-list.style3 {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 5px;
  }

  .counter-list.style3 li .count-number {
    font-size: 28px;
  }

  .counter-list.style3 li .count-name {
    font-size: 14px;
  }

  .counter-list.style4 {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 5px;
  }

  .counter-list.style4 li {
    border-width: 2px;
  }

  .counter-list.style4 li .count-number {
    font-size: 28px;
  }

  .counter-list.style5 {
    gap: 5px;
  }

  .counter-list.style5 li {
    width: 84px;
    height: 84px;
    line-height: 84px;
    min-width: auto;
    max-width: 84px;
  }

  .counter-list.style5 li .count-number {
    font-size: 24px;
  }

  .counter-list.style5 li .count-name {
    font-size: 12px;
  }
}

/* Extra small devices */
@media (max-width: 416px) {
  .counter-list.style2 li {
    margin-right: 20px;
    padding-right: 20px;
  }

  .counter-list.style3 li {
    height: 70px;
    width: 70px;
    max-width: 70px;
    line-height: 70px;
  }

  .counter-list.style3 li .count-number {
    font-size: 22px;
  }

  .counter-list.style3 li .count-name {
    font-size: 12px;
  }

  .counter-list.style4 li {
    height: 70px;
    width: 70px;
    max-width: 70px;
    line-height: 70px;
  }

  .counter-list.style4 li .count-number {
    font-size: 22px;
  }

  .counter-list.style4 li .count-name {
    font-size: 10px;
    font-weight: 500;
  }

  .counter-list.style5 li {
    width: 70px;
    height: 70px;
    line-height: 70px;
    max-width: 70px;
  }

  .counter-list.style5 li .count-number {
    font-size: 20px;
    margin-bottom: 0;
  }

  .counter-list.style5 li .count-name {
    font-size: 12px;
  }
}

/*------------------- 4.00. Speaker section  -------------------*/
.team-box {
  margin-bottom: 30px;
}

.team-box_img {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  margin-bottom: 22px;
}

.team-box_img:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #171b2a;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.team-box_img img {
  width: 100%;
}

.team-box_title {
  font-size: 24px;
  margin-bottom: 4px;
  text-align: center;
}

.team-box_title a {
  color: inherit;
}

.team-box_title a:hover {
  color: var(--theme-color);
}

.team-box_desig {
  text-align: center;
  display: block;
  margin-bottom: -0.4em;
}

.team-box .vs-social {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  z-index: 2;
  gap: 10px;
}

.team-box .vs-social a {
  --icon-size: 45px;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(60px);
  transform: translateY(60px);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: 999px;
  margin-right: 0;
}

.team-box .vs-social a:nth-child(1) {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.team-box .vs-social a:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.team-box .vs-social a:nth-child(3) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.team-box .vs-social a:nth-child(4) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.team-box:hover .team-box_img:before {
  visibility: visible;
  opacity: 0.4;
}

.team-box:hover .vs-social a {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.speaker-img-slider .speaker-img img {
  border-radius: 15px;
  width: 100%;
}

.speaker-about-slider {
  margin-bottom: 25px;
}

.speaker-about .speaker-desig {
  display: inline-block;
  color: var(--theme-color);
  position: relative;
  padding-left: 44px;
  margin-bottom: 16px;
}

.speaker-about .speaker-desig:before {
  content: "";
  height: 2px;
  width: 30px;
  background-color: var(--theme-color);
  position: absolute;
  top: 11px;
  left: 0;
}

.speaker-about .speaker-name {
  margin-bottom: 20px;
}

.speaker-avater {
  cursor: pointer;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: relative;
  z-index: 2;
  border-radius: 10px;
}

.speaker-avater:before {
  content: "";
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  position: absolute;
  top: -10px;
  left: -10px;
  background-color: var(--theme-color);
  z-index: -1;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: inherit;
}

.speaker-avater img {
  width: 100%;
  border-radius: inherit;
}

/* .slick-current .speaker-avater:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.speaker-avater-slider .slick-list {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
} */

.speaker-slider-wrapper {
  padding-left: 20px;
}

.team-card {
  margin-bottom: 30px;
}

.team-card_img {
  margin-bottom: 30px;
  border-radius: 15px;
  overflow: hidden;
}

.team-card_desig {
  color: var(--theme-color);
  margin-bottom: 5px;
  display: block;
}

.team-card_title {
  margin-bottom: 27px;
  font-size: 24px;
}

.team-card_title a {
  color: inherit;
}

.team-card_title a:hover {
  color: var(--theme-color);
}

.team-card_about {
  padding: 0;
  margin: 0;
  list-style: none;
  border-radius: 15px;
  border: 1px solid var(--border-color);
}

.team-card_about li {
  border-bottom: 1px solid var(--border-color);
  padding: 15px 20px;
  padding-left: 46px;
  position: relative;
}

.team-card_about li:last-child {
  border-bottom: none;
}

.team-card_about i {
  position: absolute;
  left: 20px;
}

.team-card_about i,
.team-card_about strong {
  color: var(--title-color);
  margin-right: 7px;
}

.team-card_about strong {
  font-weight: 600;
}

.img-box-style1 {
  position: relative;
  z-index: 2;
  display: inline-block;
}

.img-box-style1 .img1 {
  margin-right: 75px;
}

.team-grid {
  /* -webkit-box-shadow: 0px 10px 25px 0px rgba(148, 148, 148, 0.07);
  box-shadow: 0px 10px 25px 0px rgba(148, 148, 148, 0.07);
  border-radius: 30px; */
  overflow: hidden;
}

.team-grid_img {
  position: relative;
}

.team-grid_img img {
  width: 100%;
 
}

.team-grid_info {
  background-color: var(--white-color);
  padding: 27px 57px;
  position: relative;
  text-align:center;
  z-index: 2;
}

.team-grid_info:before {
  content: "";
  width: 85%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  position: absolute;
  top: 0;
  left: -100%;
  z-index: -1;
}

.team-grid_title {
  font-size: 24px;
  color: var(--title-color);
  margin-bottom: 2px;
}

.team-grid_title a {
  color: inherit;
}

.team-grid_desig {
  /* color: var(--theme-color); */
  color: #9c3519;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
}

.team-grid .plus-icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 15px 0 0 0;
}

.team-grid .team-social {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 9;
  right: -100%;
  height: 100%;
  color: var(--white-color);
  /* background-color: var(--theme-color); */
  background-color: #9c3519;
  padding: 0 30px;
  border-radius: 15px 15px 15px 15px;
  transition: 0.4s ease;
  font-weight: 400;
  font-style: normal;
  line-height: 21px;
  text-align: center;
  text-transform: none;
  padding: 30px 30px 30px 30px;
  margin: 0px 0px 0px 0px;
}

.team-grid .team-social a {
  margin-right: 20px;
  line-height: 50px;
}

.team-grid .team-social a:last-child {
  margin-right: 0;
}

.team-grid:hover .team-social {
  right: 0;
  margin-right: 25px; font-weight: unset;

}

/* .team-grid:hover .team-grid_info:before {
  visibility: visible;
  opacity: 1;
  left: 0;
} */

/* .team-grid:hover .team-grid_title,
.team-grid:hover .team-grid_desig {
  color: var(--white-color);
} */

/* ------- Speaker Details */
.team-details {
  position: relative;
}

.team-details .team-content-card {
  position: absolute;
  bottom: 0;
}

.team-details .team-img {
  position: relative;
  z-index: 2;
  max-width: 490px;
}

.team-content-card {
  --border-gap: 30px;
  border-radius: 15px;
  padding: 55px 80px 55px 280px;
  margin: var(--border-gap);
  position: relative;
}

.team-content-card:after {
  content: "";
  width: calc(100% + var(--border-gap)*2);
  height: calc(100% + var(--border-gap)*2);
  position: absolute;
  top: calc(0px - var(--border-gap));
  left: calc(0px - var(--border-gap));
  border: 2px dashed var(--theme-color);
  border-radius: inherit;
  z-index: -1;
}

.team-content-card .team-name {
  font-size: 40px;
  margin-bottom: 0;
}

.team-content-card .team-desig {
  display: block;
  margin-bottom: 17px;
  color: var(--theme-color);
  font-size: 18px;
}

.team-content-card .info-list ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.team-content-card .info-list li {
  color: var(--body-color);
  font-family: var(--body-font);
  font-size: 18px;
  margin-bottom: 8px;
}

.team-content-card .info-list li:last-child {
  margin-bottom: 0;
}

.team-content-card .info-list li a {
  color: inherit;
}

.team-content-card .info-list li a:hover {
  color: var(--theme-color);
}

.team-content-card .info-list strong {
  color: var(--title-color);
  font-weight: 600;
  margin-right: 10px;
}

/* Large devices */
@media (max-width: 1199px) {
  .team-box_title {
    font-size: 22px;
  }

  .team-box_desig {
    font-size: 14px;
  }

  .speaker-slider-wrapper {
    padding-left: 0;
  }

  .img-box-style1 {
    margin-bottom: 50px;
  }

  .team-content-card {
    padding: 55px 30px 55px 300px;
  }

  .team-content-card .team-name {
    font-size: 34px;
  }

  .team-content-card .info-list li {
    font-size: 18px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .speaker-img-slider {
    margin-bottom: 40px;
  }

  /*-- Speaker Details --*/
  .team-details .team-content-card {
    position: relative;
    bottom: unset;
    z-index: 3;
  }

  .team-content-card {
    padding: 35px 40px;
  }

  .team-content-card .team-name {
    font-size: 30px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .img-box-style1 {
    margin-bottom: 40px;
  }

  .img-box-style1 .img1 {
    margin-right: 0;
  }

  .team-content-card {
    --border-gap: 15px;
    padding: 35px 20px;
  }

  .team-content-card .info-list li {
    font-size: 18px;
  }

  .speaker-avater:before {
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    top: -5px;
    left: -5px;
  }

  .team-card_title {
    font-size: 22px;
  }

  .team-grid_title {
    font-size: 22px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .team-content-card .team-name {
    font-size: 26px;
  }

  .team-content-card .info-list li {
    font-size: 16px;
  }

  .speaker-about {
    text-align: center;
  }
}

/* Extra small devices */
@media (max-width: 416px) {
  .team-card_about li {
    font-size: 14px;
  }

  .team-card_about li {
    font-size: 14px;
    padding: 12px 15px;
    padding-left: 35px;
  }

  .team-card_about i {
    position: absolute;
    left: 15px;
  }
}

/*------------------- 4.00. Project Gallery  -------------------*/
.project-gallery {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.project-card {
  position: relative;
  overflow: hidden;
  margin-bottom: var(--bs-gutter-x);
}

.project-card .project-img {
  position: relative;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
}

.project-card .project-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--title-color);
  border-radius: inherit;
  -webkit-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  visibility: hidden;
}

.project-card .project-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.project-card .plus-btn {
  font-size: 72px;
  color: var(--white-color);
  position: absolute;
  top: 50%;
  left: 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}

.project-card:hover .project-img:before {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0.75;
  visibility: visible;
  transform: scale(1);
}

.project-card:hover .plus-btn {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

.project-menu .link-btn {
  background-color: transparent;
  border: none;
  margin-right: 45px;
  padding: 0;
  font-weight: 500;
  margin-bottom: 20px;
}

.project-menu .link-btn:before {
  height: 2px;
  bottom: -5px;
}

.project-menu .link-btn:hover,
.project-menu .link-btn.active {
  color: var(--theme-color);
}

.project-menu .link-btn:hover:before,
.project-menu .link-btn.active:before {
  background-color: var(--theme-color);
}

.project-menu .link-btn:last-child {
  margin-right: 0;
}

/* Small devices */
@media (max-width: 767px) {
  .project-menu .link-btn {
    margin-right: 20px;
    font-size: 16px;
  }

  .project-menu .link-btn:last-child {
    margin-right: 0;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .project-menu .link-btn {
    margin-right: 16px;
    font-size: 14px;
  }

  .project-menu .link-btn:last-child {
    margin-right: 0;
  }
}

/*------------------- 4.00. Testimonial Section  -------------------*/
.teti-card {
  --space: 60px;
  border: 2px solid rgba(255, 255, 255, 0.12);
  padding: var(--space);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  position: relative;
  z-index: 2;
}

.teti-card:hover {
  border-color: transparent;
  -webkit-box-shadow: 0px 5px 30px 0px rgba(49, 16, 173, 0.27);
  box-shadow: 0px 5px 30px 0px rgba(49, 16, 173, 0.27);
}

.teti-card_quote {
  position: absolute;
  right: var(--space);
  bottom: 76px;
}

.teti-card_text {
  margin-top: -0.5em;
  color: var(--white-color);
  margin-bottom: 33px;
}

.teti-card_author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.teti-card_author .avater {
  margin-right: 20px;
}

.teti-card_name {
  color: var(--white-color);
  font-weight: 500;
  margin-top: -0.29em;
  margin-bottom: 4px;
  font-size: 24px;
}

.teti-card_desig {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 300;
}

.testi-box-img {
  border-radius: 15px;
  overflow: hidden;
}

.testi-box-img img {
  border-radius: 15px;
}

.testi-box {
  position: relative;
}

.testi-box_quote {
  position: absolute;
  bottom: 0;
  right: 40px;
}

.testi-box_text {
  margin-bottom: 27px;
  font-size: 20px;
}

.testi-box_name {
  margin-bottom: 5px;
  margin-top: 33px;
}

.testi-box_desig {
  font-size: 16px;
}

.testi-img-slider {
  position: relative;
}

/* .testi-img-slider .slick-dots {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--white-color);
  margin: 0;
  padding: 20px;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(69, 23, 241, 0.05);
  box-shadow: 0px 10px 20px 0px rgba(69, 23, 241, 0.05);
  height: auto;
  border-radius: 12px 0 12px 0;
}

.testi-img-slider .slick-dots li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.testi-img-slider .slick-dots button {
  font-size: 0;
  padding: 0;
  width: 10px;
  height: 10px;
  line-height: 0;
  border-radius: 9999px;
  border: none;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  background: #ebe9f5;
}

.testi-img-slider .slick-dots .slick-active button {
  background-color: var(--theme-color);
  width: 38px;
  height: 10px;
  -webkit-transform: scale(1);
  transform: scale(1);
} */

.testi-grid {
  background-color: var(--white-color);
  -webkit-box-shadow: 0px 7px 12px 4px rgba(75, 83, 100, 0.06);
  box-shadow: 0px 7px 12px 4px rgba(75, 83, 100, 0.06);
  padding: 60px 40px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 25px;
  border-radius: 15px;
}

.testi-grid .testi-avater {
  width: 103px;
  height: 103px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 20px;
}

.testi-grid .testi-avater>img {
  border-radius: 50%;
}

.testi-grid .testi-quote {
  color: var(--theme-color);
  width: 60px;
  height: 60px;
  line-height: 55px;
  background-color: var(--white-color);
  border-radius: 50%;
  position: absolute;
  top: 35%;
  left: -38px;
}

.testi-grid .testi-title {
  font-weight: 600;
  font-size: 24px;
}

.testi-grid .name {
  margin-bottom: 4px;
  font-weight: 600;
}

.testi-slider-area {
  position: relative;
}

.vs-icon-grid {
  --icon-size: 50px;
}

.vs-icon-grid.testi-1 {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -90px;
  width: var(--icon-size);
}

/* Large devices */
@media (max-width: 1199px) {
  .teti-card {
    padding: 40px 30px;
  }

  .testi-card_quote {
    bottom: 55px;
  }

  .testi-box_text {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .testi-grid {
    padding: 30px 30px 25px 30px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .testi-img-slider {
    margin-bottom: 35px;
  }

  /* .testi-img-slider .slick-dots {
    border-radius: 12px 12px 0 0;
  }

  .testi-img-slider .slick-dots {
    right: unset;
    left: 50%;
    padding: 12px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .testi-box-img {
    text-align: center;
  } */
}

/* Small devices */
@media (max-width: 767px) {
  .testi-box_text {
    font-size: 16;
  }

  .testi-grid .testi-title {
    font-size: 22px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .teti-card {
    --space: 20px;
    padding: 40px 20px;
  }

  .teti-card_quote {
    opacity: 0.5;
  }

  .teti-card_name {
    font-size: 20px;
  }

  .testi-box {
    text-align: center;
  }

  .testi-box_quote {
    width: 50px;
    right: 0;
  }
}

/*------------------- 4.00. Event Section  -------------------*/
.event-box:nth-child(1) {
  margin-top: 115px;
}

.event-box:nth-child(4) {
  margin-top: 115px;
}

.event-box:nth-child(5) {
  margin-top: -214px;
  margin-left: 312px;
}

.event-box:nth-child(6) {
  margin-top: -115px;
}

.event-box_img {
  position: relative;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.event-box_img:before,
.event-box_img:after {
  content: "";
  width: 100%;
  height: 0;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(20%, #4517f1), to(transparent));
  background-image: linear-gradient(to top, #4517f1 20%, transparent);
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.9;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.event-box_img:after {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(20%, var(--title-color)), to(transparent));
  background-image: linear-gradient(to top, var(--title-color) 20%, transparent);
  height: 100%;
  opacity: 0.7;
}

.event-box_title {
  color: var(--white-color);
  margin-bottom: 0;
  position: absolute;
  padding: 28px 33px;
  left: 0;
  bottom: 0;
  line-height: 1.25;
  font-size: 24px;
}

.event-box_title a {
  color: inherit;
}

.event-box_title a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.event-box .icon-btn {
  --btn-size: 50px;
  background-color: var(--white-color);
  color: var(--theme-color);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  z-index: 2;
  font-size: 20px;
}

.event-box_body {
  position: relative;
  margin-bottom: 30px;
  border-radius: 15px;
  overflow: hidden;
}

.event-box_body:hover .event-box_img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}

.event-box_body:hover .event-box_img:before {
  height: 100%;
}

.event-box_body:hover .event-box_img:after {
  opacity: 0;
}

.event-box_body:hover .icon-btn {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

.event-info span {
  margin-right: 30px;
}

.event-info span i {
  margin-right: 10px;
}

.event-info span:last-of-type {
  margin-right: 0;
}

.event-info.style2 span {
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 500;
}

.top-event-img {
  border: 10px solid var(--white-color);
  background: var(--white-color);
  -webkit-box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.06);
  box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.06);
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}

.top-event-img .event-date {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: var(--white-color);
  padding: 11px 23px 12px 27px;
  color: var(--theme-color);
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 15px 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 15px 100%);
  text-transform: capitalize;
  font-weight: 500;
}

.top-event-img .event-date i {
  margin-right: 5px;
}

.top-event-img img {
  border-radius: 10px;
}

.top-event-wrapper {
  padding-left: 20px;
}

.top-event-wrapper .event-meta span,
.top-event-wrapper .event-meta a {
  font-weight: bold;
}

.t-event-day-box {
  background-color: #2a07f9;
  color: var(--white-color);
  padding: 40px;
  margin-bottom: 30px;
  border-radius: 15px;
  position: sticky;
  top: 6%;
}

.t-event-day {
  margin-bottom: 5px;
  margin-top: -0.35em;
  color: var(--white-color);
}

.t-event-meta {
  display: block;
  margin-bottom: 40px;
}

.t-event-location {
  line-height: 1.75;
  margin-bottom: -0.5em;
}

.timeline-event {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: var(--white-color);
  margin-bottom: 10px;
  position: relative;
}

.timeline-event::before,
.timeline-event::after {
  content: "";
  background-color: var(--white-color);
  position: absolute;
  left: 50%;
  top: 35%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.timeline-event::before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--white-color);
  -webkit-box-shadow: 0px 0px 0px 16px #572aff;
  box-shadow: 0px 0px 0px 16px #572aff;
  -webkit-animation: shadowPulse 2.5s ease-in-out infinite;
  animation: shadowPulse 2.5s ease-in-out infinite;
}

.timeline-event:after {
  top: 40%;
  width: 1px;
  height: 100%;
  opacity: 0.13;
}

.timeline-event .event-speaker,
.timeline-event .event-content {
  max-width: 284px;
}

.timeline-event .event-content {
  padding-bottom: 75px;
}

.timeline-event .speaker-img {
  margin-bottom: 22px;
  border-radius: 15px;
  overflow: hidden;
}

.timeline-event .speaker-img img {
  width: 100%;
}

.timeline-event .speaker-title,
.timeline-event .event-title {
  color: var(--white-color);
  font-weight: bold;
  margin-bottom: 0;
}

.timeline-event .speaker-title a,
.timeline-event .event-title a {
  color: inherit;
}

.timeline-event .speaker-title a:hover,
.timeline-event .event-title a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.timeline-event .event-title {
  line-height: 1.333;
}

.timeline-event .speaker-title {
  margin-bottom: 5px;
}

.timeline-event .event-time {
  margin-bottom: 15px;
  display: block;
}

.timeline-event:nth-child(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.timeline-event:nth-child(even) .event-content {
  text-align: right;
}

.timeline-row:last-child .timeline-event:last-child:after {
  display: none;
}

.event-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  z-index: 2;
  margin-bottom: var(--bs-gutter-x);
}

.event-card:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: rgba(12, 13, 17, 0.7);
  z-index: 0;
}

.event-card_img {
  min-height: 300px;
}

.event-card_img img {
  width: 100%;
  height: 100%;
}

.event-card_price {
  font-size: 18px;
  font-weight: bold;
  color: var(--theme-color);
  background-color: var(--white-color);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 9999px;
  position: absolute;
  top: 40px;
  right: 40px;
}

.event-card_content {
  position: absolute;
  bottom: -85px;
  left: 0;
  padding: 40px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.event-card_title {
  color: var(--white-color);
  margin-bottom: 17px;
}

.event-card_title a {
  color: inherit;
}

.event-card .event-meta {
  margin-bottom: -6px;
}

.event-card .event-meta a,
.event-card .event-meta span {
  color: var(--white-color);
}

.event-card .vs-btn {
  margin-top: 30px;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  background-image: none;
  background-color: var(--theme-color);
}

.event-card .vs-btn:hover {
  background-image: none;
  background-color: var(--white-color);
  color: var(--theme-color);
}

.event-card:hover .event-card_content {
  bottom: 0;
}

.event-card:hover .vs-btn {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/* Extra large devices */
@media (max-width: 1680px) {
  .event-wrap .filter-item:not(:nth-child(5n+1)) .event-card {
    max-width: 386px;
    max-height: 384px;
  }

  .event-wrap .filter-item:nth-child(5n+1) .event-card {
    max-width: 792px;
  }

  .event-wrap .event-card_img img {
    width: auto;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .event-card {
    max-width: 100% !important;
    max-height: auto !important;
  }

  .event-card_img img {
    width: 100% !important;
    min-height: 300px;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

/* ------- Event Details -------*/
.event-slider-area {
  position: relative;
  --icon-gap: 60px;
  border-radius: 15px;
  overflow: hidden;
}

.event-slider-area .arrow-left,
.event-slider-area .arrow-right {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.event-slider-area .arrow-left {
  left: var(--icon-gap);
}

.event-slider-area .arrow-right {
  right: var(--icon-gap);
}

.event-head .event-title {
  font-size: 40px;
}

.event-location {
  border-radius: 15px;
  overflow: hidden;
  aspect-ratio: 16/6;
}

.event-location iframe {
  width: 100%;
  height: 100%;
}

.event-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.event-head .event-title {
  margin-bottom: 3px;
}

.event-head .date {
  height: auto;
  width: auto;
  padding: 0.5rem;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%);
  color: var(--white-color);
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-right: 25px;
  border-radius: 10px;
}

.event-head .date .day {
  font-size: 18px;
  width: 100%;
  display: inline-block;
  /* height: 55px;
  line-height: 55px; */
  border-bottom: 2px solid var(--smoke-color);
}

.event-head .date .month {
  font-size: 16px;
  line-height: 34px;
  text-transform: uppercase;
  font-family: 600;
}

.event-head .event-info {
  padding: 0;
  margin: 0;
  list-style: none;
}

.event-head .event-info i {
  margin-right: 8px;
}

.event-details {
  padding-bottom: 30px;
}

.event-details .event-content {
  margin-top: 30px;
  margin-bottom: 33px;
}

.vs-info-box {
  text-align: center;
  margin-bottom: 30px;
  border-radius: 15px;
  background: #fff;
  background-clip: padding-box;
  border: solid 2px transparent;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.vs-info-box:before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%);
}

.vs-info-box_title {
  background-color: var(--theme-color);
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%);
  color: var(--white-color);
  font-weight: 600;
  padding: 13px;
  margin-bottom: 0;
  font-size: 24px;
}

.vs-info-box_content {
  padding: 35px 40px;
  margin: 0;
  list-style: none;
  background-color: #fff;
  margin: 2px;
  margin-top: 0;
  border-radius: 0 0 11px 11px;
  min-height: 208px;
}

.vs-info-box_content li {
  margin-bottom: 20px;
}

.vs-info-box_content li:last-child {
  margin-bottom: 0;
}

.vs-info-box_content h5 {
  margin-bottom: 7px;
  font-weight: 600;
  font-size: 18px;
}

.vs-info-box_content h4 {
  font-size: 22px;
}

.vs-info-box_content span {
  display: block;
  line-height: 1.625;
}

.vs-info-box_content span.text-theme {
  line-height: 1.4;
}

.event-nav {
  padding-top: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.event-nav .nav-btn {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  color: var(--title-color);
}

.event-nav .nav-btn:before {
  content: "";
  height: 2px;
  width: 0;
  background-color: var(--theme-color);
  position: absolute;
  bottom: 2px;
  right: 0;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.event-nav .nav-btn:hover {
  color: var(--theme-color);
}

.event-nav .nav-btn:hover:before {
  width: 100%;
}

.event-nav .nav-btn:first-child i {
  margin-right: 5px;
}

.event-nav .nav-btn:last-child i {
  margin-left: 5px;
}

.event-nav .nav-btn:last-child:before {
  right: unset;
  left: 0;
}

@media (max-width: 1299px) {
  .event-box:nth-child(5) {
    margin-top: -204px;
    margin-left: 287px;
  }

  .event-head .event-title {
    font-size: 36px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .event-head .event-title {
    font-size: 30px;
  }

  .top-event-wrapper {
    padding-left: 0;
  }

  .timeline-event .event-speaker,
  .timeline-event .event-content {
    max-width: 244px;
  }

  .t-event-day-box {
    padding: 40px 20px;
    height: auto;
  }

  .t-event-meta {
    margin-bottom: 25px;
  }

  .event-box:nth-child(1) {
    margin-top: 0;
  }

  .event-box:nth-child(4) {
    margin-top: -160px;
  }

  .event-box:nth-child(5) {
    margin-top: -160px;
    margin-left: 0;
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .event-box:nth-child(6) {
    margin-top: 0;
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .top-event-img {
    margin-bottom: 50px;
  }

  .t-event-day-box {
    padding: 40px 40px;
  }

  .timeline-event {
    margin-bottom: 30px;
  }

  .timeline-event:after {
    display: none;
  }

  .timeline-row:last-child .timeline-event:last-child {
    margin-bottom: -20px;
  }

  .event-box:nth-child(4) {
    margin-top: -119px;
  }

  .event-box:nth-child(5) {
    margin-top: -119px;
  }

  .event-card_price {
    top: 20px;
    right: 20px;
  }

  .event-card_content {
    padding: 40px 20px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .event-head .event-title {
    font-size: 26px;
  }

  .event-box_title,
  .vs-info-box_title {
    font-size: 22px;
  }

  .timeline-event .event-speaker,
  .timeline-event .event-content {
    max-width: 214px;
  }

  .event-nav .nav-btn {
    font-size: 0;
  }

  .event-nav .nav-btn i {
    font-size: 18px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background-color: var(--smoke-color);
    background-color: #e8e7ed;
    text-align: center;
    color: var(--title-color);
    border-radius: 5px;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
  }

  .event-nav .nav-btn i:hover {
    background-color: var(--theme-color);
    color: var(--white-color);
  }

  .event-nav .nav-btn:before {
    display: none;
  }

  .event-box:nth-child(4) {
    margin-top: 0;
  }

  .event-box:nth-child(5) {
    margin-top: 0;
    margin-left: 0;
  }

  .event-box:nth-child(6) {
    margin-top: 0;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .event-info span {
    font-size: 14px;
  }

  .t-event-day-box {
    padding: 40px 20px;
  }

  .timeline-event {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .timeline-event:before,
  .timeline-event:after {
    display: none;
  }

  .timeline-event .event-speaker,
  .timeline-event .event-content {
    max-width: 100%;
    width: 100%;
  }

  .timeline-event .speaker-title {
    font-size: 18px;
  }

  .timeline-event .event-time,
  .timeline-event .speaker-desig {
    font-size: 14px;
  }

  .timeline-event .event-content {
    padding-bottom: 0;
    margin-top: 17px;
  }

  .timeline-event:nth-child(2n) .event-content {
    text-align: left;
  }

  .timeline-event .event-time {
    margin-bottom: 5px;
  }

  .timeline-event .speaker-img {
    margin-bottom: 14px;
  }

  .event-slider-area {
    --icon-gap: 20px;
  }

  .top-event-img .event-date {
    padding: 6px 18px 6px 25px;
    font-size: 14px;
  }

  .event-head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 20px;
  }

  .event-head .event-title {
    font-size: 24px;
  }

  /* .event-head .date {
    width: 100px;
    height: 80px;
  } */

  /* .event-head .date .day {
    font-size: 24px;
    height: 50px;
    line-height: 50px;
  } */

  /* .event-head .date .month {
    font-size: 12px;
    line-height: 29px;
  } */

  .top-event-wrapper {
    text-align: center;
  }
}

/* Extra small devices */
@media (max-width: 416px) {

  .event-card .event-meta a,
  .event-card .event-meta span {
    font-size: 14px;
  }
}

/*------------------- 4.00. schedule Section  -------------------*/
.schedule-tab {
  padding-right: 40px;
}

.schedule-meta {
  margin-bottom: 15px;
}

.schedule-meta span {
  color: var(--title-color);
  margin-right: 25px;
  display: inline-block;
}

.schedule-meta span:last-of-type {
  margin-right: 0;
}

.schedule-meta i {
  color: var(--theme-color);
  margin-right: 10px;
}

.schedule-meta a {
  color: var(--theme-color);
}

.schedule-meta a:hover {
  color: var(--theme-color);
  text-decoration: underline;
}

.schedule-tab .accordion-area {
  border-bottom: 1px solid var(--border-color);
}

.accordion-box .accordion-button {
  border: none;
  border-top: 1px solid var(--border-color);
  color: var(--title-color);
  background-color: transparent;
  padding: 33px 0 34px 0;
  margin-bottom: 0;
  text-align: left;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.accordion-box .accordion-button:after {
  background-image: none;
  display: none;
}

.accordion-box .accordion-button:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.accordion-box .accordion-button:not(.collapsed) {
  color: var(--theme-color);
  background-color: transparent;
  padding: 33px 0 15px 0;
}

.accordion-box .accordion-collapse {
  border: none;
}

.accordion-box .accordion-body {
  padding: 0;
  padding-bottom: 14px;
}

.schedule-event {
  border-radius: 15px;
  overflow: hidden;
}

.schedule-event_speaker>a {
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  border: 8px solid var(--theme-color);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin-left: -24px;
}

.schedule-event_speaker>a:first-child {
  margin-left: 0;
}

.schedule-event_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--theme-color);
  padding: 21px 30px 21px 21px;
  position: relative;
}

.schedule-event_content .btn-white:hover {
  background-color: var(--title-color);
}

.schedule-event_text {
  color: var(--white-color);
  background-color: var(--theme-color);
  padding: 0 30px;
  height: 50px;
  line-height: 50px;
  border-radius: 25px 25px 0 0;
  position: absolute;
  bottom: 100%;
  left: 30px;
}

.upcoming-countdown {
  color: var(--white-color);
  text-align: center;
}

.schedule-box-wrap {
  --space: 60px;
  background-color: var(--white-color);
  padding: var(--space);
  border-radius: 15px;
}

.schedule-box {
  border: 1px solid var(--border-color);
  padding: var(--space);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-bottom: 30px;
  border-radius: 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.schedule-box:last-child {
  margin-bottom: 0;
}

.schedule-box .schedule-btn {
  position: absolute;
  top: var(--space);
  right: var(--space);
}

.schedule-box:hover {
  -webkit-box-shadow: 0px 17px 46px 0px rgba(204, 204, 204, 0.36);
  box-shadow: 0px 17px 46px 0px rgba(204, 204, 204, 0.36);
  border-color: transparent;
}

.schedule-box_date {
  min-width: 120px;
  height: 40px;
  /* background-color: var(--theme-color); */
  background-image: linear-gradient(to right, #f8972c 0%, #cd3c27  51%, #f8972c 100%);
  -webkit-box-shadow: 0px 11px 21px 0px rgba(240, 64, 27, 0.31);
  box-shadow: 0px 11px 21px 0px rgba(240, 64, 27, 0.31);
  color: var(--white-color);
  text-align: center;
  border-radius: 20px;
  padding: 13px 12px;
  margin-right: 40px;
}
.teamall .slick-slide img{
/* max-height:300px; */
margin: auto;
}
.schedule-box_date .month {
  font-size: 18px;
  font-weight: 500;
}

.schedule-box_date .date {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
}

.schedule-box_title {
  margin-top: -0.3em;
}

.schedule-box_title a {
  color: inherit;
}

.schedule-box_title a:hover {
  color: var(--theme-color);
}

.schedule-box_text {
  margin-bottom: 0;
}

.speaker-box {
  border: 1px solid var(--border-color);
  padding: 35px 25px 32px 25px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.04);
  box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.04);
  text-align: center;
}

.speaker-box .speaker-img {
  width: 60px;
  margin: 0 auto 15px auto;
  border-radius: 50%;
  overflow: hidden;
}

.speaker-box .speaker-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 2px;
  color: var(--title-color);
}

.speaker-box .speaker-desig {
  color: var(--theme-color);
  font-size: 14px;
  display: block;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.speaker-box .speaker-text {
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}

.speaker-box .speaker-text:last-child {
  margin-bottom: -0.6em;
}

.event-meta {
  margin-bottom: 6px;
}

.event-meta a,
.event-meta span {
  color: var(--theme-color);
  margin-right: 20px;
}

.event-meta a:last-child,
.event-meta span:last-child {
  margin-right: 0;
}

.event-meta i {
  margin-right: 8px;
}

.event-content .event-title {
  margin-bottom: 20px;
}

.event-content .event-text {
  margin-bottom: 27px;
}

.schedule-grid {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40px;
}

.schedule-grid:last-child {
  margin-bottom: 0;
}

.schedule-grid .event-img {
  border-radius: 15px;
  overflow: hidden;
}

.schedule-grid .event-img img {
  width: 100%;
}

.schedule-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px 34px;
  background-color: var(--white-color);
  border: 1px solid #ececec;
  border-radius: 15px;
  margin-bottom: 30px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.schedule-card_title {
  font-size: 24px;
  margin-bottom: 0;
}

.schedule-card_title i {
  color: var(--theme-color);
  margin-right: 22px;
}

.schedule-card_title a {
  color: inherit;
}

.schedule-card_title a:hover {
  color: var(--theme-color);
}

.schedule-card .time,
.schedule-card .location {
  display: inline-block;
  margin-right: 80px;
}

.schedule-card .time i,
.schedule-card .location i {
  margin-right: 8px;
}

.schedule-card .time a,
.schedule-card .location a {
  color: var(--theme-color);
  text-decoration: underline;
  text-underline-offset: 3px;
}

.schedule-card .location i {
  color: var(--theme-color);
}

.schedule-card:hover {
  -webkit-box-shadow: 0px 13px 30px 0px rgba(82, 82, 82, 0.08);
  box-shadow: 0px 13px 30px 0px rgba(82, 82, 82, 0.08);
}

/* Medium Large devices */
@media (max-width: 1399px) {

  .schedule-card .time,
  .schedule-card .location {
    margin-right: 30px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .schedule-tab {
    padding-right: 0;
  }

  .schedule-card {
    padding: 20px;
  }

  .schedule-card_title {
    font-size: 20px;
  }

  .schedule-card_title i {
    margin-right: 10px;
  }

  .schedule-card .time,
  .schedule-card .location {
    margin-right: 25px;
  }

  .schedule-card .vs-btn {
    padding: 20.5px 25px;
    min-width: 140px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .schedule-box-wrap {
    --space: 30px;
  }

  .schedule-box .schedule-btn {
    position: static;
    margin-top: 30px;
  }

  .accordion-box .accordion-button {
    padding: 20px 0 22px 0;
  }

  .accordion-box .accordion-button:not(.collapsed) {
    padding: 20px 0 12px 0;
  }

  .accordion-box .accordion-body {
    padding-bottom: 7px;
  }

  .schedule-box_date {
    margin-right: 25px;
  }

  .schedule-grid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    gap: 30px;
  }

  .schedule-card {
    margin-bottom: 20px;
  }

  .schedule-card_title i {
    margin-right: 6px;
  }

  .schedule-card .time,
  .schedule-card .location {
    font-size: 14px;
    margin-right: 15px;
  }

  .schedule-card .location {
    margin-right: 0;
  }

  .schedule-card .vs-btn {
    display: none;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .schedule-box-wrap {
    --space: 20px;
  }

  .schedule-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 40px 20px;
  }

  .schedule-box .schedule-btn {
    margin-top: 25px;
  }

  .schedule-box_date {
    width: 80px;
    margin-bottom: 20px;
  }

  .schedule-card {
    padding: 30px 20px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .schedule-event_content {
    padding: 18px 20px 27px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 10px;
  }

  .schedule-event_speaker>a:first-child {
    margin-left: -8px;
  }

  .accordion-box .accordion-button {
    font-size: 18px;
  }

  .accordion-box .accordion-body p {
    font-size: 16px;
  }

  .accordion-box .schedule-meta span {
    font-size: 14px;
  }

  .schedule-card_title {
    font-size: 18px;
    padding-left: 28px;
    position: relative;
  }

  .schedule-card_title i {
    position: absolute;
    left: 0;
  }
}

/*------------------- 4.00. price Section  -------------------*/
.price-box {
  background-color: var(--white-color);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.06);
  padding: 60px;
  margin-bottom: 30px;
  border-radius: 15px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  position: relative;
  z-index: 2;
}

.price-box:before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: var(--theme-color); */
  background-color: #9c3519;
  border-radius: inherit;
  z-index: -1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.price-box_price {
  font-size: 48px;
  margin-top: -0.22em;
  /* color: var(--theme-color); */
  color: #9c3519;
  display: block;
  font-weight: bold;
  margin-bottom: 15px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.price-box_title {
  font-size: 24px;
  padding-bottom: 25px;
  margin-bottom: 34px;
  color: var(--title-color);
  border-bottom: 1px solid #eaeaea;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.price-box_list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.price-box_list li {
  display: block;
  margin-bottom: 13px;
  line-height: 1.6;
  position: relative;
  padding-left: 18px;
}

.price-box_list li:before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  top: 10px;
  left: 0;
  background-color: var(--body-color);
  border-radius: 50%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.price-box .vs-btn {
  margin-top: 19px;
}

.price-box:hover {
  color: var(--white-color);
}

.price-box:hover:before {
  width: 100%;
}

.price-box:hover .price-box_price,
.price-box:hover .price-box_title {
  color: var(--white-color);
}

.price-box:hover .price-box_list li:before {
  background-color: var(--white-color);
}

.price-box:hover .vs-btn {
  background-color: var(--white-color);
  color: var(--theme-color);
  background-image: none;
}

.price-card {
  text-align: center;
  background-color: var(--white-color);
  -webkit-box-shadow: 0px 5px 30px 0px rgba(158, 158, 158, 0.08);
  box-shadow: 0px 5px 30px 0px rgba(158, 158, 158, 0.08);
  padding: 70px 25px;
  margin-bottom: 30px;
  border-radius: 15px;
}

.price-card .vs-btn {
  margin-top: 25px;
  margin-bottom: 22px;
}

.price-card .condition {
  display: block;
  font-size: 14px;
}

.price-card_list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 40px;
}

.price-card_list .list-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
  display: block;
  color: var(--title-color);
}

.price-card_list li {
  margin-bottom: 24px;
}

.feature-disabled .list-title {
  color: #b3b3b3;
}

.feature-disabled .list-text {
  color: #bcbcbc;
}

.price-card_title {
  font-weight: 600;
  margin-top: -0.28em;
  font-size: 24px;
}

.price-card_price {
  color: var(--theme-color);
  line-height: 1.2;
  display: block;
}

/* Price Details */
.accordion-card {
  margin-bottom: 30px;
  --border-color: #ededed;
  border: 1px solid transparent;
  border-radius: 15px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.accordion-card.active {
  border-color: var(--border-color);
  -webkit-box-shadow: 0px 12px 40px 0px rgba(203, 201, 209, 0.25);
  box-shadow: 0px 12px 40px 0px rgba(203, 201, 209, 0.25);
}

.accordion-card:last-child {
  margin-bottom: 0;
}

.accordion-card .accordion-button {
  font-size: 18px;
  font-weight: bold;
  border: 1px solid var(--border-color);
  color: var(--title-color);
  background-color: transparent;
  padding: 16px 30px 16px 30px;
  margin-bottom: 0;
  text-align: left;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 999px;
}

.accordion-card .accordion-button:after {
  content: "";
  background-image: none;
  font-family: var(--icon-font);
  color: var(--title-color);
  font-weight: 900;
  font-size: 0.9em;
  width: auto;
  height: auto;
}

.accordion-card .accordion-button:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.accordion-card .accordion-button:not(.collapsed) {
  border-radius: 0;
  border-color: transparent;
  color: var(--theme-color);
  background-color: transparent;
  padding: 16px 30px 10px 30px;
}

.accordion-card .accordion-button:not(.collapsed):after {
  color: var(--theme-color);
}

.accordion-card .accordion-collapse {
  border: none;
}

.accordion-card .accordion-body {
  padding: 0 30px 0 30px;
}

/* Large devices */
@media (max-width: 1199px) {
  .price-box {
    padding: 50px 35px;
  }

  .price-box_title {
    margin-bottom: 25px;
  }

  .price-box .vs-btn {
    margin-top: 20px;
  }

  .price-card {
    padding: 45px 20px;
  }

  .price-card .vs-btn {
    margin-top: 18px;
  }

  .price-card_list {
    margin-top: 30px;
  }

  .price-card_list li {
    margin-bottom: 18px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .price-box_price {
    font-size: 40px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .price-card_title {
    font-size: 22px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .price-box_price {
    font-size: 36px;
  }

  /* Price Details */
  .accordion-card .accordion-button {
    padding: 15px 20px 15px 20px;
    font-size: 16px;
  }

  .accordion-card .accordion-button:not(.collapsed) {
    padding: 15px 20px 15px 20px;
  }

  .accordion-card .accordion-body {
    padding: 0 20px 0 20px;
    font-size: 14px;
  }

  .accordion-card .accordion-body p {
    font-size: 14px;
  }
}

/*------------------- 4.00. Contact Section  -------------------*/
.form-box {
  --bs-gutter-x: 35px;
  --space: 60px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 9px 46px 0px rgba(220, 220, 220, 0.14);
  box-shadow: 0px 9px 46px 0px rgba(220, 220, 220, 0.14);
  background-color: var(--white-color);
  padding: var(--space);
}

.form-box label {
  font-weight: bold;
}

.contact-info-wrapper {
  padding-right: 25px;
}

.map-shadow {
  -webkit-box-shadow: 0px 5px 40px 0px rgba(62, 62, 62, 0.1);
  box-shadow: 0px 5px 40px 0px rgba(62, 62, 62, 0.1);
  background-color: var(--white-color);
  border: 10px solid var(--white-color);
  aspect-ratio: 16/11.1;
  border-radius: 15px;
}

.map-shadow iframe {
  height: 100%;
  border-radius: 12px;
  margin-bottom: -5px;
}

.subscribe-box {
  padding: 80px;
  position: relative;
  z-index: 3;
}

.newsletter-form2 {
  position: relative;
}

.newsletter-form2 .form-control {
  height: 60px;
}

.newsletter-form2 .form-group {
  margin-bottom: 0;
}

.newsletter-form2 .vs-btn {
  padding: 9px 28px;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.newsletter-form2 .vs-btn i {
  font-size: 0;
  display: none;
}

.register-form-wrapper {
  border-radius: 15px;
  overflow: hidden;
  -webkit-box-shadow: 0px 12px 30px 0px rgba(203, 201, 209, 0.05);
  box-shadow: 0px 12px 30px 0px rgba(203, 201, 209, 0.05);
  background-color: var(--white-color);
}

.register-form-wrapper .form-title {
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  padding: 14px;
  margin-bottom: 0;
}

.register-form {
  padding: 60px;
  --bs-gutter-x: 30px;
}

.register-form .custom-checkbox {
  margin-top: 30px;
  margin-bottom: 25px;
}

.contact-box {
  background-color: var(--theme-color);
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%);
  border-radius: 15px;
  color: var(--white-color);
  padding: 40px;
  -webkit-box-shadow: 0px 11px 21px 0px rgba(240, 64, 27, 0.31);
  box-shadow: 0px 11px 21px 0px rgba(240, 64, 27, 0.31);
}

.contact-box span,
.contact-box a {
  color: var(--white-color);
}

.contact-box a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.contact-box_title {
  color: var(--white-color);
  margin-top: -0.28em;
  margin-bottom: 9px;
}

.contact-box_text {
  line-height: 1.625;
}

.contact-area {
  background-color: var(--white-color);
  -webkit-box-shadow: 0px 22px 50px 0px rgba(211, 211, 211, 0.2);
  box-shadow: 0px 22px 50px 0px rgba(211, 211, 211, 0.2);
  border-radius: 15px;
}

.contact-area iframe {
  margin: 0 10px 8px 10px;
  aspect-ratio: 16/6.7;
  width: calc(100% - 20px);
  border-radius: 15px;
}

.contact-box-wrapper {
  padding: 60px;
}

.contact-form {
  border-radius: 15px;
  padding: 40px;
  background-color: var(--white-color);
}

.contact-form textarea {
  height: 150px;
  margin-bottom: 30px;
}

.contact-tab-wrapper {
  padding: 60px;
  background-color: var(--white-color);
  -webkit-box-shadow: 0px 17px 40px 0px rgba(206, 206, 206, 0.1);
  box-shadow: 0px 17px 40px 0px rgba(206, 206, 206, 0.1);
  border-radius: 15px;
}

.contact-tab-content {
  margin-top: 25px;
}

.contact-tab-content>i {
  /* color: var(--theme-color); */
  color: #9c3519;
  font-size: 36px;
  margin-bottom: 7px;
}

.contact-tab-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contact-tab-content li {
  list-style: none;
  margin-bottom: 7px;
}

.contact-tab-content li strong {
  font-weight: 600;
  color: var(--title-color);
}

.contact-tab_title {
  color: var(--title-color);
  margin-bottom: 12px;
  font-size: 24px;
}

.follow-card {
  background-color: var(--smoke-color);
  border-radius: 999px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 30px;
  margin-top: 35px;
}

.follow-card .card-title {
  margin-bottom: 0;
  font-weight: 600;
  color: var(--title-color);
}

.follow-card .simple-social {
  margin-left: 15px;
}

.follow-card a {
  font-size: 16px;
  color: var(--body-color);
}

.follow-card a:hover {
  color: var(--theme-color);
}

.location-card-wrap {
  padding: 50px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.location-card-wrap .simple-icon {
  color: var(--white-color);
}

.location-card-wrap .simple-icon:hover {
  color: var(--title-color);
}

.location-card-wrap .vs-icon-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  position: absolute;
  top: 45px;
  right: 50px;
}

.map-slide-wrap {
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
}

.map-slide-wrap iframe {
  width: 100%;
  aspect-ratio: 16/8.6;
}

.location-card .location-title {
  color: var(--white-color);
  font-size: 24px;
  /* margin-top: -0.27em; */
  border-bottom: 2px solid #fff;
  padding-bottom: 15px;
  margin-bottom: 35px;
}

.location-card .info-title,
.location-card .info-text {
  color: var(--white-color);
}

.location-card .vs-btn {
  margin-top: 33px;
}

.location-info {
  position: relative;
  padding-left: 20px;
  margin-bottom: 29px;
}

.location-info:before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: var(--white-color);
  border-radius: 999px;
  position: absolute;
  left: 0;
  top: 7px;
}

.location-info:last-of-type {
  margin-bottom: 0;
}

.location-info .info-title {
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin-bottom: 6px;
}

.location-info .info-text {
  margin-bottom: 0;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .map-slide-wrap iframe {
    width: 100%;
    aspect-ratio: 16/9.5;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .contact-info-wrapper {
    padding-right: 0;
  }

  .register-form {
    padding: 40px;
  }

  .subscribe-box {
    padding: 60px;
  }

  .contact-box-wrapper {
    padding: 20px;
  }

  .contact-box {
    padding: 30px 20px;
  }

  .map-shadow {
    aspect-ratio: 16/17;
  }

  .map-slide-wrap iframe {
    width: 100%;
    aspect-ratio: 16/12.9;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .contact-info-area {
    margin-bottom: 40px;
  }

  .contact-tab-wrapper {
    margin-bottom: 40px;
  }

  .map-shadow {
    aspect-ratio: 16/9;
  }

  .contact-box {
    padding: 40px;
  }

  .map-slide-wrap iframe {
    width: 100%;
    aspect-ratio: 16/9;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .form-box {
    padding: 45px 20px;
  }

  .contact-box {
    padding: 30px 20px;
  }

  .contact-tab_title {
    font-size: 22px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .subscribe-box {
    padding: 30px 20px;
  }

  .newsletter-form2 .vs-btn {
    font-size: 0;
    min-width: 40px;
    padding: 9px 15px;
  }

  .newsletter-form2 .vs-btn i {
    display: inline-block;
    font-size: 16px;
    line-height: 1.4;
  }

  .register-form {
    padding: 40px 20px;
  }

  .register-form .custom-checkbox {
    margin-top: 25px;
    margin-bottom: 20px;
  }

  .contact-tab-wrapper {
    padding: 45px 20px;
  }

  .contact-form {
    padding: 40px 20px;
  }

  .contact-area iframe {
    aspect-ratio: 16/8;
  }

  .location-card-wrap {
    padding: 50px 25px;
  }
}

@media (max-width: 400px) {
  .follow-card {
    padding: 16px 20px;
  }

  .follow-card .simple-social {
    margin-left: 10px;
  }

  .follow-card .simple-social a {
    margin-right: 10px;
  }

  .follow-card .simple-social a:last-child {
    margin-right: 0;
  }
}

/*------------------- 4.00. Brand Section  -------------------*/
.brand-grid {
  display: grid;
  grid-template-columns: 2.6fr 3.7fr 3.7fr 2.6fr;
}

.brand-img {
  text-align: center;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-color: rgba(255, 255, 255, 0.08) !important;
  height: 140px;
  display: grid;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.brand-img:nth-child(1),
.brand-img:nth-child(5) {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

.brand-img:nth-child(8),
.brand-img:nth-child(4) {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  border-right: none;
}

.brand-img:nth-child(5),
.brand-img:nth-child(6),
.brand-img:nth-child(7),
.brand-img:nth-child(8) {
  border-bottom: none;
}

.brand-wrap {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  --bs-gutter-x: 50px;
  --bs-gutter-y: 50px;
}

.brand-wrap [class*=col] {
  text-align: center;
}

.brand-wrap img {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.brand-wrap img:hover {
  -webkit-filter: grayscale(100);
  filter: grayscale(100);
}

.brand-img-shadow {
  -webkit-box-shadow: 0px 12px 40px 0px rgba(203, 201, 209, 0.15);
  box-shadow: 0px 12px 40px 0px rgba(203, 201, 209, 0.15);
  text-align: center;
  background-color: var(--white-color);
  padding: 42px 15px;
  border-radius: 15px;
}

.brand-img-shadow img {
  height: 42px;
}

/* Large devices */
@media (max-width: 1199px) {
  .brand-wrap {
    --bs-gutter-x: 25px;
    --bs-gutter-y: 25px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .brand-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .brand-img {
    padding: 0 15px;
    height: 100px;
  }

  .brand-img:nth-child(even) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: none;
  }

  .brand-img:nth-child(odd) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .brand-img:nth-child(5),
  .brand-img:nth-child(6) {
    border-bottom: 1px solid;
  }
}

/*------------------- 4.00. Tab menu  -------------------*/
.tab-style1 {
  border-bottom: none;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  gap: 10px;
}

.tab-style1 .vs-btn {
  background: var(--white-color);
  color: var(--title-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  min-width: auto;
  -webkit-box-shadow: 0px 13px 30px 0px rgba(82, 82, 82, 0.08);
  box-shadow: 0px 13px 30px 0px rgba(82, 82, 82, 0.08);
  border: 1px solid #ececec;
}

.tab-style1 .vs-btn.active {
  background: var(--theme-color);
  color: var(--white-color);
}

.tab-style2 {
  border-bottom: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
  margin-bottom: var(--space);
  position: relative;
  z-index: 2;
}

.tab-style2 .indicator {
  position: absolute;
  background-color: var(--theme-color);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: 15px;
  z-index: -1;
}

.tab-style2 .indicator:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 15px 0 15px;
  border-color: var(--theme-color) transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tab-style2 .tab-btn {
  min-width: 100px;
  padding: 35px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background-color: transparent;
  border: none;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  border-radius: 15px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.tab-style2 .tab-btn:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid var(--border-color);
  border-radius: inherit;
  z-index: -1;
}

.tab-style2 .tab-btn .date {
  font-size: 16px;
  font-weight: 400;
  display: block;
  margin-top: 15px;
  color: var(--body-color);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.tab-style2 .tab-btn.active {
  color: var(--white-color);
  border-color: var(--theme-color);
  background: var(--theme-color);
}
.tab-style2 .tab-btn.active :after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 15px 0 15px;
  border-color: var(--theme-color) transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tab-style2 .tab-btn.active span {
  color: var(--white-color);
}

.tab-style3 {
  border-bottom: 2px solid var(--border-color);
  position: relative;
  margin-bottom: 32px;
}

.tab-style3 .tab-btn {
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 18px 23px;
  padding-top: 0;
}

.tab-style3 .tab-btn.active {
  color: var(--theme-color);
}

.tab-style3 .indicator {
  position: absolute;
  top: unset !important;
  bottom: -2px;
  background-color: var(--theme-color);
  height: 2px !important;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.tab-style4 {
  border-bottom: none;
  gap: 15px;
}

.tab-style4 .vs-btn {
  background: var(--white-color);
  color: var(--theme-color);
  -webkit-box-shadow: 0px 12px 30px 0px rgba(203, 201, 209, 0.1);
  box-shadow: 0px 12px 30px 0px rgba(203, 201, 209, 0.1);
}

.tab-style4 .vs-btn.active,
.tab-style4 .vs-btn:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: var(--theme-color);
  color: var(--white-color);
}

.tab-style5 {
  border-bottom: 2px solid #f0f0f0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.tab-style5 .tab-btn {
  border: none;
  background-color: transparent;
  color: var(--title-color);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 0;
  margin-right: 35px;
}

.tab-style5 .tab-btn.active {
  color: var(--theme-color);
}

.tab-style5 .tab-btn:last-of-type {
  margin-right: 0;
}

.tab-style5 .indicator {
  position: absolute;
  top: unset !important;
  bottom: -2px;
  left: 0;
  height: 2px !important;
  background-color: var(--theme-color);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.tab-style6 {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 60px;
}

.tab-style6 .tab-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 13px;
  border: none;
  background-color: transparent;
  -webkit-box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.04);
  box-shadow: 0px 10px 29px 0px rgba(15, 15, 18, 0.04);
  font-size: 18px;
  padding: 18px 36px;
  border-radius: 5px;
  position: relative;
}

.tab-style6 .tab-btn:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid var(--border-color);
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.tab-style6 .tab-btn i {
  font-size: 30px;
  color: var(--theme-color);
}

.tab-style6 .tab-btn .day-box {
  text-align: left;
}

.tab-style6 .tab-btn .day {
  font-size: 18px;
  font-weight: 500;
  color: var(--title-color);
  display: block;
  margin-bottom: -3px;
}

.tab-style6 .tab-btn .date {
  color: var(--body-color);
  font-size: 14px;
}

.tab-style6 .tab-btn.active {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.tab-style6 .tab-btn.active i {
  color: var(--white-color);
}

.tab-style6 .tab-btn.active .day {
  color: var(--white-color);
}

.tab-style6 .tab-btn.active .date {
  color: #ffa994;
}

.tab-style6 .indicator {
  position: absolute;
  top: 0 !important;
  bottom: 0;
  left: 0;
  height: 100% !important;
  background-color: var(--theme-color);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: 5px 5px 5px 0;
  z-index: -1;
}

.tab-style6 .indicator:after {
  content: "";
  border-style: solid;
  border-width: 20px 20px 0 0;
  border-color: var(--theme-color) transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 0;
}

.triangle-top-left {
  display: inline-block;
  width: 0;
  height: 0;
}

/* Large devices */
@media (max-width: 1199px) {
  .tab-style6 {
    gap: 20px;
  }
}

.tab-style7 {
  border-bottom: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
}

.tab-style7 .indicator {
  position: absolute;
  background-color: var(--theme-color);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  z-index: -1;
  border-radius: 15px;
}

.tab-style7 .tab-btn {
  min-width: 100px;
  padding: 31px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background-color: transparent;
  color: var(--title-color);
  font-size: 24px;
  font-weight: bold;
  border: none;
  position: relative;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.tab-style7 .tab-btn:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #ececec;
  border-radius: 15px;
  background-color: var(--white-color);
  -webkit-box-shadow: 0px 13px 30px 0px rgba(82, 82, 82, 0.08);
  box-shadow: 0px 13px 30px 0px rgba(82, 82, 82, 0.08);
  z-index: -1;
}

.tab-style7 .tab-btn .date {
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-top: 12px;
  color: var(--body-color);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.tab-style7 .tab-btn.active {
  color: var(--white-color);
  border-color: var(--theme-color);
}

.tab-style7 .tab-btn.active span {
  color: var(--white-color);
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .tab-style7 {
    gap: 20px;
  }

  .tab-style7 .tab-btn {
    padding: 23px 23px;
    font-size: 22px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .tab-style7 .tab-btn {
    font-size: 20px;
  }

  .tab-style7 .tab-btn .date {
    font-size: 14px;
    margin-top: 8px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .tab-style1 {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .tab-style2 .tab-btn {
    padding: 25px;
  }

  .tab-style2 .tab-btn .date {
    margin-top: 11px;
  }

  .tab-style6 {
    gap: 12px;
  }

  .tab-style6 .tab-btn {
    gap: 10px;
    padding: 10px 14px;
  }

  .tab-style6 .tab-btn .day {
    font-size: 16px;
  }

  .tab-style6 .tab-btn i {
    font-size: 24px;
  }

  .tab-style7 {
    gap: 15px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .tab-style2 .tab-btn {
    padding: 20px 4px;
    font-size: 18px;
  }

  .tab-style6 .indicator {
    display: none;
  }

  .tab-style7 .tab-btn {
    padding: 23px 13px;
    font-size: 20px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .tab-style1 .vs-btn {
    padding: 8px 15px;
  }

  .tab-style2 .indicator:after {
    display: none;
  }

  .tab-style2 .tab-btn {
    width: 100%;
  }

  .tab-style3 .tab-btn {
    font-size: 16px;
    padding: 15px 10px;
  }

  .tab-style5 .tab-btn {
    font-size: 16px;
    margin-right: 23px;
  }

  .tab-style5 .tab-btn:last-of-type {
    margin-right: 0;
  }

  .tab-style7 .tab-btn {
    -ms-flex-preferred-size: calc(50% - 7.5px);
    flex-basis: calc(50% - 7.5px);
  }
}

@media (max-width: 360px) {
  .tab-style5 .tab-btn {
    font-size: 14px;
  }
}

/*------------------- 4.00. Features  -------------------*/
.feature-card {
  max-width: 245px;
  text-align: center;
  margin: 0 auto;
}

.feature-card_title {
  color: var(--white-color);
  font-size: 24px;
  margin-bottom: 10px;
}

.feature-card_icon {
  position: relative;
  z-index: 2;
  height: 156px;
  width: 189px;
  line-height: 160px;
  background-color: var(--white-color);
  margin: 0 auto 26px auto;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  -webkit-clip-path: path("M146.000,126.998 C146.000,126.998 210.860,65.241 181.344,48.998 C151.827,32.757 90.485,34.240 77.156,11.999 C63.827,-10.243 20.172,-2.760 3.000,56.998 C-14.173,116.757 61.000,195.999 146.000,126.998 Z");
  clip-path: path("M146.000,126.998 C146.000,126.998 210.860,65.241 181.344,48.998 C151.827,32.757 90.485,34.240 77.156,11.999 C63.827,-10.243 20.172,-2.760 3.000,56.998 C-14.173,116.757 61.000,195.999 146.000,126.998 Z");
}

.feature-card_icon img {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}

.feature-card_icon:before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color) 0%, var(--theme-color2) 100%);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}

.feature-card_text {
  margin-bottom: -0.45em;
  color: #8f96a3;
}

.feature-card:hover .feature-card_icon {
  -webkit-clip-path: path("M21.000,78.998 C21.000,78.998 -12.566,55.808 6.000,29.998 C24.566,4.188 72.434,-2.191 93.000,1.998 C113.566,6.189 181.434,30.808 160.000,98.998 C138.566,167.189 84.434,162.808 64.000,143.998 C43.565,125.189 56.000,108.998 21.000,78.998 Z");
  clip-path: path("M21.000,78.998 C21.000,78.998 -12.566,55.808 6.000,29.998 C24.566,4.188 72.434,-2.191 93.000,1.998 C113.566,6.189 181.434,30.808 160.000,98.998 C138.566,167.189 84.434,162.808 64.000,143.998 C43.565,125.189 56.000,108.998 21.000,78.998 Z");
}

.feature-card:hover .feature-card_icon img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.feature-card:hover .feature-card_icon:before {
  opacity: 1;
}

/* Medium devices */
@media (max-width: 991px) {
  .feature-card_icon {
    margin: 0 auto 20px auto;
  }
}

/*------------------- 4.00. Banner -------------------*/
.banner-card {
  background-color: #ffeee6;
  border-radius: 15px;
  position: relative;
  padding: 50px;
  overflow: hidden;
  z-index: 2;
}

.banner-card .banner-shape {
  width: var(--icon-size, 314px);
  height: var(--icon-size, 314px);
  position: absolute;
  bottom: -120px;
  right: -50px;
  background-color: #ffdac9;
  border-radius: 50%;
  z-index: -1;
}

.banner-card .banner-shape::before,
.banner-card .banner-shape::after {
  content: "";
  position: absolute;
  background-color: #ffdac9;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 50%;
}

.banner-card .banner-shape::after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.banner-card .banner-subtitle {
  font-size: 16px;
  font-weight: bold;
  margin-top: -0.2em;
  color: var(--theme-color);
  margin-bottom: 7px;
}

.banner-card .banner-title {
  margin-bottom: 17px;
}

.banner-card .banner-text {
  margin-bottom: 28px;
  max-width: 308px;
  position: relative;
  z-index: 2;
}

.banner-card .banner-img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .banner-card .banner-shape {
    width: 220px;
    height: 220px;
  }

  .banner-card .banner-img {
    max-width: 29%;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .banner-card .banner-shape {
    width: 320px;
    height: 320px;
  }

  .banner-card .banner-img {
    max-width: 35%;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .banner-card {
    padding: 50px 5px;
    text-align: center;
  }

  .banner-card .banner-text {
    margin-left: auto;
    margin-right: auto;
  }

  .banner-card .banner-shape {
    width: 260px;
    height: 260px;
  }
}

/*=================================
    05. Spacing
==================================*/
/*-- Padding Left And Right --*/
.px-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.px-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.px-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.px-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.px-25 {
  padding-right: 25px;
  padding-left: 25px;
}

.px-30 {
  padding-right: 30px;
  padding-left: 30px;
}

.px-35 {
  padding-right: 35px;
  padding-left: 35px;
}

.px-40 {
  padding-right: 40px;
  padding-left: 40px;
}

.px-45 {
  padding-right: 45px;
  padding-left: 45px;
}

.px-50 {
  padding-right: 50px;
  padding-left: 50px;
}

/*-- Padding Top And Bottom --*/
.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

/*-- margin Left And Right --*/
.mx-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.mx-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.mx-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.mx-20 {
  margin-right: 20px;
  margin-left: 20px;
}

.mx-25 {
  margin-right: 25px;
  margin-left: 25px;
}

.mx-30 {
  margin-right: 30px;
  margin-left: 30px;
}

.mx-35 {
  margin-right: 35px;
  margin-left: 35px;
}

.mx-40 {
  margin-right: 40px;
  margin-left: 40px;
}

.mx-45 {
  margin-right: 45px;
  margin-left: 45px;
}

.mx-50 {
  margin-right: 50px;
  margin-left: 50px;
}

/*-- margin Top And Bottom --*/
.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.my-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

/*-- margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

/*-- margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mt-60 {
  margin-top: 60px;
}

/*-- margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

/*-- margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mt-n1 {
  margin-top: -0.25rem;
}

.mt-n2 {
  margin-top: -0.5rem;
}

.mt-n3 {
  margin-top: -1rem;
}

.mt-n4 {
  margin-top: -1.5rem;
}

.mt-n5 {
  margin-top: -3rem;
}

.mb-n1 {
  margin-bottom: -0.25rem;
}

.mb-n2 {
  margin-bottom: -0.5rem;
}

.mb-n3 {
  margin-bottom: -1rem;
}

.mb-n4 {
  margin-bottom: -1.5rem;
}

.mb-n5 {
  margin-bottom: -3rem;
}

/* Medium devices */
@media (max-width: 991px) {
  .ml-md-auto {
    margin-left: auto;
  }
}

@media (min-width: 1199px) {
  .mb-lg-70 {
    margin-bottom: 70px;
  }
}

.space,
.space-top {
  padding-top: var(--section-space);
}

.space,
.space-bottom {
  padding-bottom: var(--section-space);
}

.space-plus,
.space-top-plus {
  padding-top: calc(var(--section-space) + 90px);
}

.space-plus,
.space-bottom-plus {
  padding-bottom: calc(var(--section-space) + 90px);
}

.space-extra,
.space-extra-top {
  padding-top: calc(var(--section-space) - 30px);
}

.space-extra,
.space-extra-bottom {
  padding-bottom: calc(var(--section-space) - 30px);
}

.space-3x-bottom {
  padding-bottom: calc(var(--section-space) * 3);
}

.margin-2x-top {
  margin-top: calc(var(--section-space) * -2);
}

.space-about-bottom {
  padding-bottom: calc(var(--section-space) + 78px);
}

@media (max-width: 1299px) {
  .space-about-bottom {
    padding-bottom: calc(var(--section-space) + 38px);
  }
}

/* Medium devices */
@media (max-width: 991px) {

  .space,
  .space-top {
    padding-top: var(--section-space-mobile);
  }

  .space,
  .space-bottom {
    padding-bottom: var(--section-space-mobile);
  }

  .space-extra,
  .space-extra-top {
    padding-top: calc(var(--section-space-mobile) - 30px);
  }

  .space-extra,
  .space-extra-bottom {
    padding-bottom: calc(var(--section-space-mobile) - 30px);
  }

  .space-plus,
  .space-top-plus {
    padding-top: var(--section-space-mobile);
  }

  .space-plus,
  .space-bottom-plus {
    padding-bottom: var(--section-space-mobile);
  }

  .space-3x-bottom {
    padding-bottom: calc(var(--section-space-mobile) * 3);
  }

  .margin-2x-top {
    margin-top: calc(var(--section-space-mobile) * -2);
  }

  .space-about-bottom {
    padding-bottom: calc(var(--section-space-mobile) + 18px);
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .space-about-bottom {
    padding-bottom: var(--section-space-mobile);
  }
}

.space-shape {
  --section-space: 190px;
}

.space-shape {
  padding-top: var(--section-space);
  padding-bottom: var(--section-space);
}

.space-top-shape {
  padding-bottom: 190px;
}

.space-bottom-shape {
  padding-bottom: 190px;
}

/* Extra large devices */
@media (max-width: 1500px) {
  .space-shape {
    --section-space: 150px;
  }

  .space-top-shape {
    padding-bottom: 150px;
  }

  .space-bottom-shape {
    /* padding-bottom: 150px; */
    padding-bottom: 70px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .space-shape {
    --section-space: 130px;
  }

  .space-top-shape {
    padding-bottom: 130px;
  }

  /* .space-bottom-shape {
    padding-bottom: 130px;
  } */
}

.mt-n75 {
  margin-top: -75px;
  
}
 
.ndm-slider img{border-radius: 15px!important; box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;}
.card{border-radius: 15px!important; box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;}

@media (max-width: 991px) {
  .price-box_title {
    padding-bottom: 0px!important;
  }
}
@media all and (min-width: 1199px) and (max-width: 1600px) {
  .header-layout3 .header-logo-right {height: 160px!important;padding-top: 35px;}
  .sec-title-ndm{font-size: 3rem;}
  .sec-title-ndm1{font-size: 3rem;}
  .sec-title-ndm2{font-size: 3rem;}
  .sub-title2-2{font-size: 2.5rem; color: black; margin-top: -25px; margin-bottom: 20px;}
  .mb-35-ndm{font-size: 2.5rem!important;}
  .sub-title2-ndm{font-size: 2.5rem!important;}
  .ndm-padding{padding-bottom: 130px!important;}
.ndm1-padding{padding-bottom: 126px!important;}   
}
         
         .btn-grad {
            /* background-image: linear-gradient(to right, #C04848 0%, #480048  51%, #C04848  100%); */
            background-image: linear-gradient(to right, #f8972c 0%, #cd3c27  51%, #f8972c 100%);
            margin: 10px;
            padding: 15px 45px;
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;            
            box-shadow: 3px 5px 10px 0px #2a2929;
            border-radius: 30px;
            display: block;
          }

          .btn-grad:hover {
            background-position: right center; /* change the direction of the change here */
            color: #fff;
            text-decoration: none;
          }
          body{
            background-color: #fff!important;
          }
          .custom-map-marker {
    color: rgb(180, 50, 14)!important;
}
.fa-calendar-alt{
  color: rgb(180, 50, 14)!important;
}
.ndn-border {
    padding: 22px 10px 10px 10px;
    /* border-style: ; */
    border-radius: 7px 7px 7px 7px!important;
    box-shadow: 3px 3px 5px 0px gray;
}
.ndn-border{
  height: 350px!important;

}
.menu .text-white {
    --bs-text-opacity: 1;
    color: #9c3519!important;
    font-weight: 600;
}
 .text-white :hover a {
    color: #fcb421!important;
}
.vs-social{
  color: rgb(152, 12, 12)!important;
}
.note{
  margin-top: 20px;
}
.ndm-bold{color: red; font-size: bold;}

body {
  /* font-family: 'Mont', sans-serif!important; */
  font-family: 'Mont-Bold', sans-serif!important;
  }
 
 .header {
  font-family: 'Mont', sans-serif!important;
  font-weight: bold;
  }
  .btn-grad-ndm{
    background-image: linear-gradient(to right, #f8972c 0%, #cd3c27  51%, #f8972c 100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    width: 300px;
    color: white;            
    box-shadow: 3px 5px 10px 0px #2a2929;
    border-radius: 30px;
    display: block;}
    a{
      /* color: #28a745!important; */
    }
    a:hover{
    color: #9c3519!important;
    }
    .menu-bg #top-menu{
      background-color: #9c3519!important;
    }